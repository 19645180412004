<template>
	<div class="databox">
		<table cellpadding="0" cellspacing="0" border="0" width="100%" class="tableHead">
			<thead class="tab-head">
				<template v-if="argsField.t==0">
					<th width="60">角色</th>
					<th width="120" @click="order('uid')">
						&emsp;发布人
						<img src="@/assets/img/order-asc.png" v-if="orderBy.uid=='asc'" class="jt-shang" alt="">
						<img src="@/assets/img/order-desc.png" v-else-if="orderBy.uid=='desc'" class="jt-shang" alt="">
						<img src="@/assets/img/order.png" v-else class="jt-shang" alt="">
					</th>
				</template>
				<template v-else>
					<th width="30">序号</th>
				</template>
				<th width="120" v-if="argsField.pt==0" @click="order('fuze_uid')">
					&emsp;负责人
					<img src="@/assets/img/order-asc.png" v-if="orderBy.fuze_uid=='asc'" class="jt-shang" alt="">
					<img src="@/assets/img/order-desc.png" v-else-if="orderBy.fuze_uid=='desc'" class="jt-shang" alt="">
					<img src="@/assets/img/order.png" v-else class="jt-shang" alt="">
				</th>
				<th width="180" >简述</th>
				<th width="100" @click="order('adddate')">
					&nbsp;发布时间 
					<img src="@/assets/img/order-asc.png" v-if="orderBy.adddate=='asc'" class="jt-shang" alt="">
					<img src="@/assets/img/order-desc.png" v-else-if="orderBy.adddate=='desc'" class="jt-shang" alt="">
					<img src="@/assets/img/order.png" v-else class="jt-shang" alt="">
				</th>
				<th width="100" @click="order('endtime')">
					&nbsp;完成时限 
					<img src="@/assets/img/order-asc.png" v-if="orderBy.endtime=='asc'" class="jt-shang" alt="">
					<img src="@/assets/img/order-desc.png" v-else-if="orderBy.endtime=='desc'" class="jt-shang" alt="">
					<img src="@/assets/img/order.png" v-else class="jt-shang" alt="">
				</th>
				<th width="60" @click="order('star')">
					优先 
					<img src="@/assets/img/order-asc.png" v-if="orderBy.star=='asc'" class="jt-shang" alt="">
					<img src="@/assets/img/order-desc.png" v-else-if="orderBy.star=='desc'" class="jt-shang" alt="">
					<img src="@/assets/img/order.png" v-else class="jt-shang" alt="">
				</th>
			</thead>
		</table>
		<div class="jie-table" ref="taskBox" @click.stop="caozuoIndex=null">
			<table cellpadding="0" cellspacing="0" border="0" width="100%">
				<tbody>
					<template v-for="(item,index) in dataList['data']">
					<tr :class="'tr-box '+(moreSelect ?(selectIds.indexOf(item.id)>-1?'active':''):(index==dataIndex?'active':''))"  @click.stop="select(index,item.id)">
						<template v-if="argsField.t==0">
							<td width="60">
								<div class="td-box">
									{{index+1}}
									<div class="td-fz" v-if="item.uid==userInfo.uid">发<br>布</div>
									<div class="td-fz active" v-else>接<br>收</div>
								</div>
							</td>
							<td width="120">{{item.name}}</td>
						</template>
						<template v-else>
							<td width="30">{{index+1}}</td>
						</template>
						<td width="120" v-if="argsField.pt==0">{{item.fuze_name}}</td>
						<!-- style="white-space:pre-line;text-align:left;" -->
						<td width="180">
							<div class="title">
								{{item.title}}
							</div>
						</td>
						<td width="100">{{item.adddate | dateFormate}}</td>
						<td width="100">{{item.endtime | dateFormate}}</td>
						<td width="60">
							<div :class="'point point'+item.star"></div>
							<div class="caozuo active" @click.stop="coazuo(index)">
								<img src="@/assets/img/dian2.png" alt="">
								<ul v-if="caozuoIndex==index">
									<li class="bg-orange">
										<router-link class="rwxq" :to="{name:'AddTask',params:item,query:{t:item.t,pt:item.fuze_uid==0?1:0}}">
											详情
										</router-link>
									</li>
									<li class="bg-blue" @click.stop="changeIndexShow(item.id,item.indexShow)">
										{{item.indexShow==1?'取消传屏':'传屏'}}
									</li>
									<li class="bg-red" v-if="item.uid==userInfo.uid" @click.stop="del(item)">删除</li>
								</ul>
								
							</div>
						</td>							
					</tr>
					<tr :class="'record '+(moreSelect ?(selectIds.indexOf(item.id)>-1?'active':''):(index==dataIndex?'active':''))" v-if="item.record" @click.stop="select(index,item.id)">
						<td :colspan="argsField.t==1?5:(argsField.pt==0?7:6)">
							<div class="textbox">
								<em class="noread" v-if="item.no_read_jindu>0">【{{item.no_read_jindu}}条未读】</em>
								{{getInfoById(item.record.uid)['name']}}：{{item.record.content==''?'[图片]':item.record.content}}
							</div>
						</td>
					</tr>
					
					<tr class="line">
						<td :colspan="argsField.t==1?5:(argsField.pt==0?7:6)">
							<div :style="{width:item.jindu+'%'}" :class="'task_jindu point'+item.star"></div>
						</td>
					</tr>
					<tr style="height:6px;">
						<td :colspan="argsField.t==1?5:(argsField.pt==0?7:6)"></td>
					</tr>
					</template>
				</tbody>
			</table>
			<div class="more" v-if="dataList['haveData']" @click="getTask()">点击加载更多</div>
			<div class="nodata" v-else>~ 没有更多数据了 ~</div>
		</div>	
		<!-- <div class="page2">
			<div class="pagenum">{{dataList['page']+1}} of {{dataList['allPage']}}</div>
			<div :class="'pageBtn '+(dataList['page']==0 ?'disabled':'')" @click="getTask(dataList['page']-1)"><img src="@/assets/img/prev.png" alt=""></div>
			<span class="line"></span>
			<div :class="'pageBtn '+(dataList['page']==dataList['allPage']-1 ?'disabled':'')"  @click="getTask(dataList['page']+1)"><img src="@/assets/img/next.png" alt=""></div>
		</div> -->
	</div>
</template>
<script>
	
	import { mapState,mapGetters } from 'vuex'
	export default {
  		name: 'TaskTable',
  		props: [],
  		data: function(){
  			return {
  				dataList:{page:0,data:[],allPage:0,haveData:true},
  				pagesize:30,
  				dataIndex:'',
  				selectIds:[],  				
  				moreSelect:false,
  				argsField:{},
  				orderBy:{},
  				caozuoIndex:null,
  			}
		},
  		methods:{
  			coazuo(ind){
  				if(this.caozuoIndex == ind){
  					this.caozuoIndex = null
  				}else{
  					this.caozuoIndex = ind
  				}
  				
  			},
  			order(field){
  				if(field in this.orderBy == false){
  					this.$set(this.orderBy,field,'asc')
  				}else{
  					if(this.orderBy[field] == 'asc'){
  						this.$set(this.orderBy,field,'desc')
  					}else{
  						this.$delete(this.orderBy,field)
  					}
  				}
  				this.dataList = {page:0,data:[],allPage:0,haveData:true}
  				this.getTask();
  			},
  			getTask:function(){
  				if(!this.dataList.haveData){
  					return;
  				}
  				// console.log('getTaskList')
  				var data 		= this.argsField;
  				// console.log(data)
  				data.page 		= this.dataList.page+1;
  				data.pagesize 	= this.pagesize
  				data.order  	= this.orderBy
  				data.needRecord = 1
  				
				this.$axios.post('index.php?api-task-getTask',data)
				.then((r)=>{
					if(r.length>0){
						if(data.page == 1){
							this.dataIndex = 0;
							this.$emit('activeInfo',r[0])
						}
					}else{
						this.dataIndex = '';
						this.$emit('activeInfo',false)
					}
					this.dataList.data.push(...r);
					this.dataList.page = data.page;
					var dataNum = this.argsField.statusNum[this.argsField.state];
					if(this.dataList.data.length >= dataNum){
						this.dataList.haveData = false;
					}
				})
  			},
  			select(index,id){
  				if(this.caozuoIndex!==null){
  					this.caozuoIndex = null;
  					return;
  				}
  				if(!this.moreSelect){
  					var info = this.dataList['data'][index]
  					this.dataIndex = index;
  					this.$emit('activeInfo',info)
  				}else{  					
  					var ind = this.selectIds.indexOf(id);
  					if(ind>-1){
  						this.selectIds.splice(ind,1)
  					}else{
  						this.selectIds.push(id)
  					}
  					this.$emit('selectTaskIds',this.selectIds)
  				}
  			},
  			changeIndexShow(id,s){
  				if(s==0){s=1}else{s=0}
  				this.global_changeField({table:'task',id,value:{indexShow:s}},(res)=>{
  					this.dataList['data'][this.caozuoIndex]['indexShow'] = s;
  					this.caozuoIndex = null;
  				})
  			},
  			del(info){
  				this.global_del('index.php?api-task-delTask',{id:info.id},(r)=>{
					// this.dataList['data'].splice(this.caozuoIndex,1)
					this.caozuoIndex = null;
					
					// var dataNum = this.argsField.statusNum[this.argsField.state] -= 1;
					// this.$set(this.argsField.statusNum,this.argsField.state,dataNum)

					// this.select(0)
					// if(info.t==0){
					// 	this.$store.commit('globalStore/setNoCompleteTask',JSON.parse(JSON.stringify(info)))
					// }
					
					if(info.isgongshi=='0'){
						this.ws.send(JSON.stringify({type:'delTask',t:info.t,info:info}));
					}
  				})
  			}
  		},
  		watch:{
  			newJindu(newv,oldv){
  				if(this.dataIndex!=='' && newv.taskid != this.dataList['data'][this.dataIndex]['id']){
  					this.dataList['data'].forEach((item,i)=>{
  						if(item.id == newv.taskid){
  							item.no_read_jindu = parseInt(item.no_read_jindu) + 1
  							item.record = newv
  							if('jindu' in newv){
  								item.jindu = newv.jindu
  							}

  						}
  					})
  				}
  			},
  			alreadyJindu(newv,oldv){
  					// console.log('alreadyJindu - 1')
  				if(this.dataIndex!=='' && this.dataList['data'].length>0 && newv.taskid != this.dataList['data'][this.dataIndex]['id']){
  					// console.log('alreadyJindu - 2',newv)
  					this.dataList['data'].forEach((item,i)=>{
  						if(item.id == newv.taskid){
  							item.no_read_jindu = 0
  						}
  					})
  				}
  			}
  		},
  		computed: {
  			...mapState('globalStore', ['userInfo','ws','newJindu','alreadyJindu']),
  			...mapGetters('globalStore',['getNames','getInfoById'])
  		},
  		mounted(){
  			
  		},
  		activated(){
  			this.$bus.$on('getTaskList',(r)=>{
				this.argsField = r;
				this.dataList = {page:0,data:[],allPage:0,haveData:true}

				this.getTask()
			
			})
  		},
  		//解决多次触发的问题
  		deactivated(){
  			this.$bus.$off('getTaskList')
  		},
  		
	    created(){
	    	
  		},
  		beforeDestroy(){
  			
  		},

  }
</script>
<style scoped>
	.databox{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		display: flex;
		flex-direction: column;
	}
	.tableHead{
		flex-shrink: 0;
	}
	.jie-table{
		flex-grow: 1;
		overflow-y: auto;
		overflow-x: hidden;
		color: #313a4c;
		/*flex-grow: 1;*/
	}
	.table{
		height: 100%;
		width: 100%;

	}
	.tab-head th{
		height: 50px;
		cursor: pointer;
		user-select:none;
		-webkit-user-select: none;
		user-drag:none;
		-webkit-user-drag: none;
		font-size: 18px;
	}
	.jt-shang{
		position: relative;
		top: 4px;
	}
	
	.td-box{
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;


	}
	.tr-box{
		cursor: pointer;
		position: relative;
		text-align: center;
		font-size: 20px;
		/*background-color: #fff;*/
		background-color: #f3f5f7;
	}
	.tr-box.active{
		background-color: #D9E5F7
	}
	.tr-box td{
		height: 66px;
		vertical-align: middle;
		
		border-radius: 3px;
	}
	.record{
		/*background-color: rgba(20,20,20,0.1);*/
		background-color: #f3f5f7;
	}
	.record.active{
		background-color: #D9E5F7
	}
	.record td{
		padding: 6px 20px;
		font-size: 18px;
		color: #005baa;
		width: 100%;
	}
	.blank{
		background-color: #fff;
		height: 10px;
	}
	.blank.active{
		background-color: #D9E5F7;
	}
	.task_jindu{
		height: 2px;
	}

	.width-70{
		width: 70px;
	}
	.width-80{
		width: 80px;
	}
	.width-100{
		width: 100px;
	}
	.width-180{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 180px;
	}
	.tab-head>div>div{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	table{
		width: 100%;
		table-layout: fixed;
	}

	.width-40{
		width: 40px;
	}
	.width-80{
		width: 80px;
	}
	.width-110 {
	    width: 110px;
	}
	.point{
		display: inline-block;
		position: relative;
		top: -5px;
	}
	.width-auto{
		flex-grow: 1;
	}
	tr.line{
		height: 2px;
	}

	.caozuo{
		display: inline-block;
		margin-left: 16px;
		position: relative;
		padding: 7px;
		border-radius: 5px;
		
	}
	.caozuo.active{
		background-color: #D9E5F7;
	}
	.caozuo img{
		width: 6px;
	}
	.caozuo ul{
		position: absolute;
	    right: 25px;
	    display: flex;
	    top: -2px;
	}
	.caozuo ul li{
		padding: 8px 14px;
	    font-size: 24px;
	    background-color: #E5EEF6;
	    border-radius: 5px;
	    white-space: nowrap;
	    margin-left: 2px;
	    color: #fff;
	}
	.caozuo ul li.bg-red{
		background-color: #ED1C24;
	}
	.caozuo ul li.bg-blue{
		background-color: #005BAA;
	}
	.caozuo ul li.bg-orange{
		
		background-color: #f7941d;
	}
	.caozuo ul li a{
		color: #fff;
	}
	.textbox{
		/*width: 690px;*/
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.more{
		height: 66px;
		text-align: center;
		cursor: pointer;
		line-height: 66px;
		color: #005baa;
		font-size: 18px;
	}
	.nodata{
		color: #999;
		text-align: center;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		text-align: center;
	}
	.title{
		overflow : hidden; 
		text-overflow: ellipsis; 
		display: -webkit-box; 
		 -webkit-line-clamp: 2;  
		-webkit-box-orient: vertical; 
	}
	.noread{
		color: red;
	}
</style>