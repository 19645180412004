<template>
	<div class="AddBumen">
		<div class="tit">{{form.id?'修改部门':'添加部门'}}</div>
		<div class="con">
			<div class="formbox">
				<div class="form-div">
					<span>序号</span>
					<input type="text" v-model.number.trim="form.sort">
				</div>
				<div class="form-div">
					<input type="text" placeholder="部门名称" v-model="form.title">
				</div>
				<template v-if="!(form.id>0 && form.pid==0)">
					<div class="form-tit">
						选择所属部门
					</div>
					<div class="bumenbox" >
						<BumenPeople showt="1" :pid="form.pid" @selectBumen="selectBumen"></BumenPeople>
					</div>
				</template>
				<div v-else style="height:200px;"></div>
				<div class="btnbox">
					<div class="btn btn-primary" @click="submit">确&emsp;认</div>
					<div class="btn btn-defalut"  @click="cancel">取&emsp;消</div>
					<div class="btn btn-red" @click="delBumen" v-if="form.id">删除</div>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
	import BumenPeople from '@/components/BumenPeople';
	export default {
  		name: 'AddBumen',
  		props: {},
  		data: function(){
  			return {
  				form:{
  					sort:1,
  					title:'',
  					pid:0,
  					id:0
  				},
  				bumenList:[],
  				info:{}
  			}
  		},
  		components:{
  			BumenPeople
  		},
  		methods:{
  			selectBumen(id){
  				this.form.pid = id;
  			},
  			submit(){
  				this.global_submit('index.php?api-bumen-addBumen',this.form,(r)=>{
  					this.$bus.$emit('updataData');
  					this.$router.replace('/Tongxunlu')
  				})
  			},
  			cancel(){
  				this.$router.replace('/Tongxunlu')
  			},
  			delBumen(){
  				
  				this.global_del('index.php?api-bumen-delBumen',{id:this.form.id},()=>{
  					this.$bus.$emit('updataData');
  					this.$router.replace({
  						name:'Tongxunlu'
  					})
  				},'确认删除该部门吗？','提示：该部门下人员也会一并删除')
  			}
  		},
  		watch:{
  			'$route'(to) {
  				if(to.params.id>0){
  					this.form = to.params;
  				}
  			}
  		},
  		mounted(){
  			if(JSON.stringify(this.$route.params) != '{}' && this.$route.params.id>0){
  				this.form  = this.$route.params;
  			}
  		}
  }
</script>
<style scoped>
	.AddBumen{
		padding-left: 27px;
		padding: 16px 56px 65px 28px;
		height: 100%;
	}
	.tit{
		font-size: 28px;
		color: #313a4c;
		padding: 22px 16px 22px;
	}
	.con{
		background-color: #f4f6f9;
		border-radius: 8px;
		height: calc(100% - 161px);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.formbox{
		width: 520px;
	}
	.form-div{
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}
	.form-div span{
		font-size: 14px;
		color: #9da0af;
		width: 63px;
		text-align: center;
	}
	.form-div input{
		flex-grow: 1;
		height: 48px;
		background-color: #e0e6ee;
		text-align: center;
		font-size: 20px;
		color: #313a4c;
		border: none;
		outline: none;
	}
	.form-tit{
		text-align: center;
		margin-top: 25px;
		font-size: 22px;
		color: #313a4c;
	}
	.bumenbox{
		overflow: auto;
		height: 392px;
		margin-bottom: 20px;
	}
	.del{
		color: #fff;
		padding: 16px 20px;
		font-size: 24px;
		color:#fff;
		background-color: red;
		border-radius: 8px;
		text-align: center;
		cursor: pointer;
		margin-left: 20px;
	}
	.btnbox{
		align-items: center
	}
</style>