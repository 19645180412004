<template>
	<div class="rwgs" v-if="info">
		<div class="topBox">
			<div class="rwlb-tit">
				<!-- <div class="font-25 col-051349">事项概述</div> -->
				<template v-if="info.t==0">
					<div class="td-fz">发布</div>
					<div class="tagCon">
						<div class="active">{{info.name}}</div>
					</div>
					<template v-if="info.fuze_uid>0">
					<div class="td-fz active">接收</div>
					<div class="tagCon fixedHeight">
						<div class="active">{{info.fuze_name}}</div>
						<div v-for="(item,index) in getPeoples(info['xietong_uids'])">{{item.name}}</div>
					</div>
					</template>
				</template>
				<template v-else>
					<div class="font-25" style="margin-right:10px;">事项进度</div>
					<div class="td-fz">关联</div>
					<div class="tagCon">
						<div class="active" v-for="(item,index) in getTags(info['tagIds'])">{{item.title}}</div>
					</div>
				</template>
				
				<div class="fabu-box">
					<div class="dt-box">
						<div class="date-1">{{info.adddate}}</div>
						<div :class="'time-1 '+(sytime.substr(0,1) == '-'?'active':'')">{{sytime}}</div>
					</div>
					<div class="fbdj-box">
						<div class="fb-box">发布</div>
						<div :class="'dj-box '+(sytime.substr(0,1) == '-'?'active':'')">倒计</div>
					</div>
					<div>
						<div class="jjzy" v-if="info.star==0">一般</div>
						<div class="jjzy" v-if="info.star==1">紧急</div>
						<div class="jjzy" v-if="info.star==2">重要</div>
						<div class="jjzy" v-if="info.star==3">紧急重要</div>
						<div :class="'point5 point'+info.star"></div>
					</div>
				</div>
			</div>
			<div class="cont-box">
				<div class="cont-L">
					<div class="cont-tit">{{info.title}}</div>
				</div>
				<router-link class="rwxq" :to="{name:'AddTask',params:info,query:{t:info.t,pt:info.fuze_uid==0?1:0}}">
					<div style="font-size:16px;"><img src="@/assets/img/dian.png" alt="">任务详情</div>
					<div v-if="info.picurl"><img src="@/assets/img/fujian.png" alt="">有附件</div>
				</router-link>	

			</div>
			<div class="jingdu">
				<div class="jdt-box" :style="{width:info.jindu+'%'}"></div>
				<div class="jdt-num">{{info.jindu}}%</div>
			</div>
			<!-- <div class="rwlb-tit font-25 col-051349">任务进度</div> -->
		</div>
		<div class="jd-box">
			<div class="jdbox" ref="jdbox">
				<div class="line-box"></div>
				<div class="jd-con">
					<div class="flex-3" v-for="item in taskRecord">
						<img v-if="item.picurl" :src="item.picurl" class="tx2-img" alt="">
						<div v-else class="tx">
							{{item.name.substr(0,1)}}
						</div>
						<div class="pad-top-12">
							<div class="jdHead">
								{{item.name}}
								<span class="col-7f8fa4">{{item.addtime}}</span>
								<div class="jinduNum" v-if="item.jindu>0">
									{{item.jindu}}%
								</div>
							</div>
							<div class="jdCon">
								{{item.content}}
								<!-- v-viewer :images="item.picurls.split(',')" -->
								<div class="recordImg" v-if="item.picurls">
									<template v-for="(it,ind) in item.picurls.split(',')">
										<div class="tu-box2" v-if="'ppt,pptx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/ppt.png')+');background-position:center 8px;background-size:32px auto;'">
											<div><div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div></div>
											<p>{{it.split('/').pop()}}</p>
										</div>
										<div class="tu-box2" v-else-if="'xls,xlsx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/excel.png')+');background-position:center 8px;background-size:32px auto;'">
											<div><div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div></div>
											<p>{{it.split('/').pop()}}</p>
										</div>
										<div class="tu-box2" v-else-if="'doc,docx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/word.png')+');background-position:center 8px;background-size:32px auto;'" :title="it.split('/').pop()">
											<div><div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div></div>
											<p>{{it.split('/').pop()}}</p>
										</div>
										<div class="tu-box2" v-else-if="it.split('/').pop().split('.')[1]=='pdf'" :style="'background-image:url('+require('@/assets/img/pdf.png')+');background-position:center 8px;background-size:32px auto;'">
											<div><div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div></div>
											<p>{{it.split('/').pop()}}</p>
										</div>
										<div class="tu-box2" v-else :style="'background-image:url('+it+')'">
											<div>
												<div @click="prveImg(it)"><img src="@/assets/img/see.png" alt=""> 预览</div>
												<div @click="$axios.download(it)"><img src="@/assets/img/download.png" @click="delPicurl(index)" alt=""> 下载</div>
											</div>
										</div>
									</template>
								</div>
							</div>
							
							
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- 如果是群，不显示群成员进度（因为e_task_user没有对应数据） -->
		<div v-if="info.pt=='0' && info.t=='0'" class="peopleJindu">
			<div class="showJinduBtn" @click.stop="showPeopleJd=!showPeopleJd">
				<img src="@/assets/img/jt2.png" :style="'transform:rotate('+(showPeopleJd?'180':'0')+'deg)'" alt="">
			</div>
			<div :class="'showJindu '+(showPeopleJd?'active':'')">
				<div class="jdtit">成员进度</div>
				<ul>
					<li v-for="item in info.taskPeople">
						<em :class="(item.roleType=='1'?'':'green')">{{item.roleType=='1'?'负责':'协同'}}</em>
						<div>{{item.name}}</div>
						<span>{{item.jindu}}%</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="bot-box" v-if="info.state==0">
			<div class="flex-1 hei-67">
				<img src="@/assets/img/pencil.png" @click="showDrawing=true" class="pen-img" alt="">
				<div @click="upload" class="chuan-img">
					<img src="@/assets/img/chuan.png"  alt="">
					<input  type="file" @change="uploadFile" ref="file" hidden accept="image/png,image/gif,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
				</div>
				
				<div class="tu-img">
					<div class="tu-big-box">
						<template v-for="(item,index) in addData.picurl">
							<div class="tu-box" v-if="'ppt,pptx'.indexOf(item.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/ppt.png')+');background-position:center 8px;background-size:22px auto;'">
								<div>{{item.split('/').pop()}}</div>
								<img src="@/assets/img/cuo.png" class="cuo2-img" @click="delPicurl(index)" alt="">
							</div>
							<div class="tu-box" v-else-if="'xls,xlsx'.indexOf(item.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/excel.png')+');background-position:center 8px;background-size:22px auto;'">
								<div>{{item.split('/').pop()}}</div>
								<img src="@/assets/img/cuo.png" class="cuo2-img" @click="delPicurl(index)" alt="">
							</div>
							<div class="tu-box" v-else-if="'doc,docx'.indexOf(item.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/word.png')+');background-position:center 8px;background-size:22px auto;'" :title="item.split('/').pop()">
								<div>{{item.split('/').pop()}}</div>
								<img src="@/assets/img/cuo.png" class="cuo2-img" @click="delPicurl(index)" alt="">
							</div>
							<div class="tu-box" v-else-if="item.split('/').pop().split('.')[1]=='pdf'" :style="'background-image:url('+require('@/assets/img/pdf.png')+');background-position:center 8px;background-size:22px auto;'">
								<div>{{item.split('/').pop()}}</div>
								<img src="@/assets/img/cuo.png" class="cuo2-img" @click="delPicurl(index)" alt="">
							</div>
							<div class="tu-box" v-else :style="'background-image:url('+item+')'" @click="prveImg(item)">
								<img src="@/assets/img/cuo.png" class="cuo2-img" @click.stop="delPicurl(index)" alt="">
							</div>
						</template>
					</div>
				</div>

				<div class="rangebox" v-if="info.state==0 && (info.t==1 || (info.t==0 && ((info.pt==0 && info.fuze_uid==userInfo.uid) || (info.pt==1 && info.uid==userInfo.uid)) ))">
					<span :style="{left:(2*addData.jindu-30)+'px'}">{{addData.jindu}}%</span>
					<input type="range" min="0" step="10" max="100" v-model="addData.jindu">
				</div>
				
			</div>
			<div class="line2"></div>
			<div class="last-box">
				<textarea v-model="addData.content" placeholder="这里写内容"></textarea>
				<div class="flex-1">
					<div class="logo3"><img src="@/assets/img/Logo3.png" @click="submit" alt=""></div>
					<div class="rwwc" v-if="info.state==0 && (info.t==1 || ((info.fuze_uid==0 && info.uid==userInfo.uid) || info.fuze_uid==userInfo.uid))" @click="addData.jindu=100;submit()">任务<br>完成</div>
				</div>
			</div>
		</div>
		<!-- <transition name="animate__animated animate__bounce" enter-active-class="animate__bounceIn" leave-active-class="animate__bounceOut"> -->
			<div class="backg-col2" v-if="showDrawing">
				<Drawing @hideDrawing="showDrawing=false" @returnImg="addPic"></Drawing>
			</div>
		<!-- </transition> -->
	</div>
</template>
<script>
	// import 'animate.css';
	import { mapState,mapGetters } from 'vuex'
	import Drawing from '@/components/Drawing'
	
	export default {
  		name: 'TaskDetail',
  		props: ['info'],
  		data: function(){
  			return {
  				sytime:'',
  				taskRecord:[],
  				addData:{
  					content:'',
  					picurl:[],
  					jindu:0,
  				},
  				showDrawing:false,
  				showPeopleJd:false
  			}
  		},
  		methods:{
  			hd(r){
  				console.log(r)
  				this.showDrawing=false
  			},
  			djs(){
  				if(this.info && this.info.endtime){
	  				var date = new Date();
	  				var nowtime 	= parseInt(date.getTime()/1000);
	  				var endTime 	= new Date(this.info.endtime+' 23:59:59').getTime()/1000;
	  				var sytime      = Math.abs(endTime - nowtime);
	  				var d = parseInt(sytime/(24*60*60));
			        var h = parseInt((sytime/60/60)%24);
			        var i = parseInt((sytime/60)%60)
			        var s = parseInt(sytime%60)
			        var t = (d>0?(d+'天'):'')+(h>0?(h+'时'):'')+(i>0?(i+'分'):'')+s+'秒';
				    if(endTime - nowtime>0){
				        this.sytime =  t;
				    }else{
				      	this.sytime  = '-'+t
				    }
				}else{
					this.sytime = ''
				} 				  
  			},
  			getTaskRecord(){
  				if(this.info.t==0 && parseInt(this.info.no_read_jindu) > 0){
  					//设置已读
  					if(this.info.uid == this.userInfo.uid){
  						var uid = this.info.fuze_uid
  					}else{
  						var uid = this.info.uid
  					}
  					this.$store.dispatch('globalStore/setJinduAlreadyRead',{
  						id:this.info.id,
  						pt:this.info.pt,
  						uid,
  						no_read_jindu:this.info.no_read_jindu,
  						groupid:this.info.groupid
  					}).then((r)=>{
  						if(r.ok){
  							if(this.info.pt=='0'){
  								this.$store.commit('globalStore/setMyBumenPeopleAlreadyRead',{id:uid,readnum:-parseInt(this.info.no_read_jindu)})
  							}else{
  								this.$store.commit('globalStore/setGroupAlreadyRead',{id:this.info.groupid,readnum:-parseInt(this.info.no_read_jindu)})
  							}
  							this.info.no_read_jindu = 0
  						}
  					})
  					
  				}
  				
  				this.$axios.post('index.php?api-record-getTaskRecord',{
  					taskid:this.info.id
  				})
  				.then((r)=>{
  					this.taskRecord = r;
  					this.$nextTick(()=>{
  						this.$refs.jdbox.scrollTop = this.$refs.jdbox.scrollHeight;
  					})
  				})
  			},
  			upload(){
  				this.$refs.file.click()
  			},
  			uploadFile(e){ 
  				this.$axios.upload(e.target.files[0])
  				.then((r)=>{
  					if(r.ok){
  						this.addData.picurl.push(r.msg)
  					}
  				})
  			},
  			delPicurl(index){
  				this.addData.picurl.splice(index,1)
  			},
  			addPic(u){
  				this.addData.picurl.push(u)
  			},
  			
  			submit(){
  				if(this.addData.picurl.length==0 && this.addData.content==''){
  					this.$message('warning','请添加图片或文字')
  					return;
  				}
  				var dd = {
  					taskid:this.info.id,
  					content:this.addData.content,
  					picurl:this.addData.picurl.join(','),
  					addtime:this.getTime(),	
  				}
  				//任务未完成 前提   t=1 笔记  或 t=0的情况下  (pt=0（人对人）且fuze_uid=uid) 或 (pt=1（群）且uid=uid) 			
  				if(this.info.state==0 && (this.info.t==1 || (this.info.t==0 && ((this.info.pt==0 && this.info.fuze_uid==this.userInfo.uid) || (this.info.pt==1 && this.info.uid==this.userInfo.uid)) ))){
  					dd.jindu = this.addData.jindu
  				}
  				// console.log(this.addData.picurl);
  				// return
  				this.global_submit('index.php?api-record-addTaskRecord',dd,(r)=>{
  					
  					// if(dd.jindu == 100){
  					// 	this.$emit('reload')
  					// }else{
  						var updateJinduData = {
  							id:r.insertId,
  							uid:this.userInfo.uid,
  							taskid:dd.taskid,
  							addtime:dd.addtime,
  							content:dd.content,
  							name:this.userInfo.uname,
  							picurl:this.userInfo.picurl,
  							picurls:dd.picurl,
  						}
  						if('jindu' in dd){
  							this.info.jindu = dd.jindu
  							updateJinduData.jindu 	= 	dd.jindu
  						}

  						// this.$set(this.info,'record',{uid:this.userInfo.uid,content:dd.content})
  						// this.taskRecord.push(updateJinduData)

  						if(this.info.isgongshi=='0'){
  							var sendData = JSON.parse(JSON.stringify(updateJinduData))
  							sendData.picurls = this.addData.picurl
  							sendData.taskInfo = this.info
  							this.ws.send(JSON.stringify({type:'newJindu',t:this.info.t,data:sendData}));
  						}

  						this.$nextTick(()=>{
  							this.addData.picurl = []
  							this.addData.content = ''
  							this.$refs.jdbox.scrollTop = this.$refs.jdbox.scrollHeight;
  						})
  					// }
  				})
  				
  			},
  		
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo','pt','newJindu','ws']),
  		    ...mapGetters('globalStore',['getNames','getPeoples','getInfoById','getTags']),
  		},
  		watch:{
  			info(newv,oldv){
  				// console.log('taskDetail-watch',newv)
  				this.getTaskRecord()
  				this.addData.jindu = newv.jindu
  			},
  			newJindu(newv,oldv){
  				if(newv.taskid==this.info.id){
  					newv.picurls = newv.picurls.join(',')
  					this.taskRecord.push(newv)
  					this.$nextTick(()=>{
  						this.$refs.jdbox.scrollTop = this.$refs.jdbox.scrollHeight
  					})
  				}
  				
  			}
  		},
  		components:{
  			Drawing,
  		},
  		mounted(){
  			this.addData.jindu = this.info.jindu
  			this.getTaskRecord()
  			this.timer = setInterval(()=>{
  				this.djs()
  			},1000)
  		},
  		beforeDestroy(){
  			var that = this;
  			clearInterval(that.timer)
  		}
  }
</script>
<style scoped>
	.rwgs{
		flex-grow:1;
		min-width: 700px;
		display: flex;
		flex-direction: column;
		background-color: #F6F6F7;
		position: relative;
	}
	.rwlb-tit{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 4px;
		margin-bottom: 4px;
		padding:0 10px 0 10px;

	}
	
	
	.fabu-box{
		display: flex;
		margin-left: auto;
	}
	.date-1,.time-1{
		color: #221815;
		font-size: 18px;
		line-height: 30px;
		text-align: right;
	}
	
	.dt-box{
		/*width: 115px;*/
		margin-right: 13px;
	}
	.fb-box,.dj-box{
		width: 58px;
		height: 26px;
		font-size: 18px;
		color: #ffffff;
		line-height: 26px;
		text-align: center;
		margin-bottom: 3px
	}
	.fb-box{
		background-color: #009944
	}
	.time-1{
		color: #055ba7;
	}
	.dj-box{
		background-color: #055ba7
	}

	.time-1.active{
		color: #f7941d;
	}
	.dj-box.active{
		background-color: #f7941d
	}
	.fbdj-box{
		margin-right: 18px
	}
	
	.cont-box{
		background-color: #EAEEF4;
		padding: 16px;
		display: flex;
		align-items: center;

	}
	.cont-L{
		flex-grow: 1;
	}
	.cont-tit{
		color: #000000;
		font-size: 24px;
	}
	.rwxq{
		flex-shrink: 0;
		margin-left: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.rwxq>img{
		float: left;
		width: 30px;
		height: 8px;
	}
	.rwxq div{
		display: flex;
		align-items: center;
		color: #005baa;
		font-size: 14px;
	}
	.rwxq>div img{
		margin-right: 5px;
		width: 12px;
	}
	.jingdu{
		display: flex;
		align-items: center;
		position: relative;
		top: -8px;
	}
	.jdt-box{
		height: 7px;
		background-color: #009944;
	}
	.jdt-num{
		font-size: 18px;
		color: #005baa;
		flex-shrink: 0;
    	white-space: nowrap;
	}
	.jd-box{
		flex-grow: 1;
		position: relative;
		padding-left: 8px;
	}
	.jdbox{
		position: absolute;
		left: 10px;
		top: 0;
		bottom: 14px;
		right: 10px;
		overflow: auto;
		/*background-color: red;*/
	}
	.line-box{
		position: absolute;
		top: 0;
		left: 21px;
		bottom: 0;
		width: 2px;
		background-color: #005baa;
	}
	.jd-con{
		position: relative;
		z-index: 2;
	}
	.tx{
		color: #fff;
		background-color: #005baa;
		width: 44px;
		height: 44px;
		border-radius: 44px;
		text-align: center;
		line-height: 44px;
		flex-shrink: 0;
	}
	.tx2-img{
		width: 44px;
		height: 44px;
		border-radius: 10px;
		flex-shrink: 0;
	}
	.flex-3{
		display: flex;
		margin-bottom: 10px;
	}
	.pad-top-12{
		padding-top: 5px;
		padding-left: 12px;
		font-size: 18px;
		color: #3f4145;
		line-height: 30px;
		letter-spacing: 1px;
		flex-grow: 1;
	}
	.recordImg{
		display: flex;
		flex-wrap: wrap;
		margin-top: 8px;
	}

	.col-7f8fa4{
		color: #7f8fa4;
		margin-left: 7px;
		font-size: 18px;
	}
	.bot-box{
		height: 224px;
		background-color: #eaeef4;
		font-size: 18px;
		color: #3f4145;
		margin: 10px 15px 10px 30px;
		border-radius: 28px;
	}
	.pen-img{
		width: 33px;
		height: 41px;
		margin-left: 30px;
	}
	.chuan-img{
		width: 52px;
		height: 39px;
		margin:0 27px 0 27px;
	}
	.chuan-img img{
		width: 100%;
		height: 100%;
	}
	
	.hei-67{
		height: 67px;
		justify-content: space-between;
		padding-right: 20px;
	}
	.hei-67>img{
		cursor: pointer;
	}
	.tu-img{
		height: 67px;
		flex-grow: 1; 
		margin-right: 20px;
		position: relative;
		overflow-y:hidden;
		overflow-x:auto;
	}
	.tu-big-box{
		display: flex;
		flex-wrap: nowrap;
		height: 100%;
		align-items: center;
	}
	.tu-box{
		position: relative;
		flex-shrink: 0;
		height: 58px;
		width: 58px;
		margin-right: 10px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		background-color: #fff;		
		line-height: 88px;
	}
	.tu-box2{
		position: relative;
		flex-shrink: 0;
		height: 80px;
		width: 80px;
		margin-right: 10px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		background-color: #fff;		
		transition: all ease .5s;
	}
	.tu-box div,.tu-box2 p{
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 12px;
		color: #000;
	}
	.tu-box2 p{
		line-height: 118px;
	}
	.tu-box2>div{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		display: none;
	}
	.tu-box2>div>div{
		display: flex;
		align-items: center;
		font-size: 12px;
		justify-content: center;
		color: #fff;
		cursor: pointer;
	}
	.tu-box2>div>div img{
		width: 18px;
		margin-right: 5px;
	}
	.tu-box2:hover>div{
		display: flex;
		background-color: rgba(0,0,0,0.4);
	}

	.tu-box .file_pic,.tu-box .pic3-img{
		height: 80%;
	}
	.tu-box .cuo2-img{
		width: 18px;
		height: 18px;
		position: absolute;
		right: -7px;
		top: -5px;
		cursor: pointer;
	}
	
	.line2{
		width: 98%;
		height: 4px;
		background-color: #d2dfec;
		margin: 0 auto;
	}
	.last-box{
		padding: 0 20px;
		height: 153px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.last-box textarea{
		height: 90%;
		font-size: 22px;
		background-color: transparent;
		border: none;
		outline: none;
		flex-grow: 1;
		margin-right: 20px;
		color: #3f4145;
		resize: none;
	}
	.logo3,.rwwc{
		height: 65px;
		width: 65px;
		border-radius: 100%;
		background-color: #005baa;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.rwwc{
		background-color: #009944;
		color: #ffffff;
		line-height: 22px;
		margin-left: 8px;
	}
	.backg-col2{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: #eaeef4;
		overflow: hidden;
		z-index: 99;
	}
	.jdHead{
		display: flex;
		font-size: 20px;
		color: #000;
	}
	.jinduNum{
		margin-left: auto;
		margin-right: 20px;
		color: #005baa;
		font-size: 20px;
	}
	.jdCon{
		font-size: 22px;
		background-color: #fff;
		padding: 10px;
		border-radius: 8px;
		margin-top: 10px;
		color: #666;
	}


	.showJindu{
		position: absolute;
		right: -200px;
		top: 0;
		font-size: 20px;
		width: 200px;
		transition: all ease .4s;
		opacity: 0;
	}
	.showJindu.active{
		right: 25px;
		opacity: 1;
	}
	.showJindu .jdtit{
		background-color: #fff;
		line-height: 40px;
		text-align: center;
		border-radius: 5px;
	}
	.showJindu ul{
		background-color: #fff;
		padding: 2px 5px;
		margin-top: 5px
	}
	.showJindu ul li{
		height: 50px;
		display: flex;
		align-items: center;
	}
	.showJindu ul li:not(:last-child){
		border-bottom: 2px solid #e9edf3;
	}
	.showJindu ul li em{
		width: 20px;
		font-size: 12px;
		color: #fff;
		line-height: 20px;
		background-color: var(--primary-color);
		border-radius: 4px;
		text-align: center;
		padding: 0.4px 0;
	}
	.showJindu ul li em.green{
		background-color: #009944;
	}
	.showJindu ul li div{
		flex-grow: 1;
		margin: 0 10px;
	}
	.showJindu ul li span{
		color: var(--primary-color);
	}
	.showJinduBtn{
		position: absolute;
		right: 2px;
		top: 10px;
		width: 20px;
		height: 100px;
		background-color: rgba(0,0,0,0.3);
		border-radius: 4px 0 0 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.showJinduBtn img{
		width: 10px;
		transition: all ease .4s;
	}
	.peopleJindu{
		position: absolute;
		right: 0;
		top: 150px;
		z-index: 999;
	}
</style>