<template>
	<div class="super-box">
		<div class="L">
			<router-link to="/System/ZherenBumenKind" active-class="active">责任部门(标签)分类</router-link>
			<router-link to="/System/ZherenBumen" active-class="active">责任部门(标签)</router-link>
			<router-link to="/System/TaskKind" active-class="active">任务分类</router-link>
			<router-link to="/System/GongShiKind" active-class="active">公示分类</router-link>
			<router-link to="/System/BeiWangKind" active-class="active">备忘录分类</router-link>
		</div>
		<div class="R pd-r-0">
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
	export default {
  		name: 'System',
  		props: {},
  		data: function(){
  			return {

  			}
  		},
  		methods:{

  		},
  		mounted(){

  		}
  }
</script>
<style scoped>
	.super-box{
		display: flex;
		height: 100%;
		width: 100%;
		/*border: 1px solid red;*/
	}
	.L{
		width: 210px;
		background-color: #f4f6f9;
		margin-right: 10px;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
	}
	.L a{
		height: 60px;
		color: #313a4c;
		font-size: 20px;
		line-height: 60px;
		text-align: center;
	}
	.L a.active{
		background-color: #e0e6ee;
	}

	.R{
		flex-grow: 1;
		height: 100%;
		padding-right: 54px;
	}
	
</style>