<template>
	<div>
		<template v-for="item in bumenList">
			<div :key="item.id" :class="'bu '+(bumenId==item.id?'active':'')" @click="select_bumen(item.id)" :style="'padding-left:'+((item.level-1)*30)+'px'">
				<img class="icon1" src="@/assets/img/lev.png" alt="">
				{{item.title}}
			</div>
			<ul class="plist" v-if="showt==2" >
				<li v-for="it in item.plist" :key="it.id" @click="select_people(it.id)" :class="uids.indexOf(it.id) > -1?'active':''" :style="'padding-left:'+((item.level-1)*30+40)+'px'">{{it.name}}</li>
			</ul>
		</template>
	</div>
</template>
<script>
	// showt 1 选择部门  2选择人员
	export default {
  		name: 'BumenPeople',
  		props: ['showt','pid','peoplePids'],
  		data: function(){
  			return {
  				bumenList:[],
  				uids:[],
          bumenId:0
  			}
  		},
  		methods:{
  			select_bumen(id){
  				if(this.showt == 1){
  					// if(this.bumenId == id){
  					// 	id=0;
  					// }
  					this.bumenId = id;
  					this.$emit('selectBumen',id);
  				}
  			},
  			select_people(id){
  				if(this.showt == 2){
  					var ind = this.uids.indexOf(id)
  					if(ind>-1){
  						this.uids.splice(ind,1)
  					}else{
  						this.uids.push(id)
  					}
  					this.$emit('selectPeople',this.uids);
  				}
  			}

  		},
      watch:{
        pid(newv,oldv){
            if(newv){

              this.bumenId = newv;
              
            }
        },
        peoplePids(newv,oldv){
            this.uids = newv
        }
      },
  		mounted(){
        this.$store.dispatch('globalStore/getAllBumenPeople')
        .then((r)=>{
          this.bumenList = r;

          if(this.pid==0){
            this.bumenId = r[0]['id']
          }else{
            this.bumenId = this.pid;
          }
          this.select_bumen(this.bumenId)
        })  			
  		}
  }
</script>
<style scoped>
	.bu{
		height: 48px;
		display: flex;
		align-items: center;
		font-size: 20px;
		cursor: pointer;
	}
	.bu img.icon1{
		height: 22px;
		margin-right: 14px;
	}
	.bu img.del{
		width: 24px;
		height: 24px;
		margin-left: auto;
		margin-right: 24px;
	}
	.bu.active{
		background-color: #e0e6ee;
	}
	.plist li{
		height: 48px;
		line-height: 48px;
		cursor: pointer;
		margin-bottom: 2px;
	}
	.plist li.active{
		background-color: #e0e6ee;
	}
</style>