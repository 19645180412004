<template>
    <div id="app" ref="app">
        <div id="navBox"  v-if="$route.path!='/Login'" :class="navBar?'':'hide'">
            <div class="navBut" @click="navBar=!navBar">
                <img src="@/assets/img/Dropdown.png" v-if="navBar" alt="">
                <img src="@/assets/img/Dropdown2.png" v-else alt="">
            </div>
            <div class="name-box display-1" @click="showSystemConfig=true">
                <img :src="userInfo.picurl" v-if="userInfo.picurl" class="logo-img" alt="">
                <div class="tx" v-else>{{userInfo.uname.substr(0,1)}}</div>
                <div class="font-15">{{userInfo.uname}}</div>
                <div class="font-14 mar-6">{{userInfo.zhiwu}}</div>
                <div class="font-14">{{userInfo.bumen}}</div>
            </div>
            <div class="display-1 mar-55">
                <template v-for="item in menu">
                    <router-link :to="item.url" active-class="active" v-if="userInfo.isMaster=='1' || (userInfo.powerIds.indexOf(item.id) > -1)">
                        <img :src="item.icon" alt="" class="icon">
                        <div v-html="item.menu"></div>
                        <img src="@/assets/img/jt2.png" alt="" class="navJt">
                    </router-link>
                </template>
            </div>
            <div class="display-1 imgs-box">
                <!-- <img src="@/assets/img/refreshs.png" class="ref-img" alt=""> -->
                <router-link to="/System" active-class="active" v-if="userInfo.isMaster=='1' || (userInfo.powerIds.indexOf(6) > -1)">
                        <img src="@/assets/img/Settings3.png" alt="">
                </router-link>
                <img src="@/assets/img/exit.png" @click="exit" alt="">
            </div>

        </div>    
        <div id="container"  :class="(!navBar || $route.path=='/Login')?'full':''">
            <keep-alive exclude="AddTask,Login">
                <router-view></router-view>
            </keep-alive>
        </div>
        
        <SystemConfig v-if="showSystemConfig" @cancel="showSystemConfig=false"></SystemConfig>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import SystemConfig from '@/components/SystemConfig'
export default {
    name: 'App',
    data(){
        return {
            navBar:true,
            showSystemConfig:false,
            menu:[
                {id:2,url:'/Xietong',menu:'协&nbsp;&nbsp;同',icon:require('@/assets/img/calendar.png')},
                {id:1,url:'/Index',menu:'数&nbsp;&nbsp;据',icon:require('@/assets/img/Chart4.png')},
                {id:3,url:'/Beiwang',menu:'备&nbsp;&nbsp;忘',icon:require('@/assets/img/copy.png')},
                {id:4,url:'/Tongxunlu',menu:'通讯录',icon:require('@/assets/img/pic-7.png')},
                // {id:6,url:'/System',menu:'设&nbsp;&nbsp;置',icon:require('@/assets/img/Settings3.png')},
            ]
        }
    },
    components: {
        SystemConfig
    },
    computed: {
        ...mapState('globalStore', ['userInfo','peopleList'])
    },
    methods:{
        bodyScale() {
            var devicewidth = window.screen.width;
            var standWidth = 1920; //默认横

            //宽大于高 横屏 参照1920
            if(window.screen.width < window.screen.height){
              standWidth = 1080; //竖
            }
            var scale = devicewidth / standWidth;  // 分母——设计稿的尺寸

            this.$refs.app.style.zoom = scale;
        },
        showBt(){
            if(document.documentElement.clientHeight<1080){
                this.$store.state.globalStore.isScreenFull = false;
            }else{
                this.$store.state.globalStore.isScreenFull = true;
            }
        },
        exit(){
            const confirmDia = this.$dialog.confirm({
                theme:'info',
                header:"提示",
                body:"确认退出吗？",
                onConfirm: ({ e }) => {
                    window.localStorage.removeItem('userInfo');
                    this.$router.replace('/Login');
                    location.reload();
                    confirmDia.destroy()
                }
            })
        }
    },
    mounted() {
        this.bodyScale()
        this.showBt();
        /*window.onresize = ()=>{
            this.showBt();
        }*/
    },
    beforeCreate(){
        this.$store.dispatch('globalStore/getAppInfo');
    }
}
</script>
<style>
#app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
#navBox{
    background-color: #313a4c;
    height: 100%;
    transition: all ease .3s;
    z-index: 999;
    width: 106px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    color: #fff;
}
#navBox.hide{
    left: -106px;
}
#navBox .navBut{
    position: absolute;
    bottom: 200px;
    right: -20px;
    width: 20px;
    cursor: pointer;
    z-index: 22;
}
#navBox .navBut img{
    width: 100%;
}

#container{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 106px;
}
#container.full{
    left: 0;
}
.logo-img{
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
}
.display-1{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.name-box{
    padding: 24px 0 20px 0;
    width: 100%;
    background-color: #464e5e;
    text-align: center;
}

.mar-6{
    margin: 6px 0;
}
.mar-55{
    margin: 45px 0 35px 0;
}
.mar-55>a{
    margin-bottom: 15px;
    width: 80px;
    /*height: 55px;*/
    font-size: 18px;
    text-align: center;
    padding: 10px 0;
    position: relative;
    color: #fff;
    border-radius: 5px;
}   
.mar-55>a.active{
    background-color: #464e5e;
} 
.icon{
    height: 34px;
}
.navJt{
    position: absolute;
    top: 27px;
    right: -12.5px;
    width: 12px;
    height: auto;
    z-index: 999;
    display: none;
} 
.mar-55>a.active .navJt{
    display: block;
}

.imgs-box{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    /*margin-top: calc(100vh - 750px);*/
}
.imgs-box img{
    cursor: pointer;
    width: 30px;
    margin-bottom: 30px;
}
.imgs-box .ref-img{
    width: auto;
    height: 50px;
}
.tx{
    color: #fff;
    background-color: #005baa;
  
    /*border-radius: 60px;*/
    text-align: center;
    line-height: 60px;  
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
    border-radius: 75px;
}
</style>