<template>
	<div class="system">
		<div class="con" v-if="userInfo.isMaster==1">
			<div class="tit">系统信息</div>
			<div class="caozuo">
				<img src="@/assets/img/dui.png" alt="" @click="confirm">
				<img src="@/assets/img/cuo.png" alt="" @click="cancel">
			</div>
			<input type="text" placeholder="请输入系统名称" v-model="form.systemName">
			<input type="text" placeholder="您的团队账号" :value="userInfo.zh" readonly>
			<input type="text" placeholder="默认超管账号" :value="userInfo.username" readonly>
			<input type="password" placeholder="密码 不输入不更改" v-model="form.userpass" autocomplete="new-password">
		</div>
		<div class="con" v-else>
			<div class="tit">个人信息</div>
			<div class="caozuo">
				<img src="@/assets/img/dui.png" alt="" @click="confirm">
				<img src="@/assets/img/cuo.png" alt="" @click="cancel">
			</div>
			<input type="text" class="text-c" :value="'团队账号为'+userInfo.zh+' 管理员可修改'" readonly>
			<div class="txbox">
				<div class="imgbox" @click="uploadTrigger">
					<img :src="form.picurl" v-if="form.picurl" alt="">
					<template v-else>+</template>
					<input  type="file" ref="file" @change="uploadFile" hidden accept="image/png,image/gif,image/jpeg"/>
				</div>
				<input type="text" placeholder="请输入姓名" v-model="form.uname">	
			</div>
			<input type="text" placeholder="用户名" :value="userInfo.username" readonly>
			<input type="password" placeholder="密码 不输入不更改" v-model="form.userpass" autocomplete="new-password">
			<input type="text" placeholder="请输入职务" v-model="form.zhiwu">
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	export default {
  		name: 'SystemConfig',
  		props: {},
  		data: function(){
  			return {
  				form:{
  					
  				}
  			}
  		},
  		methods:{
  			uploadTrigger(){
  				this.$refs.file.click()
  			},
  			uploadFile(e){ 
  				this.$axios.upload(e.target.files[0])
  				.then((r)=>{
  					if(r.ok){
  						this.form.picurl = r.msg;
  					}
  				})
  			},
  			cancel(){
  				this.$emit('cancel');
  			},
  			confirm(){
  				if(this.form.isMaster==1 && this.form.systemName==''){
  					this.$message('warning','系统名称不能为空')
  					return;
  				}
  				this.global_submit('index.php?api-people-editUserInfo',this.form,(r)=>{
  					if(r.ok){
  						if(this.form.userpass!=''){
  							this.$message({theme:"danger",content:'请重新登陆',onDurationEnd:()=>{
  								window.localStorage.removeItem('userInfo');
  								location.reload();
  							}})
  							return;
  						}

  						var userInfo = this.userInfo;
  						if(userInfo.isMaster=='0'){
  							userInfo.picurl = this.form.picurl;
  							userInfo.uname = this.form.uname;
  							userInfo.zhiwu = this.form.zhiwu;
  						}else{
  							userInfo.systemName = this.form.systemName;
  						}
  						localStorage.setItem('userInfo',JSON.stringify(userInfo));
  					}
  					this.$emit('cancel');
  				},'确认修改吗？')
  				
  			}
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo'])
  		},
  		mounted(){
  			this.form = JSON.parse(JSON.stringify(this.userInfo));
  			this.form.userpass = ''
  			
  		}
  }
</script>
<style scoped>
	.system{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		background-color: rgba(0,0,0,0.4)
	}
	.con{
		position: absolute;
		left: 110px;
		top: 20px;
		width: 500px;
		background-color: #EAEEF4;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		padding: 20px 20px 50px;
	}
	.tit{
		font-size: 24px;
		color: #005baa;
		font-size: 29px;
		line-height: 50px;
	}
	.caozuo{
		position: absolute;
		right: 20px;
		top: 20px;
	}
	.caozuo img{
		width: 45px;
		margin-left: 25px;
	}
	.con>input{
		height: 60px;
		border: none;
		margin-top: 15px;
		font-size: 22px;
		padding-left: 20px;
		outline: none;
	}
	.text-c{
		text-align: center;
	}
	.txbox{
		display: flex;
		margin-top: 15px;
	}
	.txbox>input{
		flex-grow: 1;
		height: 60px;
		border: none;
		font-size: 22px;
		padding-left: 20px;
		outline: none;
		margin-left: 20px;
	}
	.imgbox{
		height: 60px;
		width: 60px;
		line-height: 50px;
		background-color: #fff;
		text-align: center;
		font-size: 46px;
		color: #9096a8;
		border: none;
		outline: none;
		border-radius: 6px;
		cursor: pointer;
	}
	.imgbox img{
		width: 100%;
		height: 100%;
	}
</style>