<template>
    <div class="iframebox" v-if="info.urltype=='1'">
        <iframe :src="info.url" height="100%" width="100%" frameborder="0"></iframe>
    </div>
    <div class="content" v-else-if="info.urltype=='2'" v-html="info.content"></div>
</template>
<script>
export default {
    name: 'Other',
    props: {},
    data: function() {
        return {
            info:{}
        }
    },
    methods: {
       
    },
 
    watch: {
       '$route':{
            handler(to,from){
                this.$axios.post('index.php?api-other-getIndexMenuCon',{id:to.query.id})
                .then((r)=>{
                    this.info = r
                })
            },
            immediate:true
        }
    },
    computed: {
    },
    components: {
        
    },
    mounted() {
       //  var iframe = this.$refs.iframe;
       //  iframe.addEventListener("load", function () {
       //     iframe.contentWindow.document.querySelect('.time-box')[0].style.right = '1.4%';
       // }, false);
       // console.log(this.$route.query.id)

    },
    
    beforeDestroy() {
        
    }
}
</script>
<style scoped src="./index.css"></style>

<style scoped>
	.iframebox{
		height: 100%;
        padding: 1rem;
	}
    .content{
        margin: 1rem;
        height: 100%;
        background-color: #fff;
        border-radius: 1rem;
        padding: 1rem;
    }
</style>