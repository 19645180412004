import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Login from '@/pages/Login'

import Index from '@/pages/Index'
import Gongshi from '@/pages/Index/Gongshi'
import Fabu from '@/pages/Index/Fabu'
import Other from '@/pages/Index/Other'

import Beiwang from '@/pages/Beiwang'
import System from '@/pages/System'
import ZherenBumen from '@/pages/System/ZherenBumen'
import ZherenBumenKind from '@/pages/System/ZherenBumenKind'
import TaskKind from '@/pages/System/TaskKind'
import GongShiKind from '@/pages/System/GongShiKind'
import BeiWangKind from '@/pages/System/BeiWangKind'
import Xietong from '@/pages/Xietong'
import AddTask from '@/pages/Xietong/AddTask'
import addGroup from '@/pages/Xietong/addGroup'
import Tongxunlu from '@/pages/Tongxunlu'
import AddBumen from '@/pages/Tongxunlu/AddBumen'
import AddPeople from '@/pages/Tongxunlu/AddPeople'
import ReviewPeople from '@/pages/Tongxunlu/ReviewPeople'
import store from '@/store'
//暴露router对象
const router = new VueRouter({
	routes:[
		{path:'*',redirect:"/Xietong"},

		{name:'Index',path:'/Index',component:Index,meta:{keepAlive:true,title:'数据'},redirect:'/Index/Gongshi',children:[
			{name:'Gongshi',path:'/Index/Gongshi',component:Gongshi,meta:{title:'数据-公示'}},
			{name:'Fabu',path:'/Index/Fabu',component:Fabu,meta:{title:'数据-发布'}},
			{name:'Other',path:'/Index/Other',component:Other,meta:{title:'数据'}},
		]},

		{name:'Login',path:'/Login',component:Login,meta:{keepAlive:false,title:'登陆'}},
		{name:'Beiwang',path:'/Beiwang',component:Beiwang,meta:{keepAlive:false,title:'备忘'}},
		{name:'System',path:'/System',component:System,meta:{keepAlive:false,title:'系统设置'},redirect:'/System/ZherenBumen',children:[
			{name:'Tag',path:'/System/ZherenBumen',meta:{title:'设置'},component:ZherenBumen},
			{name:'TagKind',path:'/System/ZherenBumenKind',meta:{title:'设置'},component:ZherenBumenKind},
			{name:'TaskKind',path:'/System/TaskKind',meta:{title:'设置'},component:TaskKind},
			{name:'GongShiKind',path:'/System/GongShiKind',meta:{title:'设置'},component:GongShiKind},
			{name:'BeiWangKind',path:'/System/BeiWangKind',meta:{title:'设置'},component:BeiWangKind},
		]},
		{name:'Xietong',path:'/Xietong',component:Xietong,meta:{keepAlive:true,title:'协同'},children:[
			{name:'addGroup',path:'/Xietong/addGroup',component:addGroup},
		]},
		{name:'AddTask',path:'/AddTask',component:AddTask,meta:{keepAlive:false}},
		{name:'Tongxunlu',path:'/Tongxunlu',component:Tongxunlu,meta:{keepAlive:false,title:'通讯录'},children:[
			{name:'AddBumen',path:'/Tongxunlu/AddBumen/:id',component:AddBumen},
			{name:'AddPeople',path:'/Tongxunlu/AddPeople/:id',component:AddPeople},
			{name:'ReviewPeople',path:'/Tongxunlu/ReviewPeople',component:ReviewPeople},
		]},
	]
})
router.beforeEach((to,from,next)=>{
	if('title' in to.meta){
		document.title = (store.state.globalStore.config.title?(store.state.globalStore.config.title+'-'):'')+to.meta.title
	}

	if(to.path == '/Login'){
		next();
	}else{
		if(localStorage.getItem('userInfo')){
			next()
		}else{
			next('/Login')
		}
	}
	
})

export default router