<template>
	<div class="backg-col">
		<div class="xuan-1">
			<div class="flex-2">
				<template v-if="checkType==1">
					<div class="left-box">选择负责人(单选)</div>
					<div class="mid-box"></div>
				</template>
				<template v-else-if="checkType==2">
					<div class="left-box">选择协同人(多选)</div>
					<div class="mid-box"></div>
				</template>
				<template v-else-if="checkType==3">
					<div class="left-box">选择负责部门(多选)</div>
					<div class="mid-box"></div>
				</template>
				<template v-else-if="checkType==4 || checkType==5 || checkType==6">
					<div class="left-box">选择任务类型(单选)</div>
					<div class="mid-box"></div>
				</template>
				<template v-else="checkType==7">
					<div class="left-box">选择人员(多选)</div>
					<div class="mid-box"></div>
				</template>

				<div class="dx-box">
					<img src="@/assets/img/dui.png" @click="confirm" alt="">
					<img src="@/assets/img/cuo.png" @click="cancel" alt="">
				</div>
			</div>
			<div class="inp-box2">
				<input type="text" v-model="searchTitle" placeholder="请输入名称进行筛选">
				<span v-show="searchTitle!=''" class="clearTitle" @click="searchTitle=''">清空</span>
				<!-- <img src="@/assets/img/Search icon4.png" class="search-img2" alt=""> -->
			</div>
			<div class="catebox" v-if="checkType==3">
				<ul>
					<li :class="tagKindId==0?'active':''" @click="tagKindId=0">全部</li>
					<li :class="tagKindId==item.id?'active':''" @click="tagKindId=item.id" v-for="item in tagKind">{{item.title}}</li>
				</ul>
			</div>
			<div class="names-list">
				<div :class="(checkIds.indexOf(item.id)>-1?'active':'')+(dIds.indexOf(item.id)>-1?' disabled':'')" v-for="item in dataList" @click="check(item.id)">
					{{(checkType==3 || checkType==4 || checkType==5 || checkType==6)?item.title:item.name}}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState,mapGetters } from 'vuex'
	export default {
  		name: 'SelectPeople',
  		props: {
  			'checkType':[Number,String],
  			'alreadyCheckIds':{
  				type:Array,
  				default:function(){
  					return []
  				}
  			},
  			'peopleType':String,
  			'dIds':{
  				type:Array,
  				default:function(){
  					return []
  				}
  			}
  		},
  		data: function(){
  			return {
  				dataList:[],
  				olddata:[],
  				checkIds:[],
  				searchTitle:'',
  				tagKindId:0
  			}
  		},
  		methods:{
  			check(id){
  				//单选
  				if(this.dIds.indexOf(id) > -1){
  					return
  				}
  				if(this.checkType==1 || this.checkType==4 || this.checkType==5 || this.checkType==6){
  					this.checkIds = [id]
  				}else{
  					var ind = this.checkIds.indexOf(id);
  					if(ind>-1){
  						this.checkIds.splice(ind,1)
  					}else{
  						this.checkIds.push(id)
  					}
  				}
  			},
  			confirm(){
  				this.$emit('confirm',this.checkIds)
  				this.$emit('cancel');
  			},
  			cancel(){
  				this.$emit('cancel');
  			}
  		},
  		watch:{
  			searchTitle(newv,oldv){
  				// console.log(newv)
  				var newData = [];
  				for(var i in this.olddata){
  					if(this.checkType==3 || this.checkType==4 || this.checkType==5 || this.checkType==6){
  						if(this.olddata[i].title.indexOf(newv) > -1){
  							newData.push(this.olddata[i])
  						}
  					}else{
  						if(this.olddata[i].name.indexOf(newv) > -1){
  							newData.push(this.olddata[i])
  						}
  					}
  				}
  				this.dataList = newData
  			},
  			tagKindId(newv,oldv){
  				if(!newv){
  					if(this.searchTitle!=''){
  						var d = [];
  						this.tagList.forEach((item,index)=>{
  							if(item.title.indexOf(this.searchTitle) > -1){
  								d.push(item)
  							}
  						})
  						this.dataList = d;
  					}else{
  						this.dataList = this.tagList
  					}

  					this.olddata = this.tagList
  				}else{

  					var d = [];
  					this.tagList.forEach((item,index)=>{
  						if(this.searchTitle!=''){
  							if(item.title.indexOf(this.searchTitle) > -1 && item.cid==newv){
  								d.push(item)
  							}
  						}else if(item.cid==newv){
  							d.push(item)
  						}
  						
  					})
  					this.dataList = d;
  					this.olddata = d;
  				}
  			}
  		},
  		computed: {
  		    ...mapState('globalStore', ['peopleList','tagList','tagKind','taskKind','taskGsKind','taskBwKind','myBumenPeople']),
  		},
  		mounted(){
  			// console.log(this.checkType)
  			// console.log(this.dIds)
  			var r = [];
  			//责任部门 tag
  			if(this.checkType==3){
  				r = this.tagList
  			}else if(this.checkType==4){
  				r = this.taskKind
  			}else if(this.checkType==5){
  				r = this.taskGsKind
  			}else if(this.checkType==6){
  				r = this.taskBwKind
  			}else{
  				if(this.peopleType=='allPeople'){
  					r = this.peopleList
  				}else{
  					this.myBumenPeople.forEach((item,i)=>{
  						item.plist.forEach((it,a)=>{
  							r.push(it)
  						})
  					})
  				}
  			}
  			this.dataList = r;
  			this.olddata = r;

  			if(this.alreadyCheckIds){
  				this.checkIds = this.alreadyCheckIds
  			}
  			
  		}
  }
</script>
<style scoped>
	.backg-col{
		position: absolute;
		z-index: 999;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(49,58,76,0.5);
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.xuan-1,.xuan-2,.xuan-3{
		width: 730px;
		height: 606px;
		background-color: #eaeef4;
		color: #325b91;
		padding: 0 20px;
		border-radius: 30px;
		overflow: hidden;
	}
	.search-img2{
		width: 37px;
		height: 37px;
		margin-right: 25px
	}
	.mid-box{
		margin-right: auto;
		color: #4b4b4b;
		margin-left: 10px;
	}
	.xuan-1 .flex-2{
		align-items: baseline;
		margin: 30px 0 15px 0;
	}
	.xuan-2 .flex-2{
		align-items: baseline;
		margin: 30px 0 15px 0;
	}
	.xuan-3 .flex-2{
		align-items: baseline;
		margin: 30px 0 15px 0;
	}
	.left-box{
		color: #005baa;
		font-size: 30px;
	}
	.xuan-1 img{
		margin-left: 10px;
	}
	.inp-box2{
		height: 78px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		padding: 0 20px;
	}
	.inp-box2 input{
		font-size: 30px;
		color: #000;
		border: none;
		outline: none;
		flex-grow: 1;

	}
	.inp-box2 span{
		color: #005baa;
		font-size: 20px;
		margin-right: 14px;
		cursor: pointer;
		margin-left: 20px;
	}
	input::-webkit-input-placeholder {
	  /* WebKit browsers */
	  color: #e0e4ec;
	}

	input:-moz-placeholder {
	  /* Mozilla Firefox 4 to 18 */
	  color: #e0e4ec;
	}

	input::-moz-placeholder {
	  /* Mozilla Firefox 19+ */
	  color: #e0e4ec;
	}

	input::-ms-input-placeholder {
	  /* Internet Explorer 10+ */
	  color: #e0e4ec;
	}

	.names-list{
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		margin-top: 10px;		
		height: 380px;
		overflow-y: auto;
	}
	.names-list>div{
		padding: 0 20px;
		height: 50px;
		color: #d3d8e3;
		text-align: center;
		line-height: 50px;
		background-color: #d3d8e3;
		color: #325b91;
		margin-bottom: 10px;
		margin-right: 10px;
		cursor: pointer;
	}
	.names-list>div.active{
		background-color: #005baa;
		color: #ffffff;
	}
	.names-list>div.disabled{
		cursor: not-allowed;
		background-color: #f7f8fa;
	}

	.catebox{
		font-size: 18px;
		overflow-x:auto;
		overflow-y:hidden;
	}
	.catebox ul{
		display: flex;
		flex-wrap: nowrap;
		overflow-x:auto;
	}
	.catebox ul li{
		color: #005baa;
		border-bottom: 2px solid transparent;
		padding: 10px 20px;
		cursor: pointer;
		user-select:none;
		flex-shrink: 0;
	}
	.catebox ul li.active{
		border-bottom-color: #005baa;
	}
</style>