<template>
		<div :class="'sxb-box '+(!isScreenFull?'full':'')" ref="conbox">
			<div class="dxbig-box">
				<img src="@/assets/img/dui.png" @click="success" alt="">
				<img src="@/assets/img/cuo.png" @click="cancel" alt="">
			</div>
			<div class="con" ref="con">
				<canvas ref="cavs" @touchstart.passive="draw_start" @touchmove.passive="draw_move" @touchend.passive="draw_end" @mousedown="draw_start" @mousemove="draw_move" @mouseup="draw_end"></canvas>
			</div>

			
			<div class="mar-0-auto">
				<div class="rangebox hb_range" v-if="show_hb_range">
					<span :style="{left:((200/30)*hb_width-40)+'px'}">{{hb_width}}</span>
					<input type="range"  v-model="hb_width" min="1" max="30">
				</div>
				<div class="rangebox xp_range"  v-if="show_xp_range">
					<span :style="{left:((200/100)*xp_width-40)+'px'}">{{xp_width}}</span>
					<input type="range" v-model="xp_width" min="10" max="100">
				</div>

				<input type="color" class="color" v-model="hb_color">
				<img src="@/assets/img/clear.png" alt="" @click="clear_area">
				<img src="@/assets/img/pencil.png" class="img3-1" alt="" @click="hb_click">
				<img src="@/assets/img/xiangpi.png" alt="" @click="xp_click">
				<!-- <img src="@/assets/img/chuan.png" class="img3-2" alt="" @click="cr_pic"> -->
				<!-- <img src="@/assets/img/wenben.png" alt="" @click="cr_text"> -->
				<img src="@/assets/img/cexiao.png" alt="" @click="cexiao">
			</div>
		</div>
</template>
<script>
	import { mapState } from 'vuex'
	export default {
  		name: 'Drawing',
  		props: {},
  		data: function(){
  			return {
  				show_hb_range:false,
  				show_xp_range:false,
  				hb_color:'#000000',
  				hb_width:5, //画笔大小
  				xp_width:10, //橡皮擦大小
  				context:null, //画板2d对象
  				arrImg:[],
  				bool:false,
  				scale:1,
  				cavs_left:0,		
  				cavs_top:0,
  				cavs_width:0,
  				cavs_height:0
  			}
  		},
  		methods:{
  			draw(){

  			},
  			draw_start(e){
  				if('touches' in e && e.touches.length>0){
  					e = e.touches[0]
  				}
  				this.bool = true;
  				
  				var m_x = e.pageX/this.scale - this.cavs_left, 
  				m_y = e.pageY/this.scale - this.cavs_top;
  				this.context.beginPath();
  				this.context.moveTo(m_x, m_y); //鼠标在画布上的点
  				var that = this;
  				var imgData = this.context.getImageData(0, 0, this.cavs_width, this.cavs_height);
  				this.arrImg.push(imgData);
  			},
  			draw_move(e){
  				if('touches' in e && e.touches.length>0){
  					e = e.touches[0]
  				}
  				if (this.bool) {
  				    this.context.lineTo(e.pageX/this.scale - this.cavs_left, e.pageY/this.scale - this.cavs_top);
  				    this.context.stroke(); //绘制出路径
  				}
  			},
  			draw_end(){
  				this.context.closePath(); //结束自动闭合
  				this.bool = false; //鼠标不移动时画笔断开
  			},
  			xp_click(){
  				this.show_hb_range=false;
  				if(!this.show_xp_range){
  					this.context.strokeStyle = '#fff';
  					this.context.lineWidth = this.xp_width
  					this.show_xp_range = true;
  				}else{
  					this.context.strokeStyle = this.hb_color;
  					this.context.lineWidth = this.hb_width
  					this.show_xp_range = false;
  				}
  				
  			},
  			hb_click(){
  				this.show_hb_range=!this.show_hb_range;
  				this.show_xp_range=false
  				this.context.strokeStyle = this.hb_color;
  				this.context.lineWidth = this.hb_width
  			},
  			clear_area(){
  				this.context.clearRect(0, 0, this.cavs_width, this.cavs_height)
  			},
  			cexiao(){
  				if (this.arrImg.length > 0) {
  				    this.context.putImageData(this.arrImg.pop(), 0, 0);
  				}
  			},
  			cr_pic(){

  			},
  			cr_text(){

  			},
  			cancel(){
  				this.$emit('hideDrawing',false)
  			},
  			success(){
  				var imageData = this.context.getImageData(0, 0, this.cavs_width, this.cavs_height);
  				for (var i = 0; i < imageData.data.length; i += 4) {
  				      	// 当该像素是透明的，则设置成白色
  				      	if (imageData.data[i + 3] == 0) {
	  				        imageData.data[i] = 255;
	  				        imageData.data[i + 1] = 255;
	  				        imageData.data[i + 2] = 255;
	  				        imageData.data[i + 3] = 255;
  				      	}
  				}
  				this.context.putImageData(imageData, 0, 0);
  				var base64Data = this.$refs.cavs.toDataURL('images/jpg')

  				function dataURItoBlob (base64Data) {
  				    var byteString;
  				    if (base64Data.split(',')[0].indexOf('base64') >= 0)
  				        byteString = atob(base64Data.split(',')[1]);
  				    else
  				        byteString = unescape(base64Data.split(',')[1]);
  				    var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
  				    var ia = new Uint8Array(byteString.length);
  				    for (var i = 0; i < byteString.length; i++) {
  				        ia[i] = byteString.charCodeAt(i);
  				    }
  				    return new Blob([ia], {type: 'image/jpg'});
  				};
				//封装blob对象
			    var blob = dataURItoBlob(base64Data);
			    
			    this.$axios.upload(blob)
			    .then((r)=>{
			    	if(r.ok){
			    		this.$emit('returnImg',r.msg)
			    		this.$emit('hideDrawing',false)
			    	}
			    })

  			}

  		},
  		watch:{
  			hb_width(newv){
  				this.context.lineWidth = newv;
  			},
  			xp_width(newv){
  				this.context.lineWidth = newv;
  			},
  			hb_color(newv){
  				this.context.strokeStyle = newv;
  			}
  		},
  		computed: {
  		    ...mapState('globalStore', ['isScreenFull']),
  		},
  		mounted(){
  			var conbox = this.$refs.conbox;
  			var con = this.$refs.con;
  			var cavs = this.$refs.cavs;
  			this.cavs_width 	= cavs.width = con.clientWidth
  			this.cavs_height 	= cavs.height = con.clientHeight
  			this.cavs_left 		= conbox.offsetLeft
  			this.cavs_top 	= conbox.offsetTop

  			this.context = cavs.getContext('2d');
  			this.context.lineCap = 'round'; //线条起始与结尾样式
  			this.context.lineJoin = 'round'; //转弯
  			this.context.lineWidth = 5; //转弯
  			var devicewidth = window.screen.width;
  			var standWidth = 1920; 
  			if(window.screen.width < window.screen.height){
  				standWidth = 1080; //竖
  			}
  			this.scale = (devicewidth / standWidth).toFixed(4);
  		}
  }
</script>
<style scoped>
	
	.sxb-box{
		min-width: 829px;
		/*height: calc(100vh - 80px);
		margin: 40px;*/
		border-radius: 30px;
		background-color: #ffffff;
		/*padding: 30px 50px 30px 30px;*/
		position: absolute;
		left: 40px;
		top: 40px;
		right: 40px;
		bottom: 40px;
		overflow: hidden;
	}
	.sxb-box.full{
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
	}
	.dxbig-box{
		position: absolute;
		top: 60px;
		right: 50px;
		z-index: 999;
	}
	.dxbig-box img{
		margin-left: 20px;
		width: 60px;
		height: 60px;
		user-drag: none;
		-webkit-user-drag: none;

	}

	.con{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 117px;
	}
	
	.point6-box2{
		position: relative;
		height: 4px;
		width: 260px;
		background-color: #005baa;
		color: #005baa;
		margin: 0 auto;
	}
	.mar-0-auto{
		position: absolute;
		left: 0;
		bottom: 28px;
		right: 0;
		display: flex;
		justify-content: center;
	}
	.hb_range{
		position: absolute;
		top: -35px;
		left: 50%;
		margin-left: -207px;
	}
	.xp_range{
		position: absolute;
		top: -35px;
		left: 50%;
		margin-left: -97px;
	}
	
	.mar-0-auto img{
		margin: 0 28px;
		cursor: pointer;
		user-drag: none;
		-webkit-user-drag: none;
		
	}
	.mar-0-auto img.active{
		background-color: rgba(255,255,255,0.5);
	}
	.mar-0-auto .color{
		width: 55px;
		height: 55px;
		/*border-radius: 100%;*/
		overflow: hidden;
		outline: none;
		margin: 0 28px;
	}
	.img3-1{
		width: 50px;
		height: 60px;
	}
	.img3-2{
		width: 80px;
		height: 60px;
	}
</style>