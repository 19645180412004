<template>
    <div class="conbox">
        <div class="rwlb">
            <div class="rwlb-tit">
                <div class="font-25 col-051349">备忘录</div>
                <div class="state-1">
                    <div :class="state==0?'active':''" @click="stateChange(0)">
                        <div>待完成</div>
                        <div class="num-2 font-12">{{statusNum[0]}}</div>
                    </div>
                    <div :class="state==1?'active':''" @click="stateChange(1)">
                        <div>已完成</div>
                        <div class="num-2 font-12">{{statusNum[1]}}</div>
                    </div>
                </div>
            </div>
            <div class="search-box">
               
                <div class="inp-box">
                    <input type="text" class="s-input" v-model="taskTitle" placeholder="搜索任务">
                    <img src="@/assets/img/Search icon4.png" @click="searchTask" class="se-img" alt="">
                </div>
                <div class="bq-box" @click="showSaixuan=true"><img src="@/assets/img/biaoqian.png" class="bqian-img" alt=""></div>
                <div class="clear" v-if="saixuanArgs || taskTitle" @click="saixuanArgs=false;taskTitle='';getTaskStatus()">清空条件</div>

                <router-link :to="{path:'AddTask',query:{t:1,cateId:this.cid}}" class="jiahao-box"><img src="@/assets/img/pic_4.png" class="pic-4" alt=""></router-link>
            </div>
            <div class="cate_box" style="margin-top:0;">
                <ul>
                    <li :class="cid==0?'active':''" @click="taskKindChange(0)">全部</li>
                    <li :class="cid==item.id?'active':''" v-for="item in taskBwKind" @click="taskKindChange(item.id)">{{item.title}}</li>
                </ul>
            </div>
            <div class="taskTable">
                <TaskTable @activeInfo="showTaskInfo"></TaskTable>
            </div>
        </div>

        <Saixuan v-if="showSaixuan" @cancel="showSaixuan=false" @saixuan="getTaskStatus" pageType="beiwang" />
        <TaskDetail v-if="ActiveTaskinfo" :info="ActiveTaskinfo" @reload="getTaskStatus"></TaskDetail>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import TaskTable from '@/components/TaskTable'
import TaskDetail from '@/components/TaskDetail'
import Saixuan from '@/components/Saixuan'
import ws_beiwang_mixins from '@/api/ws_beiwang_mixins.js'
export default {
    name: 'Beiwang',
    props: {},
    data: function() {
        return {
            state: 0, //0待完成1已完成
            statusNum: [0, 0], //待完成数量,已完成数量
            ActiveTaskinfo: false,
            taskTitle: '',
            cid:0,
            saixuanArgs:false,
            showSaixuan:false
        }
    },
    methods: {
        taskKindChange(id){
            this.cid = id;
            this.getTaskStatus();
        },
        getTaskStatus: function(args) {
            var data = {
                cid:this.cid,
                t:1,
                taskTitle:this.taskTitle
            }
            if(args && 'star' in args){
                this.saixuanArgs = args
                if(args.cid>0){
                    this.cid = args.cid
                }
                data = Object.assign(data, args);
            }else if(this.saixuanArgs){
                data = Object.assign(data, this.saixuanArgs);
            }
            
            this.$axios.post('index.php?api-task-getTaskStatus', JSON.parse(JSON.stringify(data)))
            .then((r) => {
                this.statusNum = r;
                data.state = this.state;
                data.statusNum = r;

                this.$bus.$emit('getTaskList', data)
            })
        },
        searchTask: function() {
            this.getTaskStatus();
        },
        stateChange: function(s) {
            this.state = s;
            this.$nextTick(() => {
                this.$bus.$emit('getTaskList', {
                    state: this.state,
                    t: 1,
                    taskTitle: this.taskTitle,
                    statusNum: this.statusNum
                })
            })

        },
        showTaskInfo: function(info) {
            this.ActiveTaskinfo = info;
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.name == 'AddTask') {
            // if(to.params.referesh){
            //     next(vm=>{
            //         vm.state = 0;
            //         vm.getTaskStatus();
            //     })
            // }else{
                next();
            // }    
        }else{
            next(vm=>{
                vm.getTaskStatus();
            })
        }
    },
    components: {
        TaskTable,
        TaskDetail,
        Saixuan
    },
    computed: {
        ...mapState('globalStore', ['userInfo','taskBwKind','ws'])
    },
    mixins: [ws_beiwang_mixins],
    mounted() {
        console.log('BeiWangmounted')        
    }
}
</script>
<style scoped src="../Xietong/index.css">

</style>