import store from '@/store/index.js'
import {DialogPlugin,MessagePlugin} from 'tdesign-vue'
import mp3 from "@/assets/1.mp3";

let ssg = store.state.globalStore
let heartbeat = null
let timeConnect = 0
let sucAudio = new Audio(mp3);
sucAudio.load()
ssg.sucAudio = sucAudio

ssg.msgAudio = true
sucAudio.play();

// let confirmDia = DialogPlugin.confirm({
// 	theme:"default",
// 	header:"是否开启新消息提示声",
// 	body:"",
// 	closeOnOverlayClick:false,
// 	cancelBtn:"关闭",
// 	confirmBtn:"开启",
// 	onConfirm:(context)=>{
// 		ssg.msgAudio = true
// 		sucAudio.play();
// 		confirmDia.destroy()
// 	}
// })


window.addEventListener('online', ()=>{
	ssg.onLine = true
})
window.addEventListener('offline', ()=>{
	ssg.onLine = false
})


const ws_init = ()=>{
	var ws = new WebSocket("wss://"+document.domain+":7272");
	// var ws = new WebSocket("ws://"+document.domain+":7272");
	ws.onopen = ()=>{
		if(ws.readyState == 1){
			ws.send(JSON.stringify({
				type:'init',
				uid:ssg.userInfo.uid,
				clubId:ssg.userInfo.clubId,
				firstBumenId:ssg.userInfo.firstBumenId,
				topBumenId:ssg.userInfo.topBumenId
			}));
			heartbeat = setInterval(function(){
			    ws.send('{"type":"pong"}')
			},50000)
		}
	}
	ws.onmessage = (e)=>{
		var data = JSON.parse(e.data);
		// ssg.wsOnMessage = data;
		store.commit('globalStore/setWebSocketMsg',data)
		// console.log(data)
	}
	ws.onclose = ()=>{
	  	console.log('网络出问题啦~')
	  	clearInterval(heartbeat)
	  	reconnect()
	}
	ws.onerror = (res)=>{
		MessagePlugin('error','即时通讯网络出问题啦~')
		console.log("出现错误"+JSON.stringify(res));
	}
	ssg.ws = ws;
}

const reconnect = ()=>{
	timeConnect++
	console.log('第'+timeConnect+'次重连')
	if(timeConnect > 10){
		let confirmDia = DialogPlugin.confirm({theme:'warning',header:"提示",body:"即时通讯连接已断开，是否重试？",cancelBtn:null,onConfirm:()=>{
			ws_init()
			confirmDia.destroy()
		}})
		return
	}
	setTimeout(()=>{
		ws_init()
	},5000)
}
export default {
	ws_init
}