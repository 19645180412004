<template>
	<div class="conbox">
		<div class="rwlb-tit">
			<div class="font-25 col-051349">责任部门(标签)分类</div>
			<div class="zjl-box">
				<input type="text" class="zjl-1" placeholder="请输入分类名称" v-model="title">
				<div class="zjl-2" @click="add" v-if="activeIndex===false">添加</div>
				<div class="zjl-2" @click="add" v-else>修改</div>
				<div class="zjl-3" @click="del" v-if="activeIndex!==false">删除</div>
			</div>
		</div>
		<div class="cont-box2" :style="{zoom:zoom}">
			<vuedraggable class="cont-names" v-model="data" animation="300" @sort="dataUpdate" :options="{handle:'.title'}">
				<div v-for="(item,index) in data"  @click="select(index)" :class="'conlist '+(activeIndex===index?'active':'')" :key="item.id">
					<div class="title">{{item.title}}</div>
					<img src="@/assets/img/pencil.png" alt="">
				</div>
			</vuedraggable>
		</div>
	</div>
</template>
<script>
	import { mapState,mapActions,mapMutations  } from 'vuex'
	import vuedraggable from 'vuedraggable';
	export default {
  		name: 'ZherenBumenKind',
  		props: {},
  		data: function(){
  			return {
  				title:'',
  				data:[],
  				activeIndex:false,
  				zoom:1
  			}
  		},
  		methods:{
  			add(){
  				var data = {
  					title:this.title
  				}
  				if(this.activeIndex !== false){
  					data.id = this.data[this.activeIndex]['id']
  				}
  				this.global_submit('index.php?api-other-addEditTagKind',data,(r)=>{
  					if(this.activeIndex === false){
  						this.data.push({id:r.insertId,title:this.title})
  					}else{
  						this.data[this.activeIndex]['title'] = this.title
  						this.activeIndex = false;
  					}
  					this.title=''
  					this.$store.commit('globalStore/changeTagKind',this.data)
  				})
  			},
  			select(ind){
  				if(this.activeIndex === ind){
  					this.activeIndex = false;
  					this.title = ''
  				}else{
  					this.title = this.data[ind]['title']
  					this.activeIndex = ind;
  				}
  			},
  			del(){
  				this.global_del('index.php?api-other-delTagKind',{
  					id:this.data[this.activeIndex]['id']
  				},(r)=>{
					this.data.splice(this.activeIndex,1)
					this.title = ''
					this.activeIndex = false;
					this.$store.commit('globalStore/changeTagKind',this.data)
  				},'该分类下所有的责任部门也会被一同删除<br/>确认删除吗')
  			},
  			dataUpdate(e){
  				this.global_sort({data:this.data,tableName:'tag_kind'})
  				this.$store.commit('globalStore/changeTagKind',this.data)
  			},
  			...mapActions('globalStore',['getTagKind']),
  			...mapMutations('globalStore',['changeTagKind'])
  		},
  		computed: {
        	
       	},
       	watch:{

       	},
  		components:{
  			vuedraggable
  		},
  		mounted(){
  			this.$store.dispatch('globalStore/getTagKind')
  			.then((r)=>{
  				this.data = r;
  			})

  			var appzoom = document.querySelector('#app').style.zoom;
  			if(appzoom && appzoom<0.9){
  				this.zoom = 2-appzoom;
  			}
  		}
  }
</script>
<style scoped src="./index.css">
	
</style>