import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex) //应用Vuex vm实例对象多了$store

import axios from '@/api/axios.js'
Vue.prototype.$axios = axios

//引入小仓库
import test from './test'

//全局仓库
const globalStore = {
	namespaced:true,
	actions:{
		//获取app配置信息
		getAppInfo({commit}){
			axios.get('index.php?api-app-appInfo')
			.then((r) => {
				commit('changeAppInfo',r);
			})
		},
		// 获取所有人员 超管除外
		getAllPeople({commit,state}){
			axios.get('index.php?api-people-getPeople')
	        .then((r) => {
	            commit('changePeople',r)
	        })
		},
		// 获取所有的责任部门
		getAllTag({commit,state}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-other-getTag')
				.then((r)=>{
					resolve(r);
				    commit('changeTag',r)
				})
			})
		},
		//获取所有的任务人员（我的上级和我的下级）
		getTaskPeople({commit,state}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-people-getTaskPeople')
				.then((r)=>{
					resolve(r);
				    commit('changeTaskPeople',r)
				})
			})
		},
		//获取当前端口下所有的部门和人员
		getAllBumenPeople({commit,state}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-bumen-getAllBumenPeople',{bumenId:state.userInfo.bumenId})
	  			.then((r)=>{
					resolve(r);
					commit('changeAllBumenPeople',r);
	  			})
			})
		},
		//获取当前账号所属的一级部门(不是顶级部门)下所有的子部门以及人员
		getMyBumenPeople({commit,state}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-bumen-getMyBumenPeople',{topBumenId:state.userInfo.topBumenId,bumenId:state.userInfo.bumenId})
	  			.then((r)=>{
					resolve(r);
					commit('changeMyBumenPeople',r);
	  			})
			})
		},
		//获取标签分类(责任部门分类)
		getTagKind({commit}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-other-getTagKind')
				.then((r)=>{
					resolve(r);
					commit('changeTagKind',r);
				})
			})
			
		},
		//获取任务分类
		getTaskKind({commit}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-other-getTaskKind')
				.then((r)=>{
					resolve(r);
					commit('changeTaskKind',r);
				})
			})
			
		},
		//获取公示任务分类
		getGsTaskKind({commit}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-other-getGsTaskKind')
				.then((r)=>{
					resolve(r);
					commit('changeGsTaskKind',r);
				})
			})
		},
		//获取个人备忘分类
		getBwTaskKind({commit}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-other-getBwTaskKind')
				.then((r)=>{
					resolve(r);
					commit('changeBwTaskKind',r);
				})
			})
		},
		//获取个人笔记分类
		getBjKind({commit}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-other-getBjKind')
				.then((r)=>{
					resolve(r);
					commit('changeBjKind',r);
				})
			})
		},
		getGroupList({commit}){
			return new Promise((resolve,reject)=>{
				axios.get('index.php?api-group-getGroupList')
				.then((r)=>{
					resolve(r);
					commit('changeGroup',r);
				})
			})
		},
		

		//设置进度已读 替代 setNoReadJindu
		setJinduAlreadyRead({commit,state},value){
			return new Promise((resolve,reject)=>{
				axios.post('index.php?api-record-setAlreadyRead',{taskid:value.id})
				.then((r)=>{
					resolve(r);					
					//websocket通知其他端已读
					if(state.ws.readyState == 1 && r.ok){
						state.ws.send(JSON.stringify({
							type		: 'readJindu',
							taskid  	: value.id,
							pt 			: value.pt,
							target_uid	: value.uid,
							uid 		: state.userInfo.uid,
							no_read_jindu:value.no_read_jindu,
							groupid 	: value.groupid
						}))
					}
					if(!r.ok){
						this.$message('warning','网络错误003~')
					}
				})
			})
		}	
	},
	
	mutations:{
		changeAppInfo(state,value){
			state.config = value;
			if(value.ico){
				const link = document.createElement('link')
				link.rel = 'icon'
				link.href = axios.Fullurl(value.ico)
				document.getElementsByTagName('head')[0].appendChild(link)
			}
		},
		changePeople(state,value){
			state.peopleList = value;
		},
		changeTag(state,value){
			state.tagList = value
		},
		changeTaskPeople(state,value){
			state.taskPeople = value
		},
		changeAllBumenPeople(state,value){
			state.allBumenPeople = value
		},
		changeMyBumenPeople(state,value){
			value.forEach((item,i)=>{
				item.plist.forEach((it,index)=>{
					it.no_read_task = 0
				})	
			})
			state.myBumenPeople = value
		},
		changeTagKind(state,value){
			state.tagKind = value;
		},
		changeTaskKind(state,value){
			state.taskKind = value;
		},
		changeGsTaskKind(state,value){
			state.taskGsKind = value;
		},
		changeBwTaskKind(state,value){
			state.taskBwKind = value;
		},
		changePt(state,value){
			state.pt = value;
		},
		changeBjKind(state,value){
			state.userBjKind = value
		},
		changeGroup(state,value){
			value.forEach((item,i)=>{
				item.no_read_task = 0
			})
			state.groupList = value;
		},
	
		
		//设置群组 未读进度 数
		setGroupAlreadyRead(state,value){
			// console.log(value)
			state.groupList.forEach((item,i)=>{
				if(item.id == value.id){
					if(value.readnum < 0 && item.no_read_jindu<Math.abs(value.readnum)){
						item.no_read_jindu = 0
					}else{
						item.no_read_jindu = parseInt(item.no_read_jindu) + parseInt(value.readnum)
					}
					
				}
			})
		},
		//设置 人员列表 未读进度数
		setMyBumenPeopleAlreadyRead(state,value){
			console.log('setMyBumenPeopleAlreadyRead',value)
			state.myBumenPeople.forEach((item,i)=>{
				item.plist.forEach((it,index)=>{
					if(it.id == value.id){
						if(value.readnum<0 && it.no_read_jindu<Math.abs(value.readnum)){
							it.no_read_jindu = 0
							return
						}
						it.no_read_jindu = parseInt(it.no_read_jindu) + parseInt(value.readnum)
					}
				})
			})
		},

		setNoCompleteTask(state,value){
			if(value.pt==0){
				state.myBumenPeople.forEach((item,i)=>{
					item.plist.forEach((it,a)=>{
						if(it.id == value.uid){
							it.no_complete_task -= 1;
						}
					})
				})
			}else{
				state.groupList.forEach((item,i)=>{
					if(item.id == value.groupid){
						item.no_complete_task -= 1
					}
				})
			}
			
		},
		//设置人的未完成任务数量
		setPeopleNoCompleteTask(state,value){
			state.myBumenPeople.forEach((item,i)=>{
				item.plist.forEach((it,index)=>{
					if(it.id == value.id){
						it.no_complete_task = parseInt(it.no_complete_task) + parseInt(value.num) 
					}
				})
			})
		},
		//设置人的未读任务数量
		setPeopleNoReadTask(state,value){
			state.myBumenPeople.forEach((item,i)=>{
				item.plist.forEach((it,index)=>{
					if(it.id == value.id){
						if('reset' in value){
							it.no_read_task = 0
							return
						}
						if(value.num < 0 && it.no_read_task==0){
							return
						}
						it.no_read_task = parseInt(it.no_read_task) + parseInt(value.num) 
					}
				})
			})
		},
		//设置所有人的未读任务为0
		setPeopleNoReadTaskClear(state,value){
			state.myBumenPeople.forEach((item,i)=>{
				item.plist.forEach((it,index)=>{
					it.no_read_task =0
				})
			})
		},
		//群根据id设置未完成任务数量
		setGroupNoCompleteTask(state,value){
			state.groupList.forEach((item,i)=>{
				if(item.id == value.id){
					item.no_complete_task = parseInt(item.no_complete_task) + parseInt(value.num)
				}
			})
		},
		//设置群的未读任务数量
		setGroupNoReadTask(state,value){
			state.groupList.forEach((item,i)=>{
				if(item.id == value.id){
					if('reset' in value){
						item.no_read_task = 0
						return
					}
					if(value.num < 0 && item.no_read_task==0){
						return
					}
					item.no_read_task = parseInt(item.no_read_task) + parseInt(value.num)
				}
			})
		},
		//设置进度
		addJindu(state,value){
			if('taskInfo' in value){
				delete value.taskInfo
			}
			state.newJindu = value
		},
		//进度已读
		readJindu(state,value){
			state.alreadyJindu = value
		},

		//群删除
		delGroup(state,value){
			state.groupList.forEach((item,i)=>{
				if(item.id==value){
					state.groupList.splice(i,1)
				}

			})
		},
		//新建群
		addGroup(state,info){
			info.uids = info.uids.join(',')
			info.no_read_jindu = 0;
			info.no_complete_task = 0;
			state.groupList.push(info)
		},
		editGroup(state,value){
			state.groupList.forEach((item,i)=>{
				if(item.id==value.id){
					item = value
				}
			})
		},
		//推出群
		exitGroup(state,value){
			state.groupList.forEach((item,i)=>{
				if(item.id==value.id){
					item.uids = value.uids
				}
			})
		},
		//设置通知已读数
		setNoticeAlreadyRead(state,value){
			if(value.num == 'allRead'){
				state.noReadNotice[value.t] = 0
			}else{
				state.noReadNotice[value.t] += parseInt(value.num)
			}
			
		},
		setWebSocketMsg(state,value){
			state.wsOnMessage = value
		}
	},
	state:{
		config:{},
		userInfo:JSON.parse(localStorage.getItem('userInfo')),
		isScreenFull:true,
		peopleList:[], //所有人
		taskPeople:[], //已废除 使用myBumenPeople代替
		pt:0,  //0我的接收  1我的下发
		tagList:[],
		tagKind:[],
		taskKind:[],
		taskGsKind:[],
		taskBwKind:[],
		userBjKind:[],

		allBumenPeople:[],
		myBumenPeople:[],

		groupList:[],

		noReadNotice:[0,0,0,0,0,0,0,0,0], //1新任务2进度变更3任务到期提醒4任务取消通知5任务到期6代办到期提醒7笔记到期提醒
		noTxtaskNum:0, //未到提醒时间的任务（协同、代办、笔记）总数量

		taskRefresh:0,
		newJindu:{},
		alreadyJindu:{}, 

		ws:null,
		wsOnMessage:{},
		sucAudio:null,
		onLine:navigator.onLine,
		msgAudio:false, //有新消息是否播放

	},
	getters:{
		getNames:(state)=>(uids)=>{
			// console.log(state)
			uids = String(uids)
			var name = [];
			if(state.peopleList.length>0){
				state.peopleList.forEach(function(item,i){
					if(uids.indexOf(item.id) > -1){
						name.push(item.name)
					}
				})
			}
			return name
		},
		getPeoples:(state)=>(uids)=>{
			uids = String(uids)
			var name = [];
			if(state.peopleList.length>0){
				state.peopleList.forEach(function(item,i){
					if(uids.indexOf(item.id) > -1){
						name.push(item)
					}
				})
			}
			return name
		},
		getPeopleByUids:(state)=>(uids)=>{
			return uids.map((item,i)=>{
				var info = []
				state.peopleList.forEach((it,i)=>{
					if(it.id==item){
						info = it
					}
				})
				return info
			})
		},
		getInfoById:(state)=>(uid)=>{
			var info = [];
			if(state.peopleList.length>0){
				state.peopleList.forEach(function(item,i){
					if(item.id == uid){
						info = item;
					}
				})
			}
			return info
		},
		getTags:(state)=>(ids)=>{
			ids = String(ids)
			var name = [];
			if(state.tagList.length>0){
				state.tagList.forEach(function(item,i){
					if(ids.indexOf(item.id) > -1){
						name.push(item)
					}
				})
			}
			return name
		},
		getTagNames:(state)=>(ids)=>{
			ids = String(ids)
			var name = [];
			if(state.tagList.length>0){
				state.tagList.forEach(function(item,i){
					if(ids.indexOf(item.id) > -1){
						name.push(item.title)
					}
				})
			}
			return name
		},
		getTaskKinds:(state)=>(ids)=>{
			ids = String(ids)
			var data = [];
			if(state.taskKind.length>0){
				state.taskKind.forEach(function(item,i){
					if(ids.indexOf(item.id) > -1){
						data.push(item)
					}
				})
			}
			return data
		},
		getGsTaskKinds:(state)=>(ids)=>{
			ids = String(ids)
			var data = [];
			if(state.taskGsKind.length>0){
				state.taskGsKind.forEach(function(item,i){
					if(ids.indexOf(item.id) > -1){
						data.push(item)
					}
				})
			}
			return data
		},
		getBwTaskKinds:(state)=>(ids)=>{
			ids = String(ids)
			var data = [];
			if(state.taskBwKind.length>0){
				state.taskBwKind.forEach(function(item,i){
					if(ids.indexOf(item.id) > -1){
						data.push(item)
					}
				})
			}
			return data
		}
	}
}

export default new Vuex.Store({
	modules:{
		test,
		globalStore
	}
})