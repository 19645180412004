<template>
    <div class="news-add">
        <div class="topmenu">
        	<div>
            	<span v-if="info.isgongshi==1">数据 > 公示 > </span>
            	<span v-else-if="info.t==1">备忘 > </span>
                <span v-else-if="fromPage=='Xietong'">协同 > </span>
            	<span v-else-if="fromPage=='Index'">数据 > 发布 > </span>
            	<span v-if="info.id">任务单查看</span>
            	<span v-else>新增任务单</span>
            </div>

            <div class="caozuo">
                <img src="@/assets/img/dui.png" alt="" @click="confirmAddTask">
                <img src="@/assets/img/cuo.png" alt="" @click="cancelAddTask">
            </div>
        </div>
        <div class="conbox">
            <div class="n-a-1">
                <template v-if="info.t==0 && info.pt==0">
                    <template v-if="info.isgongshi==0 || (info.isgongshi==1 && field.indexOf('6')>-1)">
                        <div class="n-a-tit cursor" v-if="info.state==0 && (!info.id || (info.id && info.uid==userInfo.uid))" @click="showCheckPeople=true;checkType=1;if(info.isgongshi==1){peopleType='allPeople'}else{peopleType='taskPeople'};disabledCheckIds=info.xietong_uids">
                            负责人
                            <img src="@/assets/img/add.png"  alt="">
                        </div>
                        <div class="n-a-tit" v-else>
                            负责人
                        </div>
                        <div class="ming-box5" v-for="item in getPeoples(info.fuze_uid)">
                        	{{item.name}}
                            <img src="@/assets/img/cuo.png" v-if="info.state==0 && (!info.id || (info.id && info.uid==userInfo.uid))" alt="" @click="del(item.id,1)">
                        </div>
                    </template>
                    <template v-if="info.isgongshi==0 || (info.isgongshi==1 && field.indexOf('7')>-1)">
                        <div class="n-a-tit cursor" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))" @click="showCheckPeople=true;checkType=2;if(info.isgongshi==1){peopleType='allPeople'}else{peopleType='taskPeople'};disabledCheckIds=[info.fuze_uid]">
                            协同人
                            <img src="@/assets/img/add.png" alt="" >
                        </div>
                        <div class="n-a-tit" v-else>协同人</div>
                        <div class="ming-box5" v-for="item in getPeoples(info.xietong_uids)">
                        	{{item.name}}
                            <img @click="del(item.id,2)" src="@/assets/img/cuo.png" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                        </div>
                    </template>
                </template>
                
                <template v-if="info.isgongshi==0 || (info.isgongshi==1 && field.indexOf('8')>-1)">
                    <!-- 增加或者  状态为未完成并且是任务发布者或负责人 -->
                    <div class="n-a-tit cursor" @click="showCheckPeople=true;checkType=3" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                        责任部门
                        <img src="@/assets/img/add.png" >
                    </div>
                    <div class="n-a-tit" v-else>责任部门</div>
                    <div class="ming-box5" v-for="item in getTags(info.tagIds)">
                    	{{item.title}}
                        <img @click="del(item.id,3)" src="@/assets/img/cuo.png" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                    </div>
                </template>

                <div class="n-a-tit cursor" @click="showCheckPeople=true;if(info.isgongshi==1){checkType=5}else if(info.t==1){checkType=6}else{checkType=4}" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                    任务类型
                    <img src="@/assets/img/add.png" >
                </div>
                <div class="n-a-tit" v-else>任务类型</div>
                <template v-if="info.isgongshi==1">
                    <div class="ming-box5" v-for="item in getGsTaskKinds(info.cid)">
                        {{item.title}}
                        <img @click="del(item.id,4)" src="@/assets/img/cuo.png" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                    </div>
                </template>
                <template v-else-if="info.t==1">
                    <div class="ming-box5" v-for="item in getBwTaskKinds(info.cid)">
                        {{item.title}}
                        <img @click="del(item.id,4)" src="@/assets/img/cuo.png" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                    </div>
                </template>
                <template v-else>
                    <div class="ming-box5" v-for="item in getTaskKinds(info.cid)">
                        {{item.title}}
                        <img @click="del(item.id,4)" src="@/assets/img/cuo.png" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                    </div>
                </template>
                
                <template v-if="info.isgongshi==0 || (info.isgongshi==1 && field.indexOf('9')>-1)">
                    <div class="n-a-tit datapick cursor">
                        完成时限
                        <img src="@/assets/img/add.png" v-if="!info.id || (info.state==0 && info.uid==userInfo.uid)">
                    </div>
                    <div class="ming-box5" v-if="info.endtime">
                        {{info.endtime}}
                    </div>
                    <date-picker v-model="info.endtime" format="YYYY-MM-DD" locale="zh-cn" :locale-config="localeConfig" v-if="!info.id || (info.state==0 && info.uid==userInfo.uid)" custom-input='.datapick' />
                    

                </template>
                
                <template v-if="info.isgongshi!=1">
                    <div class="n-a-tit txtime cursor">
                        提醒时间
                        <img src="@/assets/img/add.png" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                    </div>
                    <div class="ming-box5" v-if="info.txtime">
                        {{info.txtime.substr(0,16)}}
                    </div>
                    <date-picker v-model="info.txtime" format="YYYY-MM-DD HH:mm" type="datetime" locale="zh-cn" :locale-config="localeConfig" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))" custom-input='.txtime' />
                </template>

                <div class="youxianji" v-if="info.isgongshi==0 || (info.isgongshi==1 && field.indexOf('2')>-1)">
                	<div>优先级</div>
                	<div class="jinji2">
	                    <div @click="starChange(3)">
	                        <div class="jjzy">紧急重要</div>
	                        <div :class="'point7 cursor '+(info.star==3?'active3':'')"></div>
	                    </div>
	                    <div @click="starChange(2)">
	                        <div class="jjzy">重要</div>
	                        <div :class="'point7 cursor '+(info.star==2?'active2':'')"></div>
	                    </div>
	                    <div @click="starChange(1)">
	                        <div class="jjzy">紧急</div>
	                        <div :class="'point7 cursor '+(info.star==1?'active1':'')"></div>
	                    </div>
	                    <div @click="starChange(0)">
	                        <div class="jjzy">一般</div>
	                        <div :class="'point7 cursor '+(info.star==0?'active0':'')"></div>
	                    </div>
	                </div>
                </div>
            </div>
            <div :class="'n-a-2 '+(info.isgongshi==1?'flex1':'')">
                <textarea class="n-a-2-1" v-model="info.title" :placeholder="info.t==0?'请输入任务标题':'请输入备忘标题'" :disabled="!(!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid)))" v-if="info.isgongshi==0 || (info.isgongshi==1 && field.indexOf('3')>-1)"></textarea>
                <textarea class="n-a-2-2" v-model="info.content" :placeholder="info.t==0?'请输入任务内容':'请输入备忘内容'" :disabled="!(!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid)))"></textarea>
            </div>
            <div class="n-a-3" v-if="info.isgongshi==0">
            	<div class="n-a-tit">
            	    附件
            	    <img src="@/assets/img/add.png" @click="upload" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
            	    <input type="file" @change="uploadFile" ref="file" hidden accept="image/png,image/gif,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            	</div>
            	<div class="fujianbox">
	                <template v-for="(it,index) in info.picurl">
                        <div class="tu-box3" v-if="'ppt,pptx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/ppt.png')+');background-position:center 19px;background-size:32px auto;'">
                            <div class="more">
                                <div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div>
                            </div>
                            <img src="@/assets/img/cuo.png" class="cuo2-img" @click.stop="delPicurl(index)" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                            <p>{{it.split('/').pop()}}</p>
                        </div>
                        <div class="tu-box3" v-else-if="'xls,xlsx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/excel.png')+');background-position:center 19px;background-size:32px auto;'">
                            <div class="more">
                                <div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div>
                            </div>
                            <img src="@/assets/img/cuo.png" class="cuo2-img" @click.stop="delPicurl(index)" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                            <p>{{it.split('/').pop()}}</p>
                        </div>
                        <div class="tu-box3" v-else-if="'doc,docx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/word.png')+');background-position:center 19px;background-size:32px auto;'" :title="it.split('/').pop()">
                            <div class="more">
                                <div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div>
                            </div>
                            <img src="@/assets/img/cuo.png" class="cuo2-img" @click.stop="delPicurl(index)" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                            <p>{{it.split('/').pop()}}</p>
                        </div>
                        <div class="tu-box3" v-else-if="it.split('/').pop().split('.')[1]=='pdf'" :style="'background-image:url('+require('@/assets/img/pdf.png')+');background-position:center 19px;background-size:32px auto;'">
                            <div class="more">
                                <div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div>
                            </div>
                            <img src="@/assets/img/cuo.png" class="cuo2-img" @click.stop="delPicurl(index)" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                            <p>{{it.split('/').pop()}}</p>
                        </div>
                        <div class="tu-box3" v-else :style="'background-image:url('+it+')'">
                            <div class="more">
                                <div @click="prveImg(it)"><img src="@/assets/img/see.png" alt=""> 预览</div>
                                <div @click="$axios.download(it)"><img src="@/assets/img/download.png"> 下载</div>
                            </div>
                            <img src="@/assets/img/cuo.png" class="cuo2-img" @click.stop="delPicurl(index)" alt="" v-if="!info.id || (info.state==0 && (info.uid==userInfo.uid || info.fuze_uid==userInfo.uid))">
                        </div>
	                </template>
                </div>
            </div>
            <div :class="'n-a-4 '+(info.isgongshi==1?'flex1':'')" v-if="info.id">
                <div>
                    <div class="jzqk">进展情况</div>
                    <div class="jingdu">
                        <div class="jdt-box" :style="{width:(info.jindu)+'%'}"></div>
                        <div class="jdt-num" :style="{left:(info.jindu)+'%'}">{{Math.floor((info.jindu))}}%</div>
                    </div>
                </div>
                <div class="jd-box">
                    <div class="jdbox" ref="jdbox">
                        <div class="line-box"></div>
                        <div style="position:relative;">
                            <div class="flex-3" v-for="item in taskRecord">
                                <img v-if="item.picurl" :src="item.picurl" class="tx2-img" alt="">
                                <div v-else class="tx">
                                    {{item.name.substr(0,1)}}
                                </div>

                                <div class="pad-top-12">
                                    <div class="jdHead">
                                        {{item.name}}
                                        <span class="col-7f8fa4">{{item.addtime}}</span>
                                        <div class="jinduNum" v-if="item.jindu>0">
                                            {{item.jindu}}%
                                        </div>
                                    </div>
                                    <div class="jdCon">{{item.content}}</div>
                                   <div class="recordImg" v-if="item.picurls">
                                    <template v-for="(it,ind) in item.picurls.split(',')">
                                        <div class="tu-box2" v-if="'ppt,pptx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/ppt.png')+');background-position:center 8px;background-size:32px auto;'">
                                            <div><div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div></div>
                                            <p>{{it.split('/').pop()}}</p>
                                        </div>
                                        <div class="tu-box2" v-else-if="'xls,xlsx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/excel.png')+');background-position:center 8px;background-size:32px auto;'">
                                            <div><div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div></div>
                                            <p>{{it.split('/').pop()}}</p>
                                        </div>
                                        <div class="tu-box2" v-else-if="'doc,docx'.indexOf(it.split('/').pop().split('.')[1]) > -1" :style="'background-image:url('+require('@/assets/img/word.png')+');background-position:center 8px;background-size:32px auto;'" :title="it.split('/').pop()">
                                            <div><div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div></div>
                                            <p>{{it.split('/').pop()}}</p>
                                        </div>
                                        <div class="tu-box2" v-else-if="it.split('/').pop().split('.')[1]=='pdf'" :style="'background-image:url('+require('@/assets/img/pdf.png')+');background-position:center 8px;background-size:32px auto;'">
                                            <div><div @click="$axios.download(it)"><img src="@/assets/img/download.png" alt=""> 下载</div></div>
                                            <p>{{it.split('/').pop()}}</p>
                                        </div>
                                        <div class="tu-box2" v-else :style="'background-image:url('+it+')'">
                                            <div>
                                                <div @click="prveImg(it)"><img src="@/assets/img/see.png" alt=""> 预览</div>
                                                <div @click="$axios.download(it)"><img src="@/assets/img/download.png" @click="delPicurl(index)" alt=""> 下载</div>
                                            </div>
                                        </div>
                                    </template>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="info.isgongshi==1">
                    <div class="rangebox">
                        <span :style="{left:'calc(450px * '+info.jindu/100+' - 30px)'}">{{info.jindu}}%</span>
                        <input type="range" min="0" step="10" max="100" v-model="info.jindu">
                    </div>
                    <div class="last-box">
                        <textarea v-model="content" placeholder="这里写内容"></textarea>
                        <div class="flex-1">
                            <div class="logo3"><img src="@/assets/img/Logo3.png" @click="submitRecord" alt=""></div>
                            <div class="rwwc" @click="info.jindu=100;submitRecord()">任务<br>完成</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SelectPeople v-if="showCheckPeople" :checkType="checkType" @cancel="showCheckPeople=false" @confirm="checkRes" :alreadyCheckIds="alreadyCheckIds" :peopleType="peopleType" :dIds="disabledCheckIds"></SelectPeople>
    </div>
</template>
<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import TaskTable from '@/components/TaskTable'
import SelectPeople from '@/components/SelectPeople'
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'AddTask',
    data: function() {
        return {
            localeConfig: {
                'zh-cn': {
                    lang: {
                        label: 'ZH-CN',
                        submit: '确认',
                        cancel: '取消',
                        now: '现在',
                        nextMonth: '下个月',
                        prevMonth: '上个月'
                    }
                },
            },
            showCheckPeople: false,
            peopleType: 'taskPeople',
            checkType: 1,
            sytime: '',
            taskRecord: [],
            fromPage: '',
            info: {
                cid: 0,
                title: '',
                content: '',
                fuze_uid: '',
                xietong_uids: [],
                tagIds: [],
                star: 0,
                picurl: [],
                endtime: '',
                txtime:'',
                isgongshi: 0,
                state:0,
                taskPeople:[],

                t: 0,       //0 协同  1 个人代办   2个人笔记 
                pt: 0,      //0 人对人   1全员群 
                groupid:0   //0 全体成员群   >0 对应群组
            },
            content:'',
            field:"",
            disabledCheckIds:[]
        }
    },
    methods: {
        starChange(t){
            if(!this.info.id || (this.info.state==0 && (this.info.uid==this.userInfo.uid || this.info.fuze_uid==this.userInfo.uid))){
                this.info.star = t;
            }
            
        },
        checkRes(ids) {
            if(this.checkType == 1 || this.checkType == 2){
                this.info.taskPeople = this.info.taskPeople.filter((item,index)=>item.roleType!=this.checkType)
                var plist = JSON.parse(JSON.stringify(this.getPeoples(ids)))
                plist.forEach((item,i)=>{
                    plist[i]['roleType'] = this.checkType
                    plist[i]['fengong'] =''
                })
                this.info.taskPeople.push(...plist)
            }
            if (this.checkType == 1) {
                this.info.fuze_uid = ids[0]
            } else if (this.checkType == 2) {
                this.info.xietong_uids = ids;
            } else if (this.checkType == 3){
                this.info.tagIds = ids;
            } else if (this.checkType == 4 || this.checkType == 5 || this.checkType == 6){
                this.info.cid = ids[0];
            }
        },
        del(id, t) {
            if (t == 1) {
                this.info.fuze_uid = 0;
                this.info.taskPeople = this.info.taskPeople.filter((item,index)=>item.id!=id)
            } else if (t == 2) {
                var ind = this.info.xietong_uids.indexOf(id)
                this.info.xietong_uids.splice(ind, 1)
                this.info.taskPeople = this.info.taskPeople.filter((item,index)=>item.id!=id)
            } else if (t == 3) {
                var ind = this.info.tagIds.indexOf(id)
                this.info.tagIds.splice(ind, 1)
            } else if (t == 4) {
            	this.info.cid = 0;
            }
        },
        upload() {
            this.$refs.file.click()
        },
        uploadFile(e) {
            this.$axios.upload(e.target.files[0])
                .then((r) => {
                    if (r.ok) {
                        this.info.picurl.push(r.msg)
                    }
                })
        },
        delPicurl(index) {
            this.info.picurl.splice(index, 1)
        },
        confirmAddTask() {

            if(this.info.isgongshi==0 || (this.info.isgongshi==1 && this.field.indexOf('6')>-1)){
                if (!this.info.fuze_uid && this.info.t == 0 && this.info.pt==0) {
                    this.$message('warning','请选择负责人')
                    return;
                }
            }
            if(this.info.isgongshi==0 || (this.info.isgongshi==1 && this.field.indexOf('3')>-1)){
                if (!this.info.title) {
                    this.$message('warning','请输入任务标题')
                    return;
                }
            }
            
            var dd = JSON.parse(JSON.stringify(this.info))      
            if(!dd.taskPeople ){
                dd.taskPeople = []
            }
            dd.name = this.userInfo.uname
   
            this.global_submit('index.php?api-task-addTask', dd, (r) => {
                //协同不是公示新增 消息广播
                if(dd.isgongshi==0){
                    if(!dd.id){
                        this.ws.send(JSON.stringify({type:'addTask',t:dd.t,id:r.resId}));
                    }else{
                        this.ws.send(JSON.stringify({type:'editTask',t:dd.t,id:r.resId}));
                    }
                }

                this.$router.replace({
                    name: this.fromPage,
                    params: {
                        referesh: true
                    }
                })
            })


        },
        cancelAddTask() {
            this.$router.go(-1)
        },
        djs() {
            var date = new Date();
            var nowtime = parseInt(date.getTime() / 1000);
            var endTime = new Date(this.info.endtime + ' 23:59:59').getTime() / 1000;
            var sytime = Math.abs(endTime - nowtime);
            var d = parseInt(sytime / (24 * 60 * 60));
            var h = parseInt((sytime / 60 / 60) % 24);
            var i = parseInt((sytime / 60) % 60)
            var s = parseInt(sytime % 60)
            var t = (d > 0 ? (d + '天') : '') + (h > 0 ? (h + '时') : '') + (i > 0 ? (i + '分') : '') + s + '秒';
            if (endTime - nowtime > 0) {
                this.sytime = t;
            } else {
                this.sytime = '-' + t
            }
        },
        getTaskRecord() {
            this.$axios.post('index.php?api-record-getTaskRecord', {
                    taskid: this.info.id
                })
                .then((r) => {
                    this.taskRecord = r;
                    this.$nextTick(()=>{
                        this.$refs.jdbox.scrollTop = this.$refs.jdbox.scrollHeight;
                    })

                })
        },
        submitRecord() {
            if (this.content == '') {
                this.$message('warning','请添加文字')
                return;
            }
            var dd = {
                taskid:this.info.id,
                content:this.content,
                picurl:'',
                addtime:this.getTime(),
                jindu: this.info.jindu
            }
            this.global_submit('index.php?api-record-addTaskRecord', dd, (r) => {
                var updateJinduData = {
                    id:r.insertId,
                    uid:this.userInfo.uid,
                    taskid:dd.taskid,
                    addtime:dd.addtime,
                    content:dd.content,
                    name:this.userInfo.uname,
                    picurl:this.userInfo.picurl,
                    picurls:dd.picurl,
                    jindu:dd.jindu
                }
                this.taskRecord.push(updateJinduData)
                this.$nextTick(()=>{
                    this.content = ''
                    this.$refs.jdbox.scrollTop = this.$refs.jdbox.scrollHeight;
                })
            })

        }
    },
    computed: {
        ...mapState('globalStore', ['taskKind','taskGsKind','taskBwKind', 'userInfo','ws']),
        ...mapGetters('globalStore', ['getNames', 'getPeoples', 'getInfoById', 'getTags','getTaskKinds','getGsTaskKinds','getBwTaskKinds']),
        alreadyCheckIds(){
        	if(this.checkType == 1){
        		return [this.info.fuze_uid]
        	}else if(this.checkType == 2){
        		return this.info.xietong_uids
        	}else if(this.checkType == 3){
        		return this.info.tagIds
        	}else if(this.checkType == 4 || this.checkType == 5 || this.checkType == 6){
        		return [this.info.cid]
        	}
        },
        download(){
            return this.$axios.baseURL+'index.php?admin-public-download&fileurl='
        	// return 'http://'+window.location.hostname+this.$axios.baseURL+'index.php?admin-public-download&fileurl='
        }
    },
    watch:{
        'info.cid'(newV,oldV){
            if('isgongshi' in this.$route.query){
                this.field = this.getGsTaskKinds(newV)[0].field;
            }
        }
    },
    components: {
        TaskTable,
        SelectPeople,
        datePicker: VuePersianDatetimePicker
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fromPage = from.name
            // console.log(from.name)
        })
    },
    mounted() {
        if(!this.ws || this.ws.readyState!=1){
            
        }
        var query = this.$route.query;
        if ('t' in query) {
            this.info.t = query.t;
        }
        if ('isgongshi' in query) {
            this.info.isgongshi = query.isgongshi
        }
        if ('pt' in query) {
            this.info.pt = query.pt
        }
        if ('fuze_uid' in query && query.fuze_uid>0) {
            this.info.fuze_uid = query.fuze_uid
            var uinfo = JSON.parse(JSON.stringify(this.getInfoById(query.fuze_uid)))
            uinfo.roleType = 1;
            this.info.taskPeople = [uinfo]
        }

        if('groupid' in query && query.groupid>0){
            this.info.groupid = query.groupid
        }
        var info = this.$route.params;
        if ('id' in info) {
            if(info.xietong_uids!=''){
                info.xietong_uids = info.xietong_uids.split(',')
            }else{
                info.xietong_uids = []
            }
            
            if(info.tagIds!=''){
                info.tagIds = info.tagIds.split(',')
            }else{
                info.tagIds = []
            }

            info.picurl = info.picurl == '' ? [] : info.picurl.split(',')
            this.info = info;
            this.getTaskRecord()
            if (info.endtime) {
                this.timer = setInterval(() => {
                    this.djs()
                }, 1000)
            }

        }

        if ('cateId' in query) {
            if(query['cateId']>0){
                this.info.cid = query.cateId
            }else{
                if ('isgongshi' in query) {
                    if(this.taskGsKind.length>0){
                        this.info.cid = this.taskGsKind[0]['id']
                    }else{
                        this.info.cid = 0
                    }
                }else if('t' in query && query.t==1){
                    if(this.taskBwKind.length>0){
                        this.info.cid = this.taskBwKind[0]['id']
                    }else{
                        this.info.cid = 0
                    }
                }else{
                    this.info.cid = this.taskKind[0]['id']
                }
            }
            
        }
    },
    beforeDestroy() {
        var that = this;
        clearInterval(that.timer)
    }
}
</script>
<style scoped>
.news-add {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: #D7DFEA;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.topmenu {
	display: flex;
	justify-content: space-between;
	font-size: 28px;
	margin-bottom: 10px;
	flex-shrink: 0;
}


.caozuo img{
	width: 45px;
	margin-left: 25px;
}
.conbox {
    display: flex;
    flex-grow: 1;
}
.n-a-1 {
    width: 210px;
    padding: 10px;
    font-size: 20px;
    color: #2f3034;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 8px;
    flex-shrink: 0;
}

.n-a-2 {
    flex-grow: 1;
    color: #202022;
    display: flex;
    flex-direction: column;
    margin: 0 15px;
}

.n-a-3 {
    flex-shrink: 0;
    width: 240px;
    /*padding: 10px;*/
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
}
.n-a-3 .n-a-tit{
	margin: 10px;
    margin-top: 0;
    font-size: 20px;
	background-color: transparent;
	margin-bottom: 0;
}

.n-a-tit {
    background-color: #E5E5EA;
    height: 46px;
    padding: 0 15px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #005baa;
    margin-bottom: 10px;
}
.n-a-tit img{
	width: 20px;
	cursor: pointer;
}
.ming-box5 {
    display: flex;
    justify-content: space-between;
    color: #221815;
    padding: 5px 13px 15px 20px;
    align-items: center;
}
.ming-box5 img{
	width: 24px;
    height: 24px;
}
.youxianji{
	background-color: #E5E5EA;
	/*padding: 0 15px 0 20px;*/
	color: #005baa;
	/*padding: 15px 0 15px 0;*/
	padding: 15px;
}

.jinji2 {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.jinji2>div {
    /*width: 60px;*/
    text-align: center;
}

.jinji2 .active {
    background-color: #048703;
}

.n-a-2-1 {
    border: none;
    background-color: transparent;
    height: 300px;
    background-color: #fff;
    font-size: 35px;
    padding: 20px 25px;
    outline: none;
    resize: none;
    border-radius: 8px;
}

.n-a-2-2 {
    border: none;
    background-color: transparent;
    flex-grow: 1;
    background-color: #fff;
    padding: 25px;
    margin-top: 15px;
    outline: none;
    resize: none;
    font-size: 25px;
    border-radius: 8px;
}
.fujianbox{
	flex-grow: 1;
	overflow-x:hidden;
	overflow-y:auto;
    padding: 10px;
}


.tu-box3{
    position: relative;
    flex-shrink: 0;
    height: 105px;
    width: 105px;
    margin-bottom: 10px;
    margin-right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #fff;     
    transition: all ease .5s;
    float: left;
}
.tu-box3:nth-child(2n){
    margin-right: 0;
}
.tu-box3 p{
    line-height: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #000;
}
.tu-box3 div.more{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: none;
}
.tu-box3 div.more>div{
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    line-height: 20px;
}
.tu-box3 div.more>div img{
    width: 18px;
    margin-right: 5px;
}
.tu-box3:hover>div{
    display: flex;
    background-color: rgba(0,0,0,0.4);
}
.tu-box3 .cuo2-img{
    width: 18px;
    height: 18px;
    position: absolute;
    right: -7px;
    top: -5px;
    cursor: pointer;
}
.tu-box2{
    position: relative;
    flex-shrink: 0;
    height: 80px;
    width: 80px;
    margin-right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #fff;     
    transition: all ease .5s;
}
.tu-box2 p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #000;
}
.tu-box2 p{
    line-height: 118px;
}
.tu-box2>div{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: none;
}
.tu-box2>div>div{
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}
.tu-box2>div>div img{
    width: 18px;
    height: 18px;
    margin-right: 5px;
}
.tu-box2:hover>div{
    display: flex;
    background-color: rgba(0,0,0,0.4);
}

/*-------------------------------------单详情页---------------------------------------------*/
.n-a-4 {
    width: 450px;
    margin-left: 15px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}


.add-page-5 {
    width: 160px;
    background-color: #f4f6f9;
    padding: 20px;
    height: 1005px;
    overflow-y: auto;
    margin-right: 15px;
}

.new-fb-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 130px;
}

.fabu-box7 {
    display: flex;
}

.jzqk {
   	padding: 15px 0px 10px 15px;
   	color: #005baa;
    font-size: 20px;
}

.date-1,
.time-1 {
    color: #221815;
    font-size: 18px;
    line-height: 30px;
    text-align: right;
}

.dt-box {
    /*width: 115px;*/
    margin-right: 13px;
}

.fb-box,
.dj-box {
    width: 58px;
    height: 26px;
    font-size: 18px;
    color: #ffffff;
    line-height: 26px;
    text-align: center;
    margin-bottom: 3px
}

.fb-box {
    background-color: #009944
}

.time-1 {
    color: #055ba7;
}

.dj-box {
    background-color: #055ba7
}

.time-1.active {
    color: #f7941d;
}

.dj-box.active {
    background-color: #f7941d
}

.fbdj-box {
    margin-right: 18px
}

.dx-box img {
    width: 50px;
    height: 50px;
    margin-left: 25px;
}


.jingdu {
    display: flex;
    align-items: center;
    background-color: #e9e8ed;
    margin-top: 10px;
    position: relative;
    width: 90%;
}

.jdt-box {
    height: 7px;
    background-color: #009944;
}

.jdt-num {
    font-size: 18px;
    color: #005baa;
    position: absolute;
    z-index: 999;
    white-space: nowrap;
}


.jd-box {
    flex-grow: 1;
    position: relative;
    padding-left: 8px;
    /*background-color: #f4f6f9;*/
    border-radius: 8px;
    margin-top: 10px;
}

.jdbox {
    position: absolute;
    left: 10px;
    top: 14px;
    bottom: 14px;
    right: 10px;
    overflow: auto;
    /*background-color: red;*/
}

.line-box {
    position: absolute;
    top: 0;
    left: 21px;
    bottom: 0;
    width: 2px;
    background-color: #005baa;
}

.tx2-img {
    width: 44px;
    height: 44px;
    border-radius: 10px;
    flex-shrink: 0;
}
.tx{
    color: #fff;
    background-color: #005baa;
    width: 44px;
    height: 44px;
    border-radius: 44px;
    text-align: center;
    line-height: 44px;
}

.flex-3 {
    display: flex;
    margin-bottom: 10px;
}

.pad-top-12 {
    padding-top: 5px;
    padding-left: 12px;
    
    
    line-height: 30px;
    letter-spacing: 1px;
    flex-grow: 1;
}
.jdHead {
    display: flex;
    font-size: 20px;
    color: #000;
}
.jdCon{
    font-size: 22px;
    color: #666;
    margin-top: 10px;
}

.recordImg {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
}

.recordImg img {
    height: 80px;
    width: auto;
    margin-right: 15px;
}

.jinduNum {
    margin-left: auto;
    margin-right: 20px;
    color: #005baa;
    font-size: 18px;
}

.line2 {
    width: 98%;
    height: 4px;
    background-color: #d2dfec;
    margin: 0 auto;
}

.last-box {
    padding: 0 20px;
    height: 129px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.last-box textarea {
    height: 90%;
    font-size: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    flex-grow: 1;
    margin-right: 20px;
    color: #3f4145;
    resize: none;
}

.logo3,
.rwwc {
    height: 65px;
    width: 65px;
    border-radius: 100%;
    background-color: #005baa;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.rwwc {
    background-color: #009944;
    color: #ffffff;
    line-height: 22px;
    margin-left: 8px;
}

.rangebox input {
    width: 100%;
}

.n-a-2.flex1,.n-a-4.flex1{
    flex: 1;
}
</style>