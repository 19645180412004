<template>
	<div class="conbox">
		<div class="rwlb-tit">
			<div class="font-25 col-051349">责任部门(标签)列表</div>
			<div class="zjl-box">
				<select v-model="cid" class="cate">
					<option :value="item.id" v-for="item in tagKind">{{item.title}}</option>
				</select>
				<input type="text" class="zjl-1" placeholder="请输入责任部门名称" v-model="title">
				<div class="zjl-2" @click="add" v-if="activeIndex===false">添加</div>
				<div class="zjl-2" @click="add" v-else>修改</div>
				<div class="zjl-3" @click="del" v-if="activeIndex!==false">删除</div>
			</div>
		</div>
		<div class="cont-box2" :style="{zoom:zoom}">
			<div class="cont-cate">
				<ul>
					<li :class="cateIndex===''?'active':''" @click="cateChange('')">全部</li>
					<li v-for="item in tagKind" :key="item.id" :class="cateIndex===item.id?'active':''" @click="cateChange(item.id)">{{item.title}}</li>
				</ul>
			</div>
			<vuedraggable class="cont-names" v-model="data" :options="{sort:cateIndex===''?true:false,handle:'.title'}" animation="300" @sort="dataUpdate">
				<div v-for="(item,index) in data"  @click="select(index)" :class="activeIndex===index?'active':''">
					<div class="title">{{item.title}}</div>
					<img src="@/assets/img/pencil.png" alt="">
				</div>
			</vuedraggable>
		</div>
	</div>
</template>
<script>
	import { mapState,mapActions,mapMutations } from 'vuex';
	import vuedraggable from 'vuedraggable';
	export default {
  		name: 'ZherenBumen',
  		props: {},
  		data: function(){
  			return {
  				cateIndex:'',
  				cid:'',
  				title:'',
  				data:[],
  				allData:[],
  				activeIndex:false,
  				zoom:1
  			}
  		},
  		methods:{
  	
  			cateChange(v){
  				this.cateIndex = v;
  				if(v===''){
  					this.data = this.allData
  				}else{
  					var d = [];
  					this.allData.forEach(function(item,index){
  						if(item.cid==v){
  							d.push(item)
  						}
  					})
  					this.data = d;
  				}
  			},
  			add(){
  				var data = {
  					title:this.title,
  					cid:this.cid
  				}
  				if(this.activeIndex !== false){
  					data.id = this.data[this.activeIndex]['id']
  				}
  				this.global_submit('index.php?api-other-AddEditTag',data,(r)=>{
  					if(this.activeIndex === false){
  						this.allData.push({id:r.insertId,title:this.title,cid:this.cid})
  					}else{
  						var editIndex = 0;
  						this.allData.forEach((item,index)=>{
  							if(item.id==data.id){
  								editIndex=index;
  							}
  						})
  						this.$set(this.allData,editIndex,data)
  						this.activeIndex = false;
  					}
  					this.title=''
  					this.cateChange(this.cateIndex)
  					this.$store.commit('globalStore/changeTag',this.allData)
  				})
  			},
  			select(ind){
  				if(this.activeIndex === ind){
  					this.activeIndex = false;
  					this.title = ''
  				}else{
  					this.title = this.data[ind]['title']
  					this.cid = this.data[ind]['cid']
  					this.activeIndex = ind;
  				}
  			},
  			del(){
  				var id = this.data[this.activeIndex]['id'];
  				this.global_delById('tag',id,()=>{
  					var delIndex = 0;
  					this.allData.forEach((item,index)=>{
  						if(item.id==id){
  							delIndex=index;
  						}
  					}) 
  					this.allData.splice(delIndex,1)
  					this.title = ''
  					this.activeIndex = false;

  					this.cateChange(this.cateIndex)
  					this.$store.commit('globalStore/changeTag',this.allData)
  				})
  			},
  			dataUpdate(e){
  				this.allData = this.data;
  				this.global_sort({data:this.data,tableName:'tag'})
  				this.$store.commit('globalStore/changeTag',this.allData)
  			},
  			...mapActions('globalStore',['getAllTag']),
  			...mapMutations('globalStore',['changeTag'])
  		},
  		components:{
  			vuedraggable
  		},
  		computed:{
  			...mapState('globalStore',['tagKind'])
  		},
  		mounted(){
  			if(this.tagKind.length>0){
  				this.cid = this.tagKind[0]['id']
  			}
  			
  			this.$store.dispatch('globalStore/getAllTag')
  			.then((r)=>{
  				this.data = r;
  				this.allData = r;
  			})

  			var appzoom = document.querySelector('#app').style.zoom;
  			if(appzoom && appzoom<0.9){
  				this.zoom = 2-appzoom;
  			}
  		}
  }
</script>
<style scoped src="./index.css">
	

</style>