<template>
	<div class="backg-col">
		<div class="xuan-1">
			<div class="dx-box">
				<img src="@/assets/img/dui.png" @click="confirm" alt="">
				<img src="@/assets/img/cuo.png" @click="cancel" alt="">
			</div>
			<div class="content">
				<div class="flex-2">
					<div class="left-box">选择优先级</div>
				</div>
				<div class="names-list">
					<div :class="star===3?'active':''" @click="check(3,'star')">紧急重要</div>
					<div :class="star===2?'active':''" @click="check(2,'star')">重要</div>
					<div :class="star===1?'active':''" @click="check(1,'star')">紧急</div>
					<div :class="star===0?'active':''" @click="check(0,'star')">一般</div>
				</div>
			<template v-if="pageType!='beiwang' && pageType!='groupXietong'">
				<div class="flex-2">
					<div class="left-box">发布人</div>
				</div>
				<div class="names-list">
					<div :class="uid==item.id?'active':''" v-for="item in peopleList" @click="check(item.id,'uid')">{{item.name}}</div>
				</div>

				<div class="flex-2">
					<div class="left-box">选择负责人</div>
				</div>
				<div class="names-list"  >
					<div :class="fuze_uid==item.id?'active':''" v-for="item in peopleList" @click="check(item.id,'fuze_uid')">{{item.name}}</div>
				</div>
			
				<div class="flex-2">
					<div class="left-box">选择协同人</div>
				</div>
				<div class="names-list"  >
					<div :class="xietong_uids.indexOf(item.id)>-1?'active':''" v-for="item in peopleList" @click="check(item.id,'xietong_uids',true)">{{item.name}}</div>
				</div>
			</template>	
		
				<div class="flex-2">
					<div class="left-box">选择责任部门</div>
					<div class="mid-box">可单选，点击对号确定筛选</div>
				</div>
				<div class="inp-box2">
					<input type="text" v-model="searchTitle" placeholder="请输入责任部门">
					<span v-show="searchTitle!=''" class="clearTitle" @click="searchTitle=''">清空</span>
				</div>
				<div class="catebox">
					<ul>
						<li :class="tagKindId==0?'active':''" @click="tagKindId=0">全部</li>
						<li :class="tagKindId==item.id?'active':''" @click="tagKindId=item.id" v-for="item in tagKind">{{item.title}}</li>
					</ul>
				</div>
				<div class="names-list fixedHeight">
					<div :class="tagIds.indexOf(item.id)>-1?'active':''" v-for="item in tag" @click="check(item.id,'tagIds','more')">{{item.title}}</div>
				</div>
				<!-- <div class="flex-2">
					<div class="left-box">选择任务类型</div>
				</div>
				<div class="names-list">
					<template v-if="pageType=='gongshi'">
						<div v-for="item in taskGsKind" :class="cid==item.id?'active':''" @click="check(item.id,'cid')">{{item.title}}</div>
					</template>
					<template v-else-if="pageType=='beiwang'">
						<div v-for="item in taskBwKind" :class="cid==item.id?'active':''" @click="check(item.id,'cid')">{{item.title}}</div>
					</template>
					<template v-else>
						<div v-for="item in taskKind" :class="cid==item.id?'active':''" @click="check(item.id,'cid')">{{item.title}}</div>
					</template>
				</div> -->
				<div class="flex-2">
					<div class="left-box">完成时限</div>
				</div>
				<div class="con-block">
					<div>
						<input type="text" class="datapick" disabled :value="endtime_begin" placeholder="起始时间">
						<date-picker format="YYYY-MM-DD" v-model="endtime_begin" locale="zh-cn" :locale-config="localeConfig" custom-input='.datapick' />
					</div>
					<div>
						<input type="text" class="datapick2" disabled :value="endtime_end" placeholder="结束时间">
						<date-picker format="YYYY-MM-DD" v-model="endtime_end" locale="zh-cn" :locale-config="localeConfig" custom-input='.datapick2' />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
	import { mapState,mapGetters } from 'vuex'
	export default {
  		name: 'Saixuan',
  		props: ['pageType'],
  		data: function(){
  			return {
  				localeConfig:{
  					'zh-cn': {
						lang: {
							label: 'ZH-CN',
							submit: '确认',
							cancel: '取消',
							now: '现在',
							nextMonth: '下个月',
							prevMonth: '上个月'
						}
				    }
  				},
  				star:'',
  				uid:0,		//发布人
  				fuze_uid:0,	//负责人
  				xietong_uids:[], //协同人

  				searchTitle:'',
  				tagKindId:0,
  				tagIds:[],
  				tag:[],

  				cid:0,
  				endtime:'',
  				endtime_begin:'',
  				endtime_end:'',
  			}
  		},
  		methods:{
  			check(id,field,ismore){
  				// console.log(id,field)
  				// this.$set(field,id)
  				if(ismore){
  					var ind = this[field].indexOf(id);
  					if(ind>-1){
  						this[field].splice(ind,1)
  					}else{
  						this[field].push(id)
  					}
  				}else{
  					if(this[field] === id){
  						this[field] = ''
  					}else{
  						this[field] = id;
  					}
  				}  				
  			},
  			confirm(){
  				var field = {
  					star:this.star,
  					fabu_uid:this.uid,
  					fuze_uid:this.fuze_uid,
  					xietong_uids:this.xietong_uids,
  					tagIds:this.tagIds,
  					endtime_begin:this.endtime_begin,
  					endtime_end:this.endtime_end,
  				}
  				//cid:this.cid,
  				this.$emit('saixuan',field)
  				this.$emit('cancel');
  			},
  			cancel(){
  				this.$emit('cancel');
  			}
  		},
  		watch:{
  			searchTitle(newv,oldv){
  				// console.log(newv)
  				var newData = [];
  				for(var i in this.tagList){
  					if(this.tagList[i].title.indexOf(newv) > -1){
  						newData.push(this.tagList[i])
  					}
  				}
  				this.tag = newData
  			},
  			tagKindId(newv,oldv){
  				if(!newv){
  					this.tag = this.tagList
  				}else{
  					var d = [];
  					this.tagList.forEach(function(item,index){
  						if(item.cid==newv){
  							d.push(item)
  						}
  					})
  					this.tag = d;
  				}
  			}
  		},
  		components:{
  			datePicker: VuePersianDatetimePicker
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo','peopleList','taskKind','taskGsKind','taskBwKind','tagList','tagKind']),
  		},
  		mounted(){
  			this.tag = this.tagList;
  			// this.$axios.get('index.php?api-other-getTag')
  			// .then((r)=>{
  			// 	this.tag = r;
  			// 	this.tagList = r;
  			// })

  		
  		}
  }
</script>
<style scoped>
	.backg-col{
		position: fixed;
		z-index: 999;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(49,58,76,0.5);
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.xuan-1{
		width: 730px;
		height: 80%;
		background-color: #eaeef4;
		color: #325b91;
		padding:20px;
		border-radius: 30px;
		position: relative;
	}
	.content{
		height: 100%;
		overflow-x:hidden;
		overflow-y: auto;
	}
	.search-img2{
		width: 37px;
		height: 37px;
		margin-right: 25px
	}
	.mid-box{
		margin-right: auto;
		color: #4b4b4b;
		margin-left: 10px;
	}
	.xuan-1 .flex-2{
		align-items: baseline;
		margin: 15px 0;
	}
	.left-box{
		color: #005baa;
		font-size: 30px;
	}
	.dx-box{
		position: absolute;
		right: 30px;
		top: 30px;
	}
	.dx-box img{
		margin-left: 10px;
	}
	.inp-box2{
		height: 60px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		padding: 0 20px;
	}
	.inp-box2 input{
		font-size: 30px;
		color: #000;
		border: none;
		outline: none;
		flex-grow: 1;

	}
	.inp-box2 span{
		color: #005baa;
		font-size: 20px;
		margin-right: 14px;
		cursor: pointer;
		margin-left: 20px;
	}
	.inp-box2 input::-webkit-input-placeholder {
	  color: #e0e4ec;
	}

	.inp-box2 input:-moz-placeholder {
	  color: #e0e4ec;
	}

	.inp-box2 input::-moz-placeholder {
	  color: #e0e4ec;
	}

	.inp-box2 input::-ms-input-placeholder {
	  color: #e0e4ec;
	}

	.names-list{
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		margin-top: 10px;	
		overflow-x:hidden;
		overflow-y: auto;
		max-height: 395px;
	}

	/**/
	.names-list>div{
		padding: 0 20px;
		height: 50px;
		color: #d3d8e3;
		text-align: center;
		line-height: 50px;
		background-color: #d3d8e3;
		color: #325b91;
		margin-bottom: 10px;
		margin-right: 10px;
		cursor: pointer;
	}
	.names-list>div.active{
		background-color: #005baa;
		color: #ffffff;
	}
	.con-block{
		display: flex;
		justify-content: center;
	}
	.con-block div{
		margin: 0 20px;
	}
	.con-block input{
		height: 50px;
		color: #325b91;
		font-size: 20px;
		text-align: center;
		line-height: 50px;
		background-color: #d3d8e3;
		border: none;
		outline: none;
		width: 100%;
	}
	.fixedHeight{
		max-height: 170px;
		overflow-y: auto;
	}

	.catebox{
		font-size: 18px;
		overflow-x:auto;
		overflow-y:hidden;
	}
	.catebox ul{
		display: flex;
		flex-wrap: nowrap;
	}
	.catebox ul li{
		color: #005baa;
		border-bottom: 2px solid transparent;
		padding: 10px 20px;
		cursor: pointer;
		user-select:none;
		white-space: nowrap;
	}
	.catebox ul li.active{
		border-bottom-color: #005baa;
	}
</style>