<template>
	<div class="AddBumen">
		<div class="tit">人员审核</div>
		<div class="con">
			<!-- 当数据为空需要占位时，会显示 cellEmptyContent -->
			<t-table
			 rowKey="index"
			 :data="data"
			 :columns="columns"
			 :stripe="true"
			 :bordered="true"
			 :hover="true"
			 size="small"
			 :pagination="pagination"
			 cellEmptyContent="-"
			 resizable
			 ref="table"
			 @page-change="pageChange"
			>
				<template #operation="slotProps">
		        	<t-button size="small" theme="success" style="margin-right:5px;" @click="pass(slotProps)">通过</t-button>
		        	<t-button size="small" theme="danger" @click="unpass(slotProps)">不通过</t-button>
		      	</template>
		      	<template #headerimg="slotProps">
		      		<template v-if="slotProps.row.picurl==''">
		      			-
		      		</template>
		      		<img v-else :src="slotProps.row.picurl" width="40" height="40" alt="" @click="prveImg(slotProps.row.picurl)">
		      	</template>
			</t-table>
			
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	import {Input,MessagePlugin} from 'tdesign-vue'
	export default {
  		name: 'ReviewPeople',
  		props: {},
  		data: function(){
  			return {
  				data:[],
  				columns: [
  					{align:'center',title:'头像',colKey:'picurl',cell:'headerimg'},
  					{align:'center',title:'姓名',colKey:'name'
  						// ,edit: {
				    //         component: Input,
				    //         abortEditOnEvent: ['onEnter'],
				    //         onEdited: (context) => {
				    //           this.data.splice(context.rowIndex, 1, context.newRowData);
				    //           MessagePlugin.success('Success');
				    //         },
				    //         rules: [
				    //           	{ required: true, message: '不能为空' },
				    //           	{ max: 10, message: '字符数量不能超过 10', type: 'warning' },
				    //         ]
			     //      	},
			      	},
  					{align:'center',title:'账号',colKey:'username'},
  					{align:'center',title:'联系方式',colKey:'tel'},
  					{align:'center',title:'部门',colKey:'bumen'},
  					{align:'center',title:'职务',colKey:'zhiwu'},
  					{align:'center',title:'操作',colKey:'operation',cell:'operation'},
  				],
  				pagination: {
			        defaultCurrent: 1,
			        defaultPageSize: 12,
			        total:0,
			        onChange(a){
			        	// console.log(a)
			        },
			        onCurrentChange(b){
			        	// console.log(b)
			        },
			        size:'small',
			        showPageSize:false
			    },
			    currentPage:0
  			}
  		},
  		methods:{
  			pass(r){
  				this.global_changeField({table:'user',id:r.row.id,value:{state:1}},()=>{
  					this.getData(this.currentPage,this.pagination.defaultPageSize)
  					this.$parent.getBumenList()
  				},'确认审核通过吗？')
  			},
  			unpass(r){
  				this.inputDialog('请输入不通过原因')
  				.then((res)=>{
  					this.global_changeFieldNoConfirm({table:'user',id:r.row.id,value:{state:2,nopass:res}},()=>{
  						this.getData(this.currentPage,this.pagination.defaultPageSize)
  					})
  				})
  			},
  			getData(page,pagesize){
  				this.currentPage = page;
  				this.$axios.post('index.php?api-people-getReviewPeople',{bumenId:this.userInfo.bumenId,page,pagesize})
  				.then((r)=>{
  					this.data = r.data
  					this.pagination.total = parseInt(r.total)
  					this.$parent.reviewPeopleNum = parseInt(r.total)
  				})
  			},
  			pageChange(pageInfo,newDataSource){

  				this.getData(pageInfo.current,pageInfo.pageSize)
  			}
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo'])
  		},
  		mounted(){
  			this.getData(this.pagination.defaultCurrent,this.pagination.defaultPageSize)
  		}
  }
</script>
<style scoped>
	.AddBumen{
		padding-left: 27px;
		padding: 0 20px 0 28px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.tit{
		font-size: 28px;
		color: #313a4c;
		padding: 22px 16px 22px;
		flex-shrink: 0;
	}
	.con{
		flex-grow: 1;
		height: 0;
		overflow: auto;
		padding-bottom: 50px;
	}
</style>