import store from '@/store/index.js'
export default {
	install(Vue){
		//全局资源：指令/过滤器/过渡等
		Vue.filter('dateFormate',function(value){
			if(value){
				return value.slice(2,10)
			}else{
				return '';
			}
			
		})
		Vue.directive('row', {
			bind:function(el){
				// console.log('bind')
			},
		  	// 当被绑定的元素插入到 DOM 中时……
		  	inserted: function (el,binding,vnode) {
		  		// console.log(binding)
		    	// 聚焦元素
		    	// console.log(el.offsetWidth,el.innerText)
		    	// console.log('inserted')
		    	var all = Math.floor(el.offsetWidth/22) * binding.value;
		    	if(el.innerText.length >= all){
		    		el.innerText = el.innerText.substr(0,all-1)+'...'
		    	}
		  	},
		  	update:function(el,binding,vnode){
		  		// console.log('update')
		  		//计算三行容纳多少字
		  		var all = Math.floor(el.offsetWidth/22) * binding.value;
		  		if(el.innerText.length >= all){
		  			el.innerText = el.innerText.substr(0,all-1)+'...'
		  		}
		  	}
		})
		//全局混入
		Vue.mixin({
			methods:{
	  			prveImg(src){
					this.$dialog({
						header:false,
						body:()=>{
							return this.$createElement('img',{
								attrs:{
									src,
									style:"max-width:800px;display:block;"
								}
							}) 
						},
						width:'auto',

						footer:false,
						closeOnOverlayClick:true
					})
	  			},
	  			inputDialog(header){
	  				return new Promise((resolve,reject)=>{
		  				var inputDialogModel = this.$dialog({
							header,
							width:'400px',
							body:()=>{
								return this.$createElement('t-textarea',{
									attrs:{
										autofocus:'autofocus',
									},
									props:{
										autosize:true
									},
									ref:'inputDialog'
								})
							},
							onConfirm:(r)=>{
								var v = this.$refs.inputDialog.dataValue;
								if(!v){
									v='';
								// 	this.$message.warning('请输入不通过原因');
								// 	return
								}
								resolve(v)
								inputDialogModel.destroy()
							},
							closeOnOverlayClick:true
						})
	  				})
	  				
	  			}
			}
		})

		//全局方法
	/*	Vue.myGlobalMethod = function () {
			console.log(222)
		}*/
		//添加实例方法
		Vue.prototype.showNotify = (msg)=>{
			var v = Vue.prototype;
			if(store.state.globalStore.msgAudio){
				store.state.globalStore.sucAudio.play()
			}
			v.$notify.info({title:msg.title,content:msg.content,duration:5000})
		}
		Vue.prototype.global_submit = (url,data,successFun,msg)=>{
			var v = Vue.prototype;
			if(!msg){
				msg = '确认提交吗？';
			}
			const confirmDia = v.$dialog.confirm({
				theme:'warning',
				header: '提示',
				body: msg,
				onConfirm: ({ e }) => {
					v.$axios.post(url,data)
					.then((r)=>{
						if(r.ok){
							v.$message('success',r.msg)
							// return;
							if(successFun){
								setTimeout(function(){
									successFun(r)
								},500)
							}							
						}else{
							let alertDia = this.$dialog.alert({theme:'info',header: '提示',body:r.msg,onConfirm:()=>{
							    alertDia.destroy()
							}})
						}
					})
					confirmDia.destroy();
				}
			})
		}

		Vue.prototype.global_delById = (table,id,successFun)=>{
			var v = Vue.prototype;
			const confirmDia = v.$dialog.confirm({
				theme:'danger',
				header:'确认删除该条数据吗？',
				body:'删除后不可恢复',
				onConfirm: ({ e }) => {
					v.$axios.post('index.php?admin-public-del',{table,id})
					.then((r)=>{
						if(r.ok){
							v.$message('success',r.msg)
							if(successFun){
								setTimeout(function(){
									successFun(r)
								},1000)
							}
						}else{
							let alertDia = this.$dialog.alert({theme:'info',header: '提示',body:r.msg,onConfirm:()=>{
							    alertDia.destroy()
							}})
						}
					})
					confirmDia.destroy();
				}
			})
		}

		Vue.prototype.global_del = (url,data,successFun,header,body,successMsg)=>{
			var v = Vue.prototype;
			if(!header){
				header='确认删除该条数据吗？'
			}
			if(!body){
				body = '删除后不可恢复'
			}
			const confirmDia = v.$dialog.confirm({
				theme:'danger',
				header,
				body,
				onConfirm: ({ e }) => {
					v.$axios.post(url,data)
					.then((r)=>{
						if(r.ok){
							if(successMsg){
								v.$message('success',successMsg)
							}else{
								v.$message('success',r.msg)
							}
							
							if(successFun){
								setTimeout(function(){
									successFun(r)
								},500)
							}
						}else{
							let alertDia = this.$dialog.alert({theme:'info',header: '提示',body:r.msg,onConfirm:()=>{
							    alertDia.destroy()
							}})
						}
					})
					confirmDia.destroy()
				}
			})
		}



		//data格式 {table:'',id:'',value:['field'=>value]}
		Vue.prototype.global_changeField = (data,successFun,msg)=>{
			var v = Vue.prototype;
			if(!msg){
				msg='确认操作吗？'
			}

			const confirmDia = v.$dialog.confirm({
				theme:'danger',
				header:"提示",
				body:msg,
				onConfirm: ({ e }) => {
					v.$axios.post('index.php?admin-public-changestate',data)
					.then((r)=>{
						if(r.ok){
							v.$message('success','操作成功')
							if(successFun){
								setTimeout(function(){
									successFun(r)
								},500)
							}
							
						}else{
							let alertDia = this.$dialog.alert({theme:'info',header: '操作失败',onConfirm:()=>{
							    alertDia.destroy()
							}})
						}
					})
					confirmDia.destroy()
				}
			})
		}
		Vue.prototype.global_changeFieldNoConfirm = (data,successFun)=>{
			var v = Vue.prototype;
			v.$axios.post('index.php?admin-public-changestate',data)
			.then((r)=>{
				if(r.ok){
					v.$message('success','操作成功')
					if(successFun){
						setTimeout(function(){
							successFun(r)
						},500)
					}
					
				}else{
					let alertDia = this.$dialog.alert({theme:'info',header: '操作失败',onConfirm:()=>{
					    alertDia.destroy()
					}})
				}
			})
		}

		// data 要求  data  tableName
		Vue.prototype.global_sort = (data,successFun)=>{
			var v = Vue.prototype;
			
			v.$axios.post('index.php?api-other-sort',data)
			.then((r)=>{
				if(r.ok){
					v.$message('success','排序成功')
					if(successFun){
						setTimeout(function(){
							successFun(r)
						},500)
					}
					
				}else{
					let alertDia = this.$dialog.alert({theme:'info',header: '操作失败',onConfirm:()=>{
					    alertDia.destroy()
					}})
				}
			})
			
		}

		Vue.prototype.getTime = (timestamp,t)=>{
			var date;
			if(timestamp){
			  	date = new Date(parseInt(timestamp));
			}else{
			  	date=new Date();
			}
			var year=date.getFullYear();
			var month=date.getMonth()+1;
			var day=date.getDate();
			var hour=date.getHours();
			var minute=date.getMinutes();
			var second=date.getSeconds();

			//这样写显示时间在1~9会挤占空间；所以要在1~9的数字前补零;
			if (hour<10) {
			  	hour='0'+hour;
			}
			if (day<10) {
			  	day='0'+day;
			}
			if(month<10){
			  	month='0'+month;
			}
			if (minute<10) {
			  	minute='0'+minute;
			}
			if (second<10) {
			  	second='0'+second;
			}
			if(!t || t==1){
			  var time=year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;//将时间显示在div内
			}else{
			  var time=year+'-'+month+'-'+day;//将时间显示在div内
			}
			return time;
		}
		
	}
}