<template>
    <div id="index">
        <div class="big_box">
            <img src="images/top.png" class="top_img" alt="">
            <div class="title">{{systemName}}</div>
            <div class="right_box">
                <div class="nowtime">{{nowTime}}</div>
                <div v-if="$route.name=='Gongshi'" class="add cursor" @click="addGongshi"><img src="images/pic_4.png" alt=""></div>
                <img v-if="$route.name=='Gongshi' || $route.name=='Fabu'" src="images/refresh.png" @click="referesh" alt="">
            </div>
        </div>
        <div class="menu2">
            <router-link to="/Index/Fabu" active-class="active">发布</router-link>
            <router-link to="/Index/Gongshi" active-class="active">公示</router-link>
            <router-link v-for="item in menu"  :to="{name:'Other',query:{id:item.id}}" :class="$route.query.id==item.id?'active':''">{{item.title}}</router-link>
        </div>
        <div class="IndexCon">
            <router-view ref="child" @changeSystemName="systemNameChange"></router-view>
        </div>  
        <div class="tip">{{config.index_desc}} <span class="tip2">{{config.julebu_v}}</span></div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
    name: 'Index',
    props: {},
    data: function() {
        return {
            nowTime: '',
            menu:[],
            systemName:''
        }
    },
    methods: {
        djs() {
            var d = this.dataList;
            if (d && d.length > 0) {
                var date = new Date();
                var nowtime = parseInt(date.getTime() / 1000);
                d.map((item) => {
                    if (item.endtime) {
                        var endTime = new Date(item.endtime + ' 23:59:59').getTime() / 1000;
                        var sytime = Math.abs(endTime - nowtime);
                        var d = parseInt(sytime / (24 * 60 * 60));
                        var h = parseInt((sytime / 60 / 60) % 24);
                        var i = parseInt((sytime / 60) % 60)
                        var s = parseInt(sytime % 60)
                        var t = (d > 0 ? (d + '天') : '') + (h > 0 ? (h + ':') : '') + (i > 0 ? (i + ':') : '') + s;
                        if (endTime - nowtime > 0) {
                            item.sytime = t;
                        } else {
                            item.sytime = '-' + t
                        }
                    } else {
                        item.sytime = ''
                    }
                })
            }
        },
        getMenus(){
            this.$axios.get('index.php?api-other-getIndexMenu')
            .then((r)=>{
                this.menu = r
            })
        },
        referesh(){
            switch(this.$route.name){
                case 'Fabu':
                    this.$refs.child.runPage()
                break;
                case 'Gongshi':
                    this.$refs.child.runPage()
                break;
            }
        },
        addGongshi(){
            this.$refs.child.detail('add')
        },
        systemNameChange(v){
            this.systemName = v
        }
    },
    
    watch: {
       '$route':{
            handler(to,from){
                if(to.name!='Gongshi'){
                    this.systemName = this.userInfo.systemName
                }
            },
            immediate:true
        }
    },
    computed: {
        ...mapState('globalStore', ['userInfo', 'config'])
    },
    components: {
        
    },
    mounted() {
        this.timer = window.setInterval(() => {
            this.nowTime = this.getTime();
        }, 1000)
        this.getMenus()
        this.systemName = this.userInfo.systemName
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>
<style scoped src="./index.css"></style>