<template>
    <div class="boxall">
        <div class="login_box">
            <div class="login_box_l">
                <img src="@/assets/login_images/sm_logo.png" alt="" class="sm_login">
                <div class="title">登录 Sign In</div>
                <form>
                    <label class="block">
                        <div class="tag">团队账号 Account</div>
                        <div class="input">
                            <input type="text" v-model="form.tdzh" autocomplete="off" placeholder="请输入团队账号" class="form_control" />
                        </div>
                    </label>
                    <label class="block">
                        <div class="tag">账号 Account</div>
                        <div class="input">
                            <input type="text" v-model="form.username" autocomplete="off"  placeholder="账户名" class="form_control" />
                        </div>
                    </label>
                    <label class="block">
                        <div class="tag">密码 Password</div>
                        <div class="input">
                            <input type="password" v-model="form.password" autocomplete="new-password" placeholder="密码" class="form_control" />
                        </div>
                    </label>
                    <label class="block">
                        <div class="tag">验证码 Captcha</div>
                        <div class="input">
                            <input type="text" v-model="form.captcha" placeholder="验证码" class="form_control form_sryzm" @keyup.enter="login" />
                            <div class="form_yzm">
                                <img :src="verifyUrl" @click="changeVerify">
                            </div>
                        </div>
                    </label>
                    <div @click="login" class="btn">登录&emsp;Sign in</div>
                </form>
            </div>
            <div class="login_box_r hidden-xs">
                <img src="@/assets/login_images/pic.jpg" class="pic" alt="">
                <div class="wel">
                    <div class="welcome"><span>欢迎来到工作区</span></div>
                    <div class="work"><span>Welcome to Workspace</span></div>
                </div>
            </div>
        </div>
        <div class="rong_g hidden-xs">
            <img src="@/assets/login_images/logo.png" alt="" class="rg_pic">
        </div>
        <div class="rg_rj">
            {{config.julebu_title}} {{config.julebu_v}}
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    name: 'Login',
    props: {},
    data: function() {
        return {
            verifyUrl:'',
            tip:true,
            isStorage:true,
            form:{
                tdzh:'',
                username:'',
                password:'',
                captcha:''
            }
        }
    },
    methods: {
        changeVerify(){
            this.verifyUrl = this.verifyUrl+'&'+Math.random()
        },
        login(){
            var d = this.form;
            if(this.tip){
                const confirmDia = this.$dialog.confirm({
                    theme:'info',
                    header:"提示",
                    body:"是否保持账号信息？",
                    closeOnOverlayClick:false,
                    confirmBtn:"保存",
                    onConfirm: ({ e }) => {
                        this.tip = false;
                        this.isStorage = true;
                        confirmDia.destroy()
                        this.loginRun(d)
                    },
                    onClose:()=>{
                        this.tip = false;
                        this.isStorage = false;
                        confirmDia.destroy()
                        this.loginRun(d)
                    }
                })
                
            }else{
                this.loginRun(d)
            }
            
        },
        loginRun(d){
            this.$axios.post('index.php?api-login-login',d)
            .then((r)=>{
                if (r.errorno == 0) {
                    if(this.isStorage){
                        delete d.captcha
                        localStorage.setItem('userAccount',JSON.stringify(d));
                    }
                    localStorage.setItem('userInfo',JSON.stringify(r.userInfo));
                    this.$store.state.globalStore.userInfo = r.userInfo;
                    this.$router.replace('/Xietong')
                }else {
                    
                    if(r.errorno == '0008'){
                        var tishi = this.$dialog({
                            header:r.msg,
                            body:()=>{
                                return this.$createElement('div',{
                                    style:{'white-space':'pre-line'},
                                    domProps: {
                                        innerHTML: r.nopass
                                    },
                                })
                            },
                            theme:'warning',
                            cancelBtn:null,
                            onConfirm:()=>{
                                tishi.destroy()
                            }
                        })

                    }else{
                        this.$message('warning',r.msg)
                    }
                }
            })
        }
    },
    computed:{
        ...mapState('globalStore',['config'])
    },
    mounted() {
        // this.verifyUrl = 'http://localhost'+this.$axios.baseURL+'index.php?system-api-randcode'
        this.verifyUrl = this.$axios.baseURL+'index.php?system-api-randcode'

        var userAccount = localStorage.getItem('userAccount');
        if(userAccount){
            this.tip = false;
            this.isStorage = true;
            this.form = JSON.parse(userAccount)
        }
    }
}
</script>
<style scoped>
*{
	font-family: '黑体';
	font-size: 13px;
}
.boxall {
	width: 100%;
    height: 100%;
    background-image: url('@/assets/login_images/backgrond.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rong_g {
    width: 470px;
    height: 50px;
    position: absolute;
    top: 50px;
    left: 72px;
}

.rong_g .rg_pic {
    height: 30px;
}

.rong_g .rg_txt {
    position: absolute;
    top: 20px;
    right: 0;
}

.rong_g span {
    font-size: 16px;
    margin-left: 20px;
    color: #fff;
}

.rg_rj {
    position: fixed;
    right: 20px;
    bottom: 60px;
    font-size: 16px;
    color: #999;
}

.rg_rj1 .phone {
    margin-left: 20px;
}

.rg_an {
    width: 115px;
    height: 36px;
    position: absolute;
    top: 50px;
    right: 0;
}

.rg_an a {
    display: block;
    background-image: url('@/assets/login_images/refresh.png');
    background-repeat: no-repeat;
    background-size: 42px 35px;
    width: 42px;
    height: 35px;
}



.login_box {
    display: flex;
}

.login_box_l {
    position: relative;
    background-color: #222c3c;
    padding: 0 40px 20px;
    background-image: url(@/assets/login_images/fill.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.sm_login {
    position: absolute;
    width: 60px;
    height: 60px;
    top: -30px;
    left: 30px;
}

.title {
    font-size: 30px;
    text-align: center;
    font-weight: 400;
    color: #fff;
    margin: 20px 0;
}

.block {
    margin-bottom: 8px;
    display: block;
}

.tag {
    width: 100%;
    color: #7f8fa4;
    font-size: 17px;
    margin-bottom: 8px;
}

.input {
    display: flex;
    justify-content: space-between;
}

.form_control {
    background-color: #222c3c;
    border: 1px solid #313d4f;
    border-radius: 5px;
    box-shadow: none;
    font-family: inherit;
    font-size: 22px;
    height: 50px;
    width: 344px;
    padding: 0 20px;
    display: block;
    color: #fff;
}

.form_sryzm {
    width: 200px;
    display: inline-block;
}

.form_yzm {
    width: 120px;
    height: 52px;
}

.form_yzm img {
    width: 100%;
    height: 100%;
}

.btn {
    margin: 20px auto 0;
    width: 386px;
    height: 49px;
    background-color: #329E40;
    display: block;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
}

.btn:hover {
    background-color: #38b248;
}

.login_box_r {
    height: 503px;
    position: relative;
}

.text {
    width: 100%;
    /*height: 147px;*/
    text-align: center;
    line-height: 80px;
    letter-spacing: 3px;
    font-size: 30px;
    position: absolute;
    top: -14px;
    left: 0;
}

.pic {
    height: 100%;
}

.wel {
    position: absolute;
    left: 30px;
    bottom: 20px;
    color: #fff;
}

.welcome span {
    font-size: 30px;
}

.work span {
    font-size: 21px;
}

@media screen and (max-width: 960px) {
    .rg_rj {
        bottom: 30px;
        font-size: 13px;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }

    .login_box {
        width: 100%;
        margin-left: 0;
        margin: 0 auto;
        position: static;
    }

    .login_box_l {
        float: none;
        margin: 0 auto;
    }
}
</style>