<template>
	<div>
		<div class="statebox">
		    <div :class="state==0?'active':''" @click="state=0">待完成 <sup>{{statusNum[0]}}</sup></div>
		    <div :class="state==1?'active':''" @click="state=1">已完成 <sup>{{statusNum[1]}}</sup></div>
		</div>
		<div class="searchBox">
		    <div class="s_l"></div>
		    <div class="s_r">
		        <div class="inp-box">
		            <input type="text" class="s-input" v-model="taskTitle" placeholder="搜索任务">
		            <img src="@/assets/img/Search icon4.png" @click="runPage" class="se-img" alt="">
		        </div>
		        <div class="bq-box" @click="showSaixuan=true"><img src="@/assets/img/biaoqian.png" alt=""></div>
		    </div>
		</div>
		<div class="cont_box">
		    <table class="table" cellpadding="0" cellspacing="5" border="0">
		        <thead ref="thead">
		            <tr>
		                <td width="50">序号</td>
		                <td width="50">优先</td>
		                    <!-- <td width="70">角色</td> -->
		                    <!-- <td width="100">部署领导</td> -->
		                <!-- <td width="120">负责领导</td> -->
		                <!-- <td width="219">协同领导</td> -->
		                
		                <td width="460">工作内容</td>
		                <td width="270">进展情况</td>
		                <td width="100">发布领导</td>
		                <td width="100">责任领导</td>
		                <!-- <td width="219">责任部门</td> -->
		                <td width="128">完成时限</td>
		                <td width="20"></td>
		            </tr>
		        </thead>
		    </table>
		    <div class="databox" @click.stop="caozuoIndex=null" ref="scrollBox">
		        <table class="table" ref="scrollCon" cellpadding="0" cellspacing="5" border="0">
		            <tbody>
		                <template v-for="(item,index) in dataList" v-key="item.id">
		                <tr v-if="item.state==state" @click.stop="detail(item)">
		                    <td width="50">{{index+1}}</td>
		                    <td width="50" class="nobg">
		                        <span :class="'state point'+item.star"></span>
		                    </td>
		                       <!--  <td width="70" class="nobg">
		                            <div class="role r2" v-if="item.fuze_uid==userInfo.uid"><span>负责</span></div>
		                            <div class="role r1" v-else-if="item.xietong_uids.indexOf(userInfo.uid)>-1"><span>协同</span></div>
		                        </td> -->
		                        <!-- <td width="100">{{item.fabu_name}}</td> -->
		                    
		                    <!-- <td width="219">{{getNames(item.xietong_uids).join('&nbsp;&nbsp;')}}</td> -->
		                    
		                    <td width="460">{{item.title}}</td>
		                    <td width="270">{{item.record.content}}</td>
		                    <!-- <td width="219" style="color:#075EAB;" v-html="getTagNames(item.tagIds).join('&nbsp;&nbsp;')"></td> -->
		                    <td width="100">{{item.name}}</td>
		                    <td width="100">{{item.fuze_name}}</td>
		                    <td width="128">{{item.endtime}}</td>
		                    <td width="20"  @click.stop="coazuo(index)">
		                        <div class="caozuo">
		                            <img src="@/assets/img/dian2.png" alt="">
		                            <ul v-if="caozuoIndex==index">
		                                <li class="bg-red" @click.stop="cancelIndexShow(item.id)">
		                                    取消传屏
		                                </li>
		                            </ul>
		                        </div>
		                    </td>
		                </tr>
		                </template>
		            </tbody>
		        </table>
		    </div>
		    <Saixuan v-if="showSaixuan" @cancel="showSaixuan=false" @saixuan="runPage" pageType="bushu" />
		</div>
	</div>
</template>
<script>
	import { mapState, mapGetters, mapActions } from 'vuex'
	import Saixuan from '@/components/Saixuan'
	export default {
  		name: '',
  		props: {},
  		data: function(){
  			return {
  				statusNum: [0, 0],
  				taskTitle: '',
  				dataList: [],
  				state: 0,
  				showSaixuan: false,

  				scrollTimer: null, // 滚动定时器
  				pauseTimer: null, // 暂停定时器
  				scrollDirection: 'down', // 滚动方向 up向上 down向下
  				scrollTop:0,

  				caozuoIndex:null
  			}
  		},
  		methods:{
  			runPage: function(args) {
  			    var data = {
  			        t: 0,
  			        needRecord: true,
  			        taskTitle: this.taskTitle,
  			        indexShow:1
  			    }
  			    if (args) {
  			        data = Object.assign(data, args);
  			    }

  			    this.$axios.post('index.php?api-index-getTaskAll', data)
  			    .then((r) => {
  			        this.dataList = r;
  			        var statusNum = [0,0]
  			        r.forEach((item,i)=>{
  			            statusNum[item.state] +=1
  			        })
  			        this.statusNum = statusNum
  			        this.$nextTick(()=>{
  			            this.autoScroll()
  			        })
  			    })


  			},
  			detail(info) {
  			    if(this.caozuoIndex!==null){
  			        this.caozuoIndex = null;
  			        return;
  			    }
  			    this.$router.push({ name: 'AddTask', params: info })
  			},
  			cancelIndexShow(id){
  			    this.global_changeField({table:'task',id,value:{indexShow:0}},(res)=>{
                // console.log(res)
                // console.log(this.caozuoIndex)
                this.$delete(this.dataList,this.caozuoIndex)
  			        this.caozuoIndex = null;
                var r = this.statusNum[this.state] - 1
                this.$set(this.statusNum,this.state,r)
                // this.$forceUpdate();
  			    })
  			},
  			coazuo(ind){
  			    this.pauseScroll();
  			    if(this.caozuoIndex == ind){
  			        this.caozuoIndex = null
  			    }else{
  			        this.caozuoIndex = ind
  			    }
  			    // console.log(this.caozuoIndex)
  			},

  			autoScroll() {
  			    var scrollHeight = this.$refs.scrollCon.clientHeight
  			    var clientHeight = this.$refs.scrollBox.clientHeight
  			    var scroll = scrollHeight - clientHeight
  			    if (scrollHeight<=clientHeight+40) {
  			        clearInterval(this.scrollTimer)
  			        this.scrollTimer = null
  			        return
  			    }
  			    this.scrollFun()
  			},
  			pauseScroll() {
  			    if (this.scrollTimer) {
  			        clearInterval(this.scrollTimer)
  			        this.scrollTimer = null
  			        this.pauseTimer = setTimeout(() => {
  			            this.autoScroll()
  			        }, 3000)
  			    }
            clearTimeout(this.menuTimer)
  			    this.menuTimer = setTimeout(() => {
  			        this.caozuoIndex = null
  			    },3000)
  			},
  			scrollFun() {
  				// console.log('scrollFun')
  			    if (this.scrollTimer) {
  			        clearInterval(this.scrollTimer)
  			        this.scrollTimer = null
  			    }
  			    const scrollHeight = this.$refs.scrollCon.clientHeight
  			    const clientHeight = this.$refs.scrollBox.clientHeight
  			    const scroll = scrollHeight - clientHeight

  			    this.scrollTimer = setInterval(() => {
  			        const scrollTop = this.$refs.scrollBox.scrollTop
  			        // console.log(scrollTop)
  			        if (this.scrollDirection === 'down') {
  			            const temp = scrollTop + 1
  			            this.$refs.scrollBox.scrollTop = temp
  			            if (scroll <= temp) {
  			                this.scrollDirection = 'up'
  			                this.pauseScroll();
  			            }
  			       } else if (this.scrollDirection === 'up') {
  			            const temp = scrollTop - 0.5
  			            this.$refs.scrollBox.scrollTop = temp
  			            if (temp <= 0) {
  			                this.scrollDirection = 'down'
  			                this.pauseScroll();
  			            }
  			        }
  			    }, 50)
  			},

  		},
  		beforeRouteEnter(to, from, next) {
  		    if (from.name == 'AddTask' && !to.params.referesh) {
  		        next(vm=>{
  		            vm.$nextTick(()=>{
  		                vm.$refs.scrollBox.scrollTop = vm.scrollTop
  		                vm.pauseScroll()
  		            })
  		        });
  		    } else {
  		        next(vm => {
  		            vm.runPage();
  		        })
  		    }
  		},
  		beforeRouteLeave(to,from,next){
  		    clearInterval(this.scrollTimer)
  		    this.scrollTop = this.$refs.scrollBox.scrollTop
  		    next();
  		},
  		watch: {
  		   	state(newv,oldv){
  		   		this.$nextTick(()=>{
  		   		    this.autoScroll()
  		   		})
  		   	}
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo']),
  		    ...mapGetters('globalStore', ['getNames', 'getTagNames']),
  		},
  		components: {
  		    Saixuan
  		},
  		mounted(){
  			// this.runPage()
  		},
  		beforeDestroy() {
  		    // 清理定时器
  		    clearTimeout(this.pauseTimer)
  		    this.pauseTimer = null
  		    clearInterval(this.scrollTimer)
  		    this.scrollTimer = null
  		    // 清理点击监听
  		    window.document.removeEventListener('click', this.pauseScroll)
  		}
  }
</script>
<style scoped src="./index.css"></style>
<style scoped>
	
</style>