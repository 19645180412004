//对axios进行二次封装
import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'
var that = Vue.prototype

// const baseURL = '/dlwl/task/project/project-2/'
const baseURL = 'https://note.qmkepu.com/v3/';
// const baseURL = 'https://www.keything.vip/project/';

//利用axios对象的create方法，创建axios实例(配置)
const requests = axios.create({
	baseURL,
	timeout:5000,
	headers:{
		'Content-Type':'application/x-www-form-urlencoded'
	}
})

var requestNum = 0
var loading = null

//请求拦截器  发送请求前，检测到，做一些事情
requests.interceptors.request.use((config)=>{
    var content = '正在加载'
    if(config.url == 'index.php?admin-public-uploadimg'){
        content = '正在上传'
    }
    if(requestNum==0){
        loading = that.$loading({fullscreen:true,text:content});
    }
    requestNum++
	return config;
})

//响应拦截器  服务器返回数据以后可以检测到
requests.interceptors.response.use((res)=>{
    requestNum--
    if(requestNum==0 && loading){
        loading.hide()
    }
	return res
},(error)=>{
    requestNum--
    if(requestNum==0){
        loading.hide()
    }
    that.$message('warning','网络错误')
})



const get = function(url, params){   
    var  uinfo = localStorage.getItem('userInfo');
    if(uinfo){
        uinfo = JSON.parse(uinfo);
        if(!params){
            params = {uid:uinfo.uid,clubId:uinfo.clubId,firstBumenId:uinfo.firstBumenId,topBumenId:uinfo.topBumenId}
        }else{
            params.uid = uinfo.uid;
            params.clubId = uinfo.clubId;
            params.firstBumenId = uinfo.firstBumenId;
            params.topBumenId = uinfo.topBumenId;

        }
    }
    return new Promise((resolve, reject) =>{        
        requests.get(url, {            
            params: params        
        }).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)        
    	})    
	});
}
const post = function(url, params) {
    var  uinfo = localStorage.getItem('userInfo');
    if(uinfo){
        uinfo = JSON.parse(uinfo);
        if(!params){
            params = {uid:uinfo.uid,clubId:uinfo.clubId,firstBumenId:uinfo.firstBumenId,topBumenId:uinfo.topBumenId}
        }else{
            params.uid = uinfo.uid;
            params.clubId = uinfo.clubId;
            params.firstBumenId = uinfo.firstBumenId;
            params.topBumenId = uinfo.topBumenId;
        }
    }
    return new Promise((resolve, reject) => {
        requests.post(url, Qs.stringify(params))
        .then(res => {
            resolve(res.data);
        })
        .catch(err =>{
            reject(err.data)
        })
    })
}

const upload = function(file) {
    
    var form = new FormData();
    // form.append('picurl',file);
    form.append('file',file);
    
    if(file.type == 'application/msword' || file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
       form.append('type',3);
    }else if(file.type == 'application/vnd.ms-powerpoint' || file.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'){
        form.append('type',5);
    }else if(file.type == 'application/vnd.ms-excel' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        form.append('type',4);
    }else if(file.type == 'application/pdf'){
        form.append('type',2);
    }else if(file.type.split('/')[0] == 'image'){

        if(file.size>2097152){
            that.$message('warning','上传图片请小于2M')
            return false;
        }  
        if('name' in file){
            form.append('type',1); //File
        }else{
            form.append('type',6); //Blob
        }  
        
    }
 
    return new Promise((resolve, reject) => {
        requests.post('index.php?admin-public-uploadfile', form,{
            headers: { "Content-Type": "multipart/form-data" }
        })
        .then(res => {
            // console.log(res)
            if(res.data.ok){
                // res.data.msg = window.location.origin+res.config.baseURL+'/'+res.data.msg
                res.data.msg = res.config.baseURL+res.data.msg
            }else{
                let alertDia = this.$dialog.alert({theme:'info',header: '上传提示',body:r.msg,onConfirm:()=>{
                    alertDia.destroy()
                }})
            }
            resolve(res.data);
        })
        .catch(err =>{
            reject(err.data)
        })
    })
}
const download = (url)=>{
    var host = baseURL
    //检测是本地还是线上
    if(window.location.port != '' && window.location.port != "80"){
        host ='http://localhost'+host
    }
    var u = host+'index.php?admin-public-download&fileurl='+url
    // console.log(u)
    // return
    window.location.href = u
}
const Fullurl = (relativeUrl, domain = '')=>{
    if(baseURL.indexOf('http') > -1){
        domain = baseURL
    }else{
        domain = 'http://localhost/'+baseURL
    }
    return domain  + relativeUrl
}

export default {
    baseURL,
	get,
	post,
    upload,
    download,
    Fullurl
}
