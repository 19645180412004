<template>
	<div class="AddBumen">
		<div class="tit">添加成员</div>
		<div class="con">
			<div class="bigbox">
				<div class="formbox">
					<div class="form-div" style="align-items:flex-end">
						<div class="columnBox">
							<span>序号</span>
							<input type="text" v-model.number.trim="form.sort">
						</div>
						<div class="columnBox">
							<span>头像</span>
							<div class="imgbox" @click="upload">
								<div class="filebox" >
									<template v-if="form.picurl">
										<img :src="form.picurl" alt="">
									</template>
									<template v-else>+</template>
									<input  type="file" ref="file" @change="uploadFile" hidden accept="image/png,image/gif,image/jpeg"/>
								</div>
							</div>
						</div>		
						<input type="text" placeholder="请输入姓名" v-model="form.name">			
					</div>
					<div class="form-div">
						<input type="text" placeholder="请输入账号" v-model="form.username">
					</div>
					<div class="form-div">
						<input type="text" :placeholder="form.id?'密码：不输入不修改':'请输入密码'" v-model="form.userpass">
					</div>
					<div class="form-div">
						<input type="text" placeholder="请输入联系方式" v-model="form.tel">
					</div>
					<div class="form-div">
						<input type="text" placeholder="请输入职务" v-model="form.zhiwu">
					</div>
					<!-- 只有超级管理员 或 有通讯录管理的人 才可修改权限 （自己不能改自己的） -->
					<template v-if="userInfo.isMaster=='1' || (userInfo.powerIds.indexOf('5')>-1 && userInfo.uid!=form.id)">
						<div class="form-tit">
							人员权限
						</div>
						<div class="divlist">
							<div v-for="item in powerList" :key="item.id" @click="clickPower(item.id)" :class="(form.powerIds && form.powerIds.indexOf(String(item.id))) > -1?'active':''">{{item.title}}</div>
						</div>
					</template>
				</div>
				<div class="formbox">
					<div class="form-tit2">选择部门</div>
					<div class="bumenbox" >
						<BumenPeople ref="bp" showt="1" :pid="form.bumenId" @selectBumen="checkBumen"></BumenPeople>
					</div>
				</div>
			</div>
			<div class="btnbox">
				<div class="btn btn-primary" @click="submit">确认</div>
				<div class="btn btn-defalut" @click="$router.replace('/Tongxunlu')">取&emsp;消</div>
				<template v-if="form.id && userInfo.powerIds.indexOf('5')>-1 && userInfo.uid!=form.id">
					<div class="btn btn-red" @click="delPeople">删除</div>
					<div class="btn btn-warning" v-if="form.isLock==0" @click="userModify(1)">停用账号</div>
					<div class="btn btn-success" @click="userModify(0)" v-else>恢复账号</div>
				</template>
			</div>
			

		</div>
	</div>
</template>
<script>
	import BumenPeople from '@/components/BumenPeople';
	import { mapState } from 'vuex'
	export default {
  		name: 'AddPeople',
  		props: {},
  		data: function(){
  			return {
  				form:{
  					sort:1,
  					name:'',
  					username:'',
  					userpass:'',
  					tel:'',
  					zhiwu:'',
  					bumenId:'',
  					picurl:'',
  					powerIds:[],
  				},
  				powerList:[
  					{id:1,title:'数据'},
  					{id:2,title:'协同'},
  					{id:3,title:'备忘'},
  					{id:4,title:'通讯录查看'},
  					{id:5,title:'通讯录管理'},
  					{id:6,title:'设置'},
  				],
  				bumenList:[]
  			}
  		},
  		components:{
  			BumenPeople
  		},
  		methods:{
  			checkBumen(id){
  				this.form.bumenId = id;
  			},
  			upload(e){
  				this.$refs.file.click()
  			},
  			uploadFile(e){ 
  				this.$axios.upload(e.target.files[0])
  				.then((r)=>{
  					if(r.ok){
  						this.form.picurl = r.msg;
  					}
  				})
  			},
  			clickPower(id){
  				id = String(id)
  				var ind = this.form.powerIds.indexOf(id)
  				if(ind>-1){
  					this.form.powerIds.splice(ind,1)
  				}else{
  					this.form.powerIds.push(id)
  				}
  			},
  			userModify(isLock){
  				if(isLock==1){
  					var msg = '确认停用该账号吗？';
  				}else{
  					var msg = '确认恢复该账号吗？';
  				}
  				var that = this;
  				this.global_changeField({table:'user',id:this.form.id,value:{isLock}},()=>{
  					this.$bus.$emit('updataData');
  					this.$router.replace('/Tongxunlu')
  				},msg)
  			},
  			delPeople(){
  				this.global_del('index.php?api-people-delPeople',{id:this.form.id},()=>{
  					this.$bus.$emit('updataData');
  					this.$router.replace('/Tongxunlu')
  				},"确认删除该账号吗？\n该账号所有数据将被清空");
  			},
  			submit(){
  				this.form.state=1
  				this.global_submit('index.php?api-people-addPeople',this.form,(r)=>{
  					this.$bus.$emit('updataData');
  					this.$router.replace('/Tongxunlu')
  				})
  			}
  		},
  		watch:{
  			'$route'(to) {
  				if(to.params.id>0){
  					var info =  to.params;
  					info['powerIds'] = info['powerIds'].split(',')
	  				info.userpass = ''
	  				this.form  = info
  					// console.log('watch-router',to.params)
  				}
  			}
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo'])
  		},
  		mounted(){
  			this.$refs.bp.$on('getBumen',(r)=>{
  				this.bumenList = r;
  			})
  			if('bumenId' in this.$route.query){
  				this.form.bumenId = this.$route.query.bumenId
  			}
  			if(JSON.stringify(this.$route.params) != '{}' && this.$route.params.id>0){
  				var info = this.$route.params;
  				info['powerIds'] = info['powerIds'].split(',')
  				info.userpass = ''
  				this.form  = info
  				// console.log('mounted',this.$route.params)
  				// console.log(this.$route)
  			}
  		}
  }
</script>
<style scoped>
	.btnbox{
		width: 700px;
	}
	input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
	  color: #9096a8;
	}

	input:-moz-placeholder, textarea:-moz-placeholder {
	  color: #9096a8;
	}

	input::-moz-placeholder, textarea::-moz-placeholder {
	  color: #9096a8;
	}

	input:-ms-input-placeholder, textarea:-ms-input-placeholder {
	  color: #9096a8;
	}
	.AddBumen{
		padding-left: 27px;
		padding: 16px 56px 65px 28px;
		height: 100%;
	}
	.tit{
		font-size: 28px;
		color: #313a4c;
		padding: 22px 16px 22px;
	}
	.con{
		background-color: #f4f6f9;
		border-radius: 8px;
		height: calc(100% - 161px);
	}
	.bigbox{
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: flex-start;
		margin-bottom: 20px;
	}
	.formbox{
		width: 520px;
		margin-top: 52px;
	}
	.formbox:last-child{
		margin-top: 72px;
		margin-left: 50px;
	}
	.columnBox{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 8px;
	}
	.columnBox span{
		font-size: 14px;
		color: #9da0af;
		text-align: center;
	}
	.columnBox input,.columnBox .imgbox{
		height: 48px;
		width: 48px;
		background-color: #e0e6ee;
		text-align: center;
		font-size: 20px;
		color: #9096a8;
		border: none;
		outline: none;
		border-radius: 6px;
	}
	.filebox{
		cursor: pointer;
	}
	.filebox img{
		width: 100%;
		height: 100%;
	}
	.columnBox .imgbox{
		font-size: 40px;
		line-height: 40px;
	}
	.form-div{
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}
	.form-div>span{
		font-size: 14px;
		color: #9da0af;
		width: 63px;
		text-align: center;
	}
	.form-div>input,.form-div>select{
		flex-grow: 1;
		height: 48px;
		background-color: #e0e6ee;
		text-align: center;
		font-size: 20px;
		color: #313a4c;
		border: none;
		outline: none;
		text-align-last: center;
	}
	select{
		text-align: center; text-align-last: center;
		width: auto;
		padding: 0 2%;
		margin: 0;
	}
	select option{
		text-align: center;
	}

	.form-tit{
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 19px;
		color: #313a4c;
	}
	.form-tit2{
		font-size: 26px;
		text-align: center;
		color: #313a4c;
	}
	.bumenbox{
		overflow-x:hidden;
		overflow-y: auto;
		height: 512px;
		/*border: 1px solid red;*/
	}
	.bu{
		height: 48px;
		display: flex;
		align-items: center;
		font-size: 20px;
		cursor: pointer;
	}
	.bu img.icon1{
		height: 22px;
		margin-right: 14px;
	}
	.bu img.del{
		width: 24px;
		height: 24px;
		margin-left: auto;
		margin-right: 24px;
	}
	.bu.active{
		background-color: #e0e6ee;
	}

	.divlist{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.divlist div{
		width: 167px;
		height: 50px;
		background-color: #e0e6ee;
		color: #969bac;
		line-height: 50px;
		text-align: center;
		font-size: 19px;
		margin-bottom: 6px;
		cursor: pointer;
	}
	.divlist div.active{
		background-color: #005baa;
		color: #fff;
	}
	.rolebox{
		display: flex;
	}
	.rolebox div{
		height: 48px;
		flex:1;
		background-color: #e0e6ee;
		color: #005baa;
		text-align: center;
		line-height: 48px;
	}
	.rolebox div:last-child{
		margin-left: 2px;
	}
	.rolebox div.active{
		color: #fff;
		background-color: #005baa;
	}
	.tip{
		color: red;
		line-height: 35px;
		font-size: 14px;
	}
	
</style>