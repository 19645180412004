<template>
	<div class="xietong">
		<div class="wdjsBox-1">
			<router-link class="addbox cursor" :to="{name:'addGroup'}"><img src="@/assets/img/add.png" alt="">新建任务群</router-link>
			<div v-for="(item,ind) in groupList" :class="'groupList cursor '+(pt==1 && item.id==groupid?'active':'')" @click="groupChange(item.id)">
				<div class="groupL groupText"  v-if="item.id==0">
					<em>全</em><em>体</em><em>成</em><em>员</em>
					<em class="badge" v-if="item.no_read_jindu>0">{{item.no_read_jindu>99?'99+':item.no_read_jindu}}</em>
				</div>
				<div :class="'groupL userNum'+item.uids.split(',').length" v-else>
					<template v-for="(it,index) in getPeoples(item.uids)">
						<div v-if="index<=8">
							<img :src="it.picurl" v-if="it.picurl!=''" alt="">
							<div v-else>
								{{it.name.substr(0,1)}}
							</div>
						</div>
					</template>
					<em class="badge" v-if="item.no_read_jindu>0">{{item.no_read_jindu>99?'99+':item.no_read_jindu}}</em>
				</div>
				<div class="groupR">
					<div class="groupName txt-cut2">{{item.title}}</div>
					<div class="groupRbox">
						（{{item.no_complete_task}}项）
						<em class="badge" v-if="item.no_read_task>0">+{{item.no_read_task}}</em>
					</div>
					<div @click.stop="$router.push({name:'addGroup',query:item})" class="caozuo" v-if="item.id!=0">
						<img src="@/assets/img/dian.png" v-if="item.id>0" alt="">
					</div>
					
				</div>
			</div>

			<div class="addbox">成员任务</div>

			<div :class="'groupList cursor '+((pt==0 && userActiveId==0)?'active':'')" @click="peopleChange(0)">
				<div class="groupL groupText">
					<em>全</em><em>部</em><em>任</em><em>务</em>
					<em class="badge" v-if="getAllPeopleData['no_read_jindu']>0">{{getAllPeopleData['no_read_jindu']>99?'99+':getAllPeopleData['no_read_jindu']}}</em>
				</div>
				<div class="groupR">
					<div class="groupName txt-cut2">全部成员任务</div>
					<div class="groupRbox">
						（{{getAllPeopleData['no_complete_task']}}项）
						<em class="badge" v-if="getAllPeopleData['no_read_task']>0">+{{getAllPeopleData['no_read_task']}}</em>
					</div>
				</div>
			</div>

			<div class="ubox">
				<div v-for="(it,ind) in myBumenPeople">
					<div class="kindbox" :style="'padding-left:'+(10+(it.level-1)*20)+'px'" @click="it.iszk=!it.iszk">
						<em class="one-txt-cut">{{it.title}}</em>
						<div class="kindR">
							<div>{{getChildSum(it.plist)}}项</div>
							<span><img src="@/assets/img/jt3.png" class="jt" alt="" :style="'transform:rotate('+(it.iszk?'0':'180')+'deg)'"></span>
						</div>
					</div>
					<div  class="peopleBox" :style="'height:'+(it.iszk?(it.plist.length*60+'px'):'0px')">
						<div v-for="(item,index) in it.plist" :class="'qbcy-item '+(item.id==userActiveId?'active':'')" @click="peopleChange(item.id)">
							<div class="b9-1">
								<img :src="item.picurl" v-if="item.picurl!=''" class="tx-img" alt="">
								<div v-else>
									{{item.name.substr(0,1)}}
								</div>
								<em class="badge" v-if="item.no_read_jindu>0">{{item.no_read_jindu>99?'99+':item.no_read_jindu}}</em>
							</div>
							<div class="b9-2">
								<div class="b9-i-1 one-txt-cut">
									{{item.name}}
								</div>
								<div class="b9-i-2">
									{{item.zhiwu}}
								</div>
							</div>
							<div class="b9-3">
								（{{item.no_complete_task}}项）
								<em class="badge" v-if="item.no_read_task>0">+{{item.no_read_task}}</em>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="conbox">
			<div class="rwlb">
				<div class="rwlb-tit">
					<div class="font-25 col-051349">事项列表</div>
					<div class="state-1">
						<div :class="state==0?'active':''" @click="stateChange(0)">
							<div>待完成</div>
							<div class="num-2 font-12">{{statusNum[0]}}</div>
						</div>
						<div :class="state==1?'active':''" @click="stateChange(1)">
							<div>已完成</div>
							<div class="num-2 font-12">{{statusNum[1]}}</div>
						</div>
					</div>
				</div>
				<div class="search-box">
					<ul class="s-L">
						<li :class="roleType==0?'active':''" @click="roleType=0">全部</li>
						<li :class="roleType==1?'active':''" @click="roleType=1">发布</li>
						<li :class="roleType==2?'active':''" @click="roleType=2">接收</li>
					</ul>
					
					<div class="inp-box">
						<input type="text" class="s-input" v-model="taskTitle" placeholder="搜索任务">
						<img src="@/assets/img/Search icon4.png" @click="searchTask" class="se-img" alt="">
					</div>
					<div class="bq-box" @click="showSaixuan=true"><img src="@/assets/img/biaoqian.png" class="bqian-img" alt=""></div>
					<div class="clear" v-if="saixuanArgs || taskTitle" @click="saixuanArgs=false;taskTitle='';getTaskStatus()">清空条件</div>

					<router-link :to="{name:'AddTask',query:{t:0,pt:this.pt,groupid:this.groupid,fuze_uid:this.userActiveId,cateId:this.cid}}" class="jiahao-box"><img src="@/assets/img/pic_4.png" class="pic-4" alt=""></router-link>
				</div>
				<div class="cate_box">
					<ul>
						<li :class="cid==0?'active':''" @click="taskKindChange(0)">全部</li>
						<li :class="cid==item.id?'active':''" v-for="item in taskKind" @click="taskKindChange(item.id)">{{item.title}}</li>
					</ul>
				</div>
				
				
				<div class="taskTable">
					<TaskTable @activeInfo="showTaskInfo"></TaskTable>
				</div>
				
			</div>
			
			<Saixuan v-if="showSaixuan" @cancel="showSaixuan=false" @saixuan="runSaixuan" :pageType="pt==0?'xietong':'groupXietong'" />

			<TaskDetail v-if="ActiveTaskinfo" :info="ActiveTaskinfo" @reload="reloadFun()"></TaskDetail>
			
			
			
			<!-- <addTask v-if="showAddTask" :t="t" :conHeight="150" @close="showAddTask=false" @confirmAdd="successAddTask"></addTask> -->
		</div>
		<router-view></router-view>
	</div>
</template>
<script>
	import { mapState,mapGetters,mapActions } from 'vuex'
	import TaskTable from '@/components/TaskTable'
	import TaskDetail from '@/components/TaskDetail'
	import Saixuan from '@/components/Saixuan'

	import ws from '@/api/websocket.js'
	import ws_xietong_mixins from '@/api/ws_xietong_mixins.js'
	export default {
  		name: 'Xietong',
  		props: {},
  		data: function(){
  			return {
  				pt:1,		//0 人对人    	1 群
  				groupid:0,  //0 全体成员群	>0 对应群组
				userActiveId:0,

				state:0, //0待完成1已完成
				statusNum:[0,0], //待完成数量,已完成数量
				roleType:0,
				cid:0,
				ActiveTaskinfo:false,
				taskTitle:'',
				showSaixuan:false,
				saixuanArgs:false,
				allPeopleQStauts0Num:0,
				allPeopleStauts0Num:0,
  			}
  		},
  		methods:{
  			reloadFun(){
  				this.getTaskStatus();
  			},
  			runSaixuan(args){
  				
  				this.getTaskStatus(args)
  			},
  			groupChange:function(groupid){
  				this.pt = 1;
  				this.groupid = groupid;
  				this.userActiveId = 0;
  				this.getTaskStatus();
  				//设置任务已读
  				this.$store.commit('globalStore/setGroupNoReadTask',{id:groupid,reset:true})
  			},
  			peopleChange:function(uid){
  				this.pt=0;
  				this.userActiveId = uid;
  				this.getTaskStatus();
  				if(uid == 0){
  					this.$store.commit('globalStore/setPeopleNoReadTaskClear',{})
  				}else{
  					this.$store.commit('globalStore/setPeopleNoReadTask',{id:uid,reset:true})
  				}
  			},
  			getTaskStatus:function(args){
  				var data = {
  					t:0,
  					cid:this.cid,
  					seeUid:this.userActiveId,
  					pt:this.pt,	
  					groupid:this.groupid,
  					taskTitle:this.taskTitle,
  					roleType:this.roleType
  				}
  				if(args && 'star' in args){
  				    this.saixuanArgs = args
  				    if(args.cid>0){
  				    	this.cid = args.cid
  				    }
  				    data = Object.assign(data, args);
  				}else if(this.saixuanArgs){
  				    data = Object.assign(data, this.saixuanArgs);
  				}
  				
  				
  				this.$axios.post('index.php?api-task-getTaskStatus',JSON.parse(JSON.stringify(data)))
  				.then((r)=>{
  					this.statusNum = r;
  					data.state = this.state;
  					data.statusNum = r;
  					this.$bus.$emit('getTaskList',data)
  				})
  			},
  
  			searchTask:function(){
  				this.getTaskStatus();
  			},
  			stateChange:function(s){
  				this.state = s;
				var data = {
					t:0,
					cid:this.cid,
					seeUid:this.userActiveId,
					state:this.state,					
					pt:this.pt,
					groupid:this.groupid,
					taskTitle:this.taskTitle,
					roleType:this.roleType,
					statusNum:this.statusNum,
				}
				if(this.saixuanArgs){
					data = Object.assign(data,this.saixuanArgs)
				}
  				this.$nextTick(()=>{
  					console.log('stateChange')
  					this.$bus.$emit('getTaskList',data)
  				})
  				
  			},
  			showTaskInfo:function(info){
  				// console.log('index-showTaskInfo',info)
  				this.ActiveTaskinfo = info;
  			},
  			taskKindChange(id){
  				this.cid = id;
  				this.getTaskStatus();
  			},
  			getChildSum(d){
				var n = 0;
				d.forEach((item,i)=>{
					n += parseInt(item.no_complete_task)
				})
				return n
			}
  		},
  		watch:{
  			roleType(newv,oldv){
  				this.getTaskStatus();
  			},
  			$route(to, from) {
  				if(from.name == 'addGroup' && from.params.refreshGroupList==1){
  					this.$store.dispatch('globalStore/getGroupList');
  				}
  			}
  		},
  		beforeRouteEnter(to, from, next) {
	        if(from.name == "AddTask"){
	        	// if(to.params.referesh){
	        	// 	next(vm=>{
	        	// 		vm.state = 0;
	        	// 		vm.getTaskStatus();
	        	// 	})
	        	// }else{
	        		next();
	        	// }
	        	
	        }else{
	        	next(vm=>{
	        		vm.getTaskStatus();
	        	})
	        	
	        }
	    },
	    beforeCreate() {
	    	// this.$store.dispatch('globalStore/getGroupList')
	    	
	        this.$store.dispatch('globalStore/getMyBumenPeople');
	        this.$store.dispatch('globalStore/getAllPeople');
	        this.$store.dispatch('globalStore/getTagKind');
	        this.$store.dispatch('globalStore/getAllTag');
	        this.$store.dispatch('globalStore/getTaskKind');
	        this.$store.dispatch('globalStore/getGsTaskKind');
	        this.$store.dispatch('globalStore/getBwTaskKind');
	        
	    },
	    
  		components:{
  			TaskTable,
  			TaskDetail,
  			Saixuan
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo','taskKind','myBumenPeople','groupList']),
  		    ...mapGetters('globalStore', ['getInfoById','getPeoples']),
  		    getAllPeopleData(){
  				var data = {
					no_complete_task:0,
					no_read_jindu:0,
					no_read_task:0
				}

  				this.myBumenPeople.forEach((item,index)=>{
  					item.plist.forEach((it,ind)=>{
  						data.no_complete_task += parseInt(it.no_complete_task)
  						data.no_read_jindu += parseInt(it.no_read_jindu)
	  					data.no_read_task += parseInt(it.no_read_task)
  					})
  				})
  				return data
			}
  		},
  		mixins: [ws_xietong_mixins],
  		mounted(){
  			// console.log('xietongMounted')
  			ws.ws_init()
  		}
  }
</script>
<style scoped src="./index.css"></style>
<style scoped>
	.line{
		width: 80%;
		background-color: #D3D5D9;
		height: 4px;
		margin: 0 auto;
	}
	.xietong{
		height: 100%;
		display: flex;
	}
	.wdjsBox-1{
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
		flex-shrink: 0;
		/*width: 190px;*/
		width: 290px;
		border-right: 1px solid #313a4c;
	}

	.js-tit{
		/*flex: 1;*/
		line-height: 85px;
		text-align: center;
		height: 85px;
		font-size: 20px;
		color: #000000;
		cursor: pointer;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center
	}


	.js-tit.active{
		border-right: 3px solid #005baa;
    	background-color: #eaeef4;
	}
	.qbcy{
		height: 85px;
		width: 100%;
		
		font-size: 20px;
		color: #000000;
		line-height: 85px;
		text-align: center;
		box-sizing:border-box;
		border-bottom: 1px solid #eaebed;
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.qbcy.active{
		border-right: 3px solid #005baa;
		background-color: #eaeef4;
	}

	.ubox{
		flex-grow: 1;
		overflow-x:hidden;
		overflow-y: auto;
	}
	.qbcy-item.active{
		background-color: #eaeef4;
		border-right: 3px solid #005baa;
	}
	.qbcy-item{
		height: 60px;
		width: 100%;
		border-bottom: 1px solid #eaebed;
		padding-left: 7px;
		display: flex;
		align-items: center;
		color: #313a4c;
		box-sizing:border-box;
	}
	.qbcy-item.active .b9-1 div{
		background-color: #fff;
	}
	.tx-box{
		position: relative;
		margin-right: 13px;
	}
	.tx-box > .tx{
		color: #fff;
		background-color: #005baa;
		width: 45px;
		height: 45px;
		border-radius: 45px;
		text-align: center;
		line-height: 45px;
	}
	.b9-1{
		width: 50px;
		height: 50px;
		position: relative;
	}
	.b9-1 div{
		border-radius: 8px;
		height: 100%;
		line-height: 50px;
		background-color: #eaeef4;
		text-align: center;
		font-size: 18px;
		color: var(--primary-color);
		font-weight: 600;
	}
	.b9-1 .tx-img{
		width: 100%;
		height: 100%;
		border-radius: 8px;
	}
	.b9-2{
		flex-grow: 1;
		margin-left: 10px;
		width: 0;
	}
	.b9-i-1{
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #000;
		font-size: 20px;
	}
	.b9-i-2{
		color: #999;
		font-size: 14px;
	}

	.b9-3{
		color: #a1acbb;
		font-size: 14px;
		position: relative;
		flex-shrink:0;
	}

	

	
	.font-20{
		font-size: 20px
	}
	.font-20.zs{
		margin-bottom: 2px;
	}
	.font-15.zjb{
		height: 25px;
		line-height: 25px;
		padding-left: 10px;
		/*display: flex;*/
		/*justify-content: space-between;*/
	}
	.tx-num{
		position: absolute;
	    left: 39px;
	    top: -2px;
	    background-color: #005baa;
	    color: #ffffff;
	    font-size: 13px;
	    padding: 0px 5px;
	    line-height: 21px;
	    text-align: center;
	    border-radius: 8px;
	    white-space: nowrap;
	}
	.state0{
		position: relative;
		left: 10px;
	    background-color: #005baa;
	    color: #ffffff;
	    font-size: 13px;
	    padding: 0px 5px;
	    line-height: 21px;
	    text-align: center;
	    border-radius: 8px;
	}

	.kindbox{
		/*margin-bottom: 10px;*/
		background-color: #ffffff;
		border-radius: 5px;
		padding: 0 10px;
		display:flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		color: #222222;
		height: 40px;
		border-bottom: 1px solid #eaebed;
		cursor: pointer;
	}
	.kindbox em{
		flex-grow: 1;
		width: 0;
	}
	em{
		font-style: normal;
	}
	.kindbox .kindR{
		color: #005baa;
		font-size: 16px;
		display: flex;
		flex-shrink: 0;
	}
	.kindbox .kindR div{
		flex-shrink: 0;
	}
	.kindbox .kindR span{
		margin-left: 14px;
		width: 20px;
		height: 20px;
		background-color: #005baa;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
	}
	.kindbox .kindR span img.jt{
		width: 10px;
		transition: all ease .3s;
	}
	.peopleBox{
		transition: height .3s ease-in-out;
		overflow: hidden;
		height: auto;
		will-change: height;	
	}




	.addbox{
		display: flex;
		align-items: center;
		height: 41px;
		justify-content: center;
		font-size: 16px;
		border-bottom: 1px solid #eaebed;
		color: #444;
	}
	.addbox img{
		width: 15px;
		margin-right: 10px;
		
	}
	.groupList{
		width: 100%;
		border-bottom: 1px solid #eaebed;
		padding: 5px 7px;
		display: flex;
		align-items: center;
		color: #313a4c;
		box-sizing:border-box;
	}
	.groupList.active{
		background-color: #eaeef4;
	}
	.groupList.active .groupL{
		background-color: #FFF;
	}
	.groupList .groupL{
		flex-shrink: 0;
		width: 50px;
		height: 50px;
		border-radius: 8px;
		background-color: #eaeef4;
		text-align: center;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 4px;
		box-sizing:border-box;
		position: relative;
	}
	.groupList .groupL.userNum2>div,.groupList .groupL.userNum3>div,.groupList .groupL.userNum4>div{
		width: 19px;
		height: 19px;
	}
	.groupList .groupL>div{
		width: 14px;
		height: 14px;
	}

	.groupList .groupL div img{
		width: 100%;
		height: 100%;
		float: right;
	}
	.groupList .groupL>div div{
		width: 100%;
		height: 100%;
		background-color: var(--primary-color);
		color: #fff;
		font-size: 12px;
	}
	.groupList .groupL.groupText{
		color: var(--primary-color);
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 9px;
		padding-top: 6px;
		font-weight: bold;
	}
	.badge{
		position: absolute;
		background-color: red;
		color: #fff;
		border-radius: 16px;
		top: -4px;
		right: -10px;
		font-size: 12px;
		display: inline;
		height: 16px;
		line-height: 16px;
		padding: 0 4px;
	}
	.b9-3 .badge,.groupRbox .badge{
		left: 0;
		transform: translateX(-50%);
		right: auto;
		top: -16px;
	}
	.groupList .groupR{
		margin-left: 10px;
		flex-grow: 1;
		width: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		height: 100%;
	}
	.groupList .groupR .groupName{
		font-size: 17px;
		color: var(--primary-color);
		flex-grow: 1;
		width: 0;
	}
	.groupList .groupR .groupRbox{
		color: #a1acbb;
		font-size: 16px;
		flex-shrink:0;
		position: relative;
	}
	.groupList .groupR .caozuo{
		position: absolute;
		right: -7px;
		top: -4px;
		width: 20px;
		height: 20px;
	}
	.groupList .groupR .caozuo img{
		height: 3px;
		float: right;
		margin-right: 3px;
		margin-top: 8px;
	}
	.groupList .groupR .caozuo:hover{
		background-color: #fff;
	}
</style>