<template>
    <div>
        <!-- {{taskKind.length>0?getTaskTitleById(activeCid):userInfo.systemName}} -->
        <div class="statebox">
            <div :class="state==0?'active':''" @click="state=0">待完成 <sup>{{statusNum[0]}}</sup></div>
            <div :class="state==1?'active':''" @click="state=1">已完成 <sup>{{statusNum[1]}}</sup></div>
        </div>
        <div class="searchBox">
            <div class="s_l">
                <div :class="item.id==activeCid?'active':''" @click="cateChange(item.id,index)" v-for="(item,index) in taskKind">{{item.title}} <sup>{{item.gongshiNum}}</sup></div>
            </div>
            <div class="s_r">
                <div class="inp-box">
                    <input type="text" class="s-input" v-model="taskTitle" placeholder="搜索任务">
                    <img src="@/assets/img/Search icon4.png" @click="runPage" class="se-img" alt="">
                </div>
                <div class="bq-box" @click="showSaixuan=true"><img src="@/assets/img/biaoqian.png" alt=""></div>
                <div class="clear" v-if="saixuanArgs || taskTitle" @click="saixuanArgs=false;taskTitle='';runPage()">清空条件</div>
                <em @click="fontSize+=2">字号 <span>+</span></em>
                <em @click="fontSize-=2">字号 <span>-</span></em>
            </div>
        </div>
        <div class="cont_box">
            <table class="table" cellpadding="0" cellspacing="5" border="0">
                <thead ref="thead">
                    <tr>
                        <td v-for="item in fieldData" v-if="taskKind.length>0 && taskKind[activeIndex]['field'].indexOf(item.id) > -1" :width="item.width">{{item.title}}</td>  
                        
                        <td width="20"></td>
                    </tr>
                </thead>
            </table>
            <div class="databox" @click.stop="caozuoIndex=null" ref="scrollBox">
                <table class="table" ref="scrollCon" cellpadding="0" cellspacing="5" border="0">
                    <tbody :style="'font-size:'+fontSize+'px;line-height:'+(fontSize+13)+'px'">
                        <tr v-for="(item,index) in dataList" @click.stop="detail('edit',item)">
                            <td v-for="(it,ind) in fieldData" v-if="taskKind.length>0 && taskKind[activeIndex]['field'].indexOf(it.id) > -1" :width="it.width" :class="it.id==2?'nobg':''">
                                <template v-if="it.id==1">
                                    {{index+1}}
                                </template>
                                <template v-else-if="it.id==2">
                                    <span :class="'state point'+item.star"></span>
                                </template>
                                <template v-else-if="it.id==3">
                                    {{item.title}}
                                </template>
                                <template v-else-if="it.id==4">
                                    {{item.record.content}}
                                </template>
                                <template v-else-if="it.id==5">
                                    {{item.name}}
                                </template>
                                <template v-else-if="it.id==6">
                                    {{item.fuze_name}}
                                </template>
                                <template v-else-if="it.id==7">
                                    {{getNames(item.xietong_uids).join('&nbsp;&nbsp;')}}
                                </template>
                                <template v-else-if="it.id==8">
                                    <div style="color:#075EAB;" v-html="getTagNames(item.tagIds).join('&nbsp;&nbsp;')"></div>
                                </template>
                                <template v-else-if="it.id==9">{{item.endtime}}</template>
                            </td>                           
                            <td width="20"  @click.stop="coazuo(index)">
                                <div class="caozuo">
                                    <img src="@/assets/img/dian2.png" alt="">
                                    <ul v-if="caozuoIndex==index">

                                        <li class="bg-warning" v-if="" @click.stop="setTop(item.id,item.topIndex)">
                                            {{item.topIndex<=0?'置顶':'取消置顶'}}
                                        </li>

                                        <li class="bg-success" @click.stop="completeTask(item.id)">
                                            完成
                                        </li>
                                        <li class="bg-red" @click.stop="delTask(item.id)">
                                            删除
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Saixuan v-if="showSaixuan" @cancel="showSaixuan=false" @saixuan="runPage" pageType="gongshi" />
        </div>
         <PasswordModel v-if="showPasswordModel" @cancel="showPasswordModel=false" @adopt="toDetail"></PasswordModel>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import PasswordModel from '@/components/PasswordModel'
import Saixuan from '@/components/Saixuan'
export default {
    name: 'Gongshi',
    props: {},
    data: function() {
        return {
            t: '',

            statusNum:[0,0,],
            state:0,

            fieldData:[
                {id:1,width:50,title:'序号'},
                {id:2,width:50,title:'优先'},
                {id:3,width:460,title:'工作内容'},
                {id:4,width:270,title:'进展情况'},
                {id:5,width:100,title:'部署领导'},
                {id:6,width:100,title:'责任领导'},
                {id:7,width:200,title:'协同领导'},
                {id:8,width:219,title:'责任部门'},
                {id:9,width:128,title:'完成时限'},
            ],

            caozuoIndex:null,
            taskTitle: '',
            dataList: [],
            taskKind: [],
            activeCid: 0,
            activeIndex: 0,
            showSaixuan: false,
            saixuanArgs: false,

            scrollTimer: null, // 滚动定时器
            pauseTimer: null, // 暂停定时器
            hideCaozuo: null, //按钮消失定时器
            scrollDirection: 'down', // 滚动方向 up向上 down向下
            scrollTop:0,

            fontSize:20,

            showPasswordModel:false,
            toPageType:'',
            detailData:{},
            canAddEditSecond:0,
        }
    },
    methods: {
        getTaskTitleById(cid) {
            var name = ''
            this.taskKind.forEach(function(item, i) {
                if (item.id == cid) {
                    name = item.title
                }
            })
            return name;
        },
        getTaskKind() {
            this.$axios.get('index.php?api-other-getGsTaskKind&needTaskGongShiNum=1&state='+this.state)
            .then((r) => {
                this.taskKind = r;
                if (r.length > 0) {
                    if (this.activeCid == 0) {
                        this.activeCid = r[0]['id']
                    }
                    this.runPage()
                }
            })
        },
        cateChange(id,index) {
            this.activeCid = id;
            this.activeIndex = index;
            this.runPage()
        },

        runPage: function(args) {
            var data = {
                cid: this.activeCid,
                isgongshi: 1,
                needRecord: true,
                state: this.state,
                taskTitle: this.taskTitle,
                order:{topIndex:'desc',id:'asc'}
            }
   
            if(args && 'star' in args){
                this.saixuanArgs = args
                data = Object.assign(data, args);
            }else if(this.saixuanArgs){
                data = Object.assign(data, this.saixuanArgs);
            }
            // console.log(data)

            this.$axios.post('index.php?api-index-getTaskStatus', data)
            .then((r) => {
                this.statusNum = r;
            })

            this.$axios.post('index.php?api-index-getTaskAll', data)
            .then((r) => {
                this.dataList = r;

                this.$nextTick(()=>{
                    this.autoScroll()
                })
            })
        },
        toDetail(){
            // this.canAddEditSecond = 30; //操作完后30s内修改无需输入密码
            // clearTimeout(this.detailTimer)
            // this.detailTimer = setInterval(()=>{
            //     this.canAddEditSecond--
            //     if(this.canAddEditSecond<=0){
            //         clearTimeout(this.detailTimer)
            //     }
            // },1000)
            // this.showPasswordModel = false
            if(this.toPageType == 'add'){
                this.$router.push({ name: 'AddTask', query: { isgongshi: 1,cateId:this.activeCid }})
            }else{
                if(this.caozuoIndex!==null){
                    this.caozuoIndex = null;
                    return;
                }
                this.$router.push({ name: 'AddTask', query: { isgongshi: 1 }, params: this.detailData })
            }            
        },

        detail(type,info) {
            this.toPageType         = type
            this.detailData         = info
            // if(this.canAddEditSecond<=0){
                // this.showPasswordModel  = true
            // }else{
                this.toDetail()  
            // }
        },
        delTask(id){
            this.global_del('index.php?api-task-delTask',{id},(r)=>{                
                this.$delete(this.dataList,this.caozuoIndex)
                this.caozuoIndex = null;

                this.statusNum[this.state]-=1;

                var activeTaskKindIndex = 0;
                this.taskKind.forEach((item,index)=>{
                    if(item.id == this.activeCid){
                        activeTaskKindIndex = index;
                    }
                })
                this.taskKind[activeTaskKindIndex]['gongshiNum']-=1;
            })
        },
        completeTask(id){
            this.global_changeField({table:'task',id,value:{state:1}},(res)=>{
                // console.log(this.caozuoIndex)
                this.$delete(this.dataList,this.caozuoIndex)
                this.caozuoIndex = null;

                this.statusNum[this.state]-=1;
                this.statusNum[this.state==0?1:0] = parseInt(this.statusNum[this.state==0?1:0])+1;


                var activeTaskKindIndex = 0;
                this.taskKind.forEach((item,index)=>{
                    if(item.id == this.activeCid){
                        activeTaskKindIndex = index;
                    }
                })
                this.taskKind[activeTaskKindIndex]['gongshiNum']-=1;
            })
        },
        setTop(id,topIndex){
            var that = this;
            this.$axios.post('index.php?api-index-setTop',{id,topIndex})
            .then((r)=>{
                this.caozuoIndex = null;
                if(r.ok){
                    this.$message('warning','操作成功')
                    setTimeout(()=>{
                        that.runPage()
                    },1500)                    
                }else{
                    let alertDia = this.$dialog.alert({theme:'info',header: '提示',body:r.msg,onConfirm:()=>{
                        alertDia.destroy()
                    }})
                }
            })
        },
        coazuo(ind){
            this.pauseScroll();
            if(this.caozuoIndex == ind){
                this.caozuoIndex = null
            }else{
                this.caozuoIndex = ind
            }
            
        },
        autoScroll() {
            const scrollHeight = this.$refs.scrollCon.clientHeight
            const clientHeight = this.$refs.scrollBox.clientHeight
            const scroll = scrollHeight - clientHeight
            if (scrollHeight<=clientHeight+40) {
                clearInterval(this.scrollTimer)
                this.scrollTimer = null
                return
            }
            this.scrollFun()
        },
        pauseScroll() {
            if (this.scrollTimer) {
                clearInterval(this.scrollTimer)
                this.scrollTimer = null
                this.pauseTimer = setTimeout(() => {
                    this.autoScroll()
                }, 3000)
            }
            clearInterval(this.hideCaozuo)
            this.hideCaozuo = setTimeout(() => {
                this.caozuoIndex = null
            },6000)
        },
        scrollFun() {
            if (this.scrollTimer) {
                clearInterval(this.scrollTimer)
                this.scrollTimer = null
            }
            const scrollHeight = this.$refs.scrollCon.clientHeight
            const clientHeight = this.$refs.scrollBox.clientHeight
            const scroll = scrollHeight - clientHeight

            this.scrollTimer = setInterval(() => {
                const scrollTop = this.$refs.scrollBox.scrollTop
                // console.log(scrollTop)
                if (this.scrollDirection === 'down') {
                    const temp = scrollTop + 1
                    this.$refs.scrollBox.scrollTop = temp
                    if (scroll <= temp) {
                        this.scrollDirection = 'up'
                        this.pauseScroll();
                    }
               } else if (this.scrollDirection === 'up') {
                    const temp = scrollTop - 0.5
                    this.$refs.scrollBox.scrollTop = temp
                    if (temp <= 0) {
                        this.scrollDirection = 'down'
                        this.pauseScroll();
                    }
                }
            }, 50)
        },
        
    },
    beforeRouteEnter(to, from, next) {
        //详情
        if (from.name == 'AddTask' && !to.params.referesh) {
            next(vm=>{
                vm.$nextTick(()=>{
                    vm.$refs.scrollBox.scrollTop = vm.scrollTop
                    vm.pauseScroll()
                })
            });
            
        } else {
            next(vm => {
                vm.getTaskKind();
            })
        }
    },
    beforeRouteLeave(to,from,next){
        clearInterval(this.scrollTimer)
        this.scrollTop = this.$refs.scrollBox.scrollTop
        next();
    },
    computed: {
        ...mapState('globalStore', ['userInfo', 'config','taskGsKind']),
        ...mapGetters('globalStore', ['getNames', 'getTagNames']),
    },
    components: {
        Saixuan,
        PasswordModel
    },
    watch:{
        state(){
            this.getTaskKind()
        },
        activeCid(newv,oldv){
            if(newv>0){
                this.$emit('changeSystemName',this.getTaskTitleById(newv))
            }
        }
    },
    mounted() {
        
    },
    beforeCreate() {

    },
    beforeDestroy() {
        // 清理定时器
        clearTimeout(this.pauseTimer)
        this.pauseTimer = null
        clearInterval(this.scrollTimer)
        this.scrollTimer = null
        // 清理点击监听
        // window.document.removeEventListener('click', this.pauseScroll)

    }
}
</script>
<style scoped src="./index.css"></style>