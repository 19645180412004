<template>
	<div class="box">
		<div class="L">
			<!-- <div class="sb">
				<input type="text" placeholder="搜索成员 部门">
				<img src="@/assets/img/search.png" alt="">
			</div> -->
			<div class="bl">
				<template v-for="(item,index) in bumenList">
					<div class="bu" @click="editBumen(index)" :class="$route.params.id==item.id?'active':''" :style="'padding-left:'+((item.level-1)*30+14)+'px'">
						<div>
							<img src="@/assets/img/lev.png" alt="">
							<b>{{item.title}}</b>
						</div>
						<span>
							{{'plist' in item?item.plist.length:0}}人
							<em class="" @click.stop="item.iszk=!item.iszk"><img src="@/assets/img/jt3.png" class="jt" alt="" :style="'transform:rotate('+(item.iszk?'0':'180')+'deg)'"></em>
						</span>
					</div>
					<ul class="plist" :style="'height:'+(item.iszk?(item.plist.length*58+'px'):'0px')">
						<li v-for="(it,ind) in item.plist" :key="it.id" @click="editPoeple(index,ind)" :class="$route.params.id==it.id?'active':''" :style="'padding-left:'+((item.level-1)*30+10)+'px'">
							<div class="sort">{{it.sort}}</div>
							<img v-if="it.picurl!=''" :src="it.picurl" alt="">
							<div v-else class="xingming">{{it.name.substr(0,1)}}</div>
							<div class="name">{{it.name}}</div>
							<div class="tel">{{it.tel}}</div>
						</li>
					</ul>
				</template>
				
			</div>
			<div class="btnb" v-if="JSON.stringify($route.params)=='{}' && (userInfo.powerIds.indexOf(5) > -1 || userInfo.isMaster==1)">
				<a :class="$route.path=='/Tongxunlu/ReviewPeople'?'active':''" @click="tiaozhuan('/Tongxunlu/ReviewPeople')">人员审核 <span v-if="reviewPeopleNum>0">{{reviewPeopleNum}}</span></a>
				<a :class="$route.path=='/Tongxunlu/AddBumen'?'active':''" @click="tiaozhuan('/Tongxunlu/AddBumen/0')">添加部门</a>
				<a :class="$route.path=='/Tongxunlu/AddPeople'?'active':''" @click="tiaozhuan('/Tongxunlu/AddPeople/0')">添加成员</a>
			</div>
		</div>
		<div class="R">
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	export default {
  		name: 'Tongxunlu',
  		props: {},
  		data: function(){
  			return {
  				Y:0,
  				bumenList:[],
  				activeBumenIndex:0,
  				reviewPeopleNum:0,
  			}
  		},
  		methods:{
  			tiaozhuan(url){
  				if(this.$route.path == url){
  					this.$router.replace('/Tongxunlu')
  				}else{
  					this.$router.push(url)
  				}
  			},
  			//只有超级管理员和当前长有通讯录管理的人，才可以修改部门信息
  			editBumen(index){
  				if(this.userInfo.isMaster=='0' && this.userInfo.powerIds.indexOf(5) == -1){
  					this.$message.warning('您无权修改部门信息')
  					return
  				}
  				var info = JSON.parse(JSON.stringify(this.bumenList[index])) 
  				delete info['plist']
  				if('id' in this.$route.params && this.$route.params.id == info.id){
  					this.$router.replace({
  						name:'Tongxunlu'
  					})
  				}else{
  					this.$router.replace({
  						name:'AddBumen',
  						params:info
  					})
  				}
  			},
  			delBumen(){
  				this.global_del('index.php?api-people-delBumen',{id:this.bumenList[this.activeBumenIndex]['id']},()=>{
  					this.bumenList.splice(this.activeBumenIndex,1)
  				},'确认删除该部门吗？<br/>提示：该部门下人员也会一并删除')
  			},
  			addPeople(){
  				var that = this;
  				this.$router.push('/AddPeople?bumenId='+that.bumenList[that.activeBumenIndex]['id'])
  			},
  			editPoeple(index,ind){
  				var info =  this.bumenList[index]['plist'][ind];
  				if(this.userInfo.isMaster=='0' && this.userInfo.powerIds.indexOf(5) == -1 && this.userInfo.uid!=info.id){
  					this.$message.warning('您无权修改他人信息')
  					return
  				}
  				
  				if('id' in this.$route.params && this.$route.params.id == info.id){
  					this.$router.replace({
  						name:'Tongxunlu'
  					})
  				}else{
  					this.$router.replace({
  						name:'AddPeople',
  						params:info
  					})
  				}
  			},
  			getBumenList(){
  				this.$store.dispatch('globalStore/getAllBumenPeople')
  				.then((r)=>{
  					this.bumenList = r;
  				})
  			},
  			getReviewPeopleNum(){
  				this.$axios.post('index.php?api-people-getReviewPeople',{bumenId:this.userInfo.bumenId})
  				.then((r)=>{
  					this.reviewPeopleNum = parseInt(r.total)
  				})
  			}
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo'])
  		},
  		beforeRouteEnter(to, from, next) {
  			next(vm=>{
  				vm.getReviewPeopleNum();
  				vm.getBumenList();
  			})
  		},
  		mounted(){
  			
  			this.$bus.$on('updataData',()=>{
  				this.getBumenList();
  			})
  		},
  		beforeDestroy(){
  			this.$bus.$off('updataData');
  		}
  }
</script>
<style scoped>
	.box{
		height: 100%;
		display: flex;
	}
	.L{
		width: 474px;
		height: 100%;
		background-color: #f4f6f9;
		position: relative;
		flex-shrink: 0;
		/*overflow: hidden;*/
	}
	.sb{
		background-color: #e0e3e7;
		border-radius: 10px;
		position: relative;
		height: 60px;
		margin: 28px 12px 15px;
	}
	.sb input{
		width: 351px;
		color: #444;
		height: 100%;
		background-color: transparent;
		border: none;
		outline: none;
		padding-left: 10px;
		font-size: 20px;
	}
	.sb img{
		position: absolute;
		width: 22px;
		top: 19px;
		right: 19px;
	}
	.bl{
		/*height: calc(100% - 210px);*/
		height: calc(100% - 107px);
		/*border: 1px solid red;*/
		/*overflow-x:hidden;*/
		overflow-y:auto;
	}
	.bu{
		background-color: #ffffff;
		padding: 0 14px;
		display: flex;
		align-items: center;
		height: 58px;
		color: #313a4c;
		justify-content: space-between;
		margin-bottom: 2px;
	}
	.bu.active{
		background-color: #D9E5F7;
	}
	.bu div{
		display: flex;
		align-items: center;
		font-size: 24px;
		flex-grow: 1;
		width: 0;
		margin-right: 20px;
	}
	.bu div b{
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-weight: normal;
	}
	.bu div img{
		margin-right: 14px;
	}
	.bu span{
		font-size: 19px;
		margin-left: auto;
		margin-right: 8px;
		display: flex;
	}
	.bu span em{
		width: 26px;
		height: 26px;
		background-color: #005baa;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		font-style: normal;
		margin-left: 18px;
		cursor: pointer;
	}
	.bu span em img{
		width: 16px;
		transition: all ease .3s;
	}
	.bu .dian{
		margin-left: 20px;
		width: 22px;
		height: 100%;
		cursor: pointer;
	}
	.bu .dian img{
		width: 100%;

	}
	.plist{
		transition: height .3s ease-in-out;
		overflow: hidden;
		height: auto;
		will-change: height;	
	}
	.plist>li{
		height: 58px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.plist>li.active{
		background-color: #D9E5F7;
	}
	.plist>li>img{
		width: 36px;
		border-radius: 36px;
		margin: 0 10px;
	}
	.plist>li .sort{
		width: 38px;
		height: 38px;
		background-color: #fff;
		color: #325b91;
		border: 1px solid #325b91;
		font-size: 16px;
		line-height: 38px;
		text-align: center;
	}
	.plist>li .xingming{
		color: #fff;
		background-color: #005baa;
		width: 45px;
		height: 45px;
		border-radius: 45px;
		text-align: center;
		line-height: 45px;
		margin-left: 10px;
	}
	.plist>li .name{
		flex-grow: 1;
		height: 40px;
		background-color: #fff;
		font-size: 18px;
		text-align: center;
		line-height: 40px;
		margin-right: 10px;
		margin-left: 10px;
	}
	.plist>li .tel{
		flex-grow: 1;
		height: 40px;
		background-color: #fff;
		font-size: 18px;
		text-align: center;
		line-height: 40px;
		margin-right: 10px;
	}
	.btn-samll{
		width: 51px;
		height: 40px;
		font-size: 18px;
		text-align: center;
		line-height: 40px;
		color: #fff;
		margin-left: 5px;
		cursor: pointer;
	}
	.btn-samll.red{
		background-color: red;
	}
	.btn-samll.green{
		background-color: #0a9100;
	}
	.btn-samll.gray{
		background-color: gray;
	}
	.btn-samll.warning{
		background-color: #005baa;
	}
	.btnb{
		height: 59px;
		display: flex;
		justify-content: space-around;
		margin:23px 0;
		flex:1;
	}
	.btnb a{
		height: 100%;
		margin: 0 10px;
		width: 100%;
		background-color: #e0e6ee;
		font-size: 21px;
		color: #005baa;
		text-align: center;
		line-height: 59px;
		cursor: pointer;
	}
	.btnb a:hover{
		background-color: #005baa;
		color: #fff;
	}
	.btnb a.active{
		background-color: #005baa;
		color: #fff;
	}
	.R{
		height: 100%;
		flex-grow: 1;
	}
</style>