export default {
	watch:{
		"$store.state.globalStore.wsOnMessage":{
			handler:function(data,oldVal){
				if(this.$route.name!='Xietong'){
					return
				}
				// console.log(data)
				switch(data['type']){
					case 'init':
						this.$store.commit('globalStore/changeGroup',data.groupList);
						break;
					case 'timelyMsg':
						//1新增任务2进度变更4任务取消
						switch(data['dataType']){
							case 1:
								var uids = data.data.js_uids+','+data.data.uid
								if(this.pt == data.data.pt 
									&& 
									(((uids.indexOf(this.userActiveId)>-1 || this.userActiveId==0) && this.pt==0) || (this.groupid==data.data.groupid && this.pt==1))									
								){
									if((this.cid==0 || this.cid==data.data.cid) && (
										this.roleType==0 || 
										(this.roleType==1 && this.userInfo.uid==data.data.uid) || 
										(this.roleType==2 && 
											(
												(this.pt==1 && this.userInfo.uid!=data.data.uid) || 
												(this.pt==0 && data.data.js_uids.indexOf(this.userInfo.uid)>-1)
											)
										)
									)){
										let v = parseInt(this.statusNum[data.data.state])+1
										this.$set(this.statusNum,data.data.state,v)

										if(this.state == data.data.state){
											//数据开头加入新数据，如果满页，结尾去一条
											let c = this.$children[2]
											c.dataList.data.unshift(data.data)
											if(c.dataList.data.length>c.pagesize*c.dataList.page){
												if(!c.dataList.haveData){
													this.$set(c.dataList,'haveData',true)
												}
												c.dataList.data.pop()
											}
											c.caozuoIndex = null
											c.select(0)
										}
									}
									// this.getTaskStatus()
								}else if(data.data.uid != this.userInfo.uid){
									this.showNotify(data)
									if(data.data.pt == 0){
										this.$store.commit('globalStore/setPeopleNoReadTask',{id:data.data.uid,num:1})
									}else{
										this.$store.commit('globalStore/setGroupNoReadTask',{id:data.data.groupid,num:1})
									}
								}


								//首页未完成任务数量
								if(data.data.pt=='0'){
									//我发布的任务
									if(data.data.uid == this.userInfo.uid){
										//只增加负责人的数量
										this.$store.commit('globalStore/setPeopleNoCompleteTask',{id:data.data.fuze_uid,num:1})
									}else if(data.data.js_uids.indexOf(this.userInfo.uid) > -1){
										//我接收的任务
										this.$store.commit('globalStore/setPeopleNoCompleteTask',{id:data.data.uid,num:1})
									}							
								}else{
									this.$store.commit('globalStore/setGroupNoCompleteTask',{id:data.data.groupid,num:1})
								}
								break;
							case 2:
								//新进度 列表新增
								if(data.data.uid != this.userInfo.uid){
									this.showNotify(data)
								}

								var taskInfo = data.taskInfo;
								if(taskInfo.uid == this.userInfo.uid){
									var uid = taskInfo.fuze_uid
								}else{
									var uid = taskInfo.uid
								}
								if('jindu' in data.data && data.data.jindu==100){
									if(taskInfo.pt=='0'){
										this.$store.commit('globalStore/setPeopleNoCompleteTask',{id:uid,num:-1})
									}else{
										this.$store.commit('globalStore/setGroupNoCompleteTask',{id:taskInfo.groupid,num:-1})
									}
								}
								if(this.ActiveTaskinfo.id == taskInfo.id){
									//设置已读
									
									this.$store.dispatch('globalStore/setJinduAlreadyRead',{
										id:taskInfo.id,
										pt:taskInfo.pt,
										uid,
										no_read_jindu:1,
										groupid:taskInfo.groupid
									})

									if('jindu' in data.data){
										this.ActiveTaskinfo.jindu = data.data.jindu
										if(data.data.jindu == 100){
											this.getTaskStatus()
											return
										}
									}
									//进度列表新增
									this.$store.commit('globalStore/addJindu',data.data);

									//更新taskTable进度
									this.ActiveTaskinfo.record = {uid:data.data.uid,content:data.data.content}
									if('jindu' in data.data){
										this.ActiveTaskinfo.jindu = data.data.jindu
									}
									
								}else{
									//找到对应任务，更新进度未读条数和内容
									var uids = taskInfo.js_uids+','+taskInfo.uid
									if(this.pt == taskInfo.pt && (((uids.indexOf(this.userActiveId)>-1 || this.userActiveId==0) && this.pt==0) || (this.groupid==taskInfo.groupid && this.pt==1))){
										if('jindu' in data.data && data.data.jindu == 100){
											this.getTaskStatus()
										}else{
											this.$store.commit('globalStore/addJindu',data.data);
										}
										
									}
									//设置首页未读进度数量
									if(taskInfo.pt=='0'){
										this.$store.commit('globalStore/setMyBumenPeopleAlreadyRead',{id:uid,readnum:1})
									}else{
										this.$store.commit('globalStore/setGroupAlreadyRead',{id:taskInfo.groupid,readnum:1})
									}
								}
								

								break;
							case 4:
								//任务取消
								if(data.data.uid != this.userInfo.uid){
									this.showNotify(data)
								}
								var uids = data.data.js_uids+','+data.data.uid
								if(this.pt == data.data.pt 
									&& 
									(((uids.indexOf(this.userActiveId)>-1 || this.userActiveId==0) && this.pt==0) || (this.groupid==data.data.groupid && this.pt==1))									
								){
									if((this.cid==0 || this.cid==data.data.cid) && (
										this.roleType==0 || 
										(this.roleType==1 && this.userInfo.uid==data.data.uid) || 
										(this.roleType==2 && 
											(
												(this.pt==1 && this.userInfo.uid!=data.data.uid) || 
												(this.pt==0 && data.data.js_uids.indexOf(this.userInfo.uid)>-1)
											)
										)
									)){
										let vv = parseInt(this.statusNum[data.data.state])-1
										this.$set(this.statusNum,data.data.state,vv)
										if(this.state == data.data.state){
											//如果是满页，直接重新请求
											let c = this.$children[2]
											// return
											if(c.dataList.data.length>=c.pagesize*c.dataList.page){
												this.getTaskStatus()
											}else{
												//寻找对应数据的角标
												let ind = null
												c.dataList.data.forEach((item,index)=>{
													if(item.id==data.data.id){
														ind = index
													}
												})
												if(ind!==null){
													c.dataList.data.splice(ind,1)
												}
												if(this.ActiveTaskinfo.id == data.data.id){
													c.caozuoIndex = null;
													c.select(0)
												}
											}
										}
									}									
								}
								//首页未完成任务数量
								if(data.data.pt == 0){
									if(data.data.uid == this.userInfo.uid){
										this.$store.commit('globalStore/setPeopleNoCompleteTask',{id:data.data.fuze_uid,num:-1})
									}else{
										this.$store.commit('globalStore/setPeopleNoCompleteTask',{id:data.data.uid,num:-1})
									}
									
								}else{
									this.$store.commit('globalStore/setGroupNoCompleteTask',{id:data.data.groupid,num:-1})
								}
								break;
						}
						this.$store.commit('globalStore/setNoticeAlreadyRead',{t:data.dataType-1,num:1});
						break;
					case 'timelyEvent':
						switch(data['dataType']){
							case 1:
								if(this.ActiveTaskinfo.id == data.data.taskid){
									return
								}
								console.log('timelyEvent',data.data)
								//设置首页未读进度数量
								if(data.data.pt=='0'){
									this.$store.commit('globalStore/setMyBumenPeopleAlreadyRead',{id:data.data.target_uid,readnum:-parseInt(data.data.no_read_jindu)})
								}else{
									this.$store.commit('globalStore/setGroupAlreadyRead',{id:data.data.groupid,readnum:-parseInt(data.data.no_read_jindu)})
								}
								//设置任务列表页 进度已读条数
								if(this.pt == data.data.pt && (((this.userActiveId == data.data.target_uid || this.userActiveId==0) && this.pt==0) || (this.groupid==data.data.groupid && this.pt==1))){
									this.$store.commit('globalStore/readJindu',data.data);
								}
								break;
						}
						break;
					case 'notice':
						/*3协同到提醒时间
			            5协同完成时间已到
			            6代办到提醒时间
			            7笔记到提醒时间
			            8代办完成时间已到
			            9笔记完成时间已到*/
			            this.$store.commit('globalStore/setNoticeAlreadyRead',{t:data.dataType-1,num:1});
			            this.showNotify(data)
						break;
					case 'groupMsg':
						/*addGroup
						delGroup
						exitGroup*/
						switch(data['dataType']){
							case 'addGroup':
								if(data.data.uid != this.userInfo.uid){
									this.showNotify(data)
								}
								this.$store.commit('globalStore/addGroup',data.data)
								break;
							case 'editGroup':
								if(data.data.uid != this.userInfo.uid){
									this.showNotify(data)
								}
								// this.$store.commit('globalStore/editGroup',data.data)
								// console.log('editGroup')
								this.$store.dispatch('globalStore/getGroupList')
								break;
							case 'delGroup':
								if(data.data.uid != this.userInfo.uid){
									this.showNotify(data)
								}
								this.$store.commit('globalStore/delGroup',data.data.id)
								break;
							case 'exitGroup':
								if(data.data.quiteUid != this.userInfo.uid){
									this.showNotify(data)
									var newUids = data.data.uids.filter((item,i)=>{
										return item!=data.data.quiteUid
									})
									this.$store.commit('globalStore/exitGroup',{id:data.data.id,uids:newUids.join(',')})
								}else{
									this.$store.commit('globalStore/delGroup',data.data.id)
								}
								break;
						}						
						break;
					case 'quiteMsg':
						switch(data['dataType']){
							case 'xtEdit':
								var uids = data.data.js_uids+','+data.data.uid
								if(this.pt == data.data.pt 
									&& 
									(((uids.indexOf(this.userActiveId)>-1 || this.userActiveId==0) && this.pt==0) || (this.groupid==data.data.groupid && this.pt==1))
									&&
									this.state == data.data.state && (this.cid==0 || this.cid==data.data.cid)
								){

									let c = this.$children[2]
									//寻找对应数据的角标
									let ind = null
									c.dataList.data.forEach((item,index)=>{
										if(item.id==data.data.id){
											ind = index
										}
									})
									if(ind!==null){
										data.data.record = c.dataList.data[ind]['record']
										if('no_read_jindu' in c.dataList.data[ind]){
											data.data.no_read_jindu = c.dataList.data[ind]['no_read_jindu']
										}
										
										this.$set(c.dataList.data,ind,data.data)
									}
									
									if(this.ActiveTaskinfo.id == data.data.id){
										this.ActiveTaskinfo = data.data

									}
								}
							break;
						}
						break;
				}
			}
		}
	},
	mounted(){

	}
}