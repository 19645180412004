export default {
	watch:{
		"$store.state.globalStore.wsOnMessage":{
			handler:function(data,oldVal){
				if(this.$route.name!='Beiwang'){
					return
				}
				console.log('ws_beiwang_mixins',data)
				switch(data['type']){
					case 'quiteMsg':
						switch(data['dataType']){
							case 'bwAdd':
								if(this.cid==0 || this.cid==data.data.cid){
									let v = parseInt(this.statusNum[data.data.state])+1
									this.$set(this.statusNum,data.data.state,v)
									if(this.state == data.data.state){
										//数据开头加入新数据，如果满页，结尾去一条
										let c = this.$children[1]
										c.dataList.data.unshift(data.data)
										if(c.dataList.data.length>c.pagesize*c.dataList.page){
											if(!c.dataList.haveData){
												this.$set(c.dataList,'haveData',true)
											}
											c.dataList.data.pop()
										}
										c.caozuoIndex = null
										c.select(0)
									}
								}
								
								break;
							case 'bwEdit':
								if(this.state == data.data.state && (this.cid==0 || this.cid==data.data.cid)){
									let c = this.$children[1]
									//寻找对应数据的角标
									let ind = null
									c.dataList.data.forEach((item,index)=>{
										if(item.id==data.data.id){
											ind = index
										}
									})
									if(ind!==null){
										data.data.record = c.dataList.data[ind]['record']
										this.$set(c.dataList.data,ind,data.data)
									}
									if(this.ActiveTaskinfo.id == data.data.id){
										this.ActiveTaskinfo = data.data
									}
								}
								break;
							case 'bwDel':
								if(this.cid==0 || this.cid==data.data.cid){
									let vv = parseInt(this.statusNum[data.data.state])-1
									this.$set(this.statusNum,data.data.state,vv)
									if(this.state == data.data.state){
										//如果是满页，直接重新请求
										let c = this.$children[1]
										// return
										if(c.dataList.data.length>=c.pagesize*c.dataList.page){
											this.getTaskStatus()
										}else{
											//寻找对应数据的角标
											let ind = null
											c.dataList.data.forEach((item,index)=>{
												if(item.id==data.data.id){
													ind = index
												}
											})
											if(ind!==null){
												c.dataList.data.splice(ind,1)
											}
											if(this.ActiveTaskinfo.id == data.data.id){
												c.caozuoIndex = null;
												c.select(0)
											}
										}
									}
								}
								
								break;
							case 'bwNewJindu':
									if(this.ActiveTaskinfo.id == data.data.id){
										if('jindu' in data.record){
											this.ActiveTaskinfo.jindu = data.record.jindu
											if(data.record.jindu == 100){
												this.getTaskStatus()
												return
											}else{
												let c = this.$children[2]
												c.addData.jindu = data.record.jindu
												this.ActiveTaskinfo.jindu = data.record.jindu												
											}
										}
										this.ActiveTaskinfo.record = {
											uid:data.record.uid,
											content:data.record.content
										}
										//进度列表新增
										this.$store.commit('globalStore/addJindu',data.record);
									}else if(this.cid==0 || this.cid==data.data.cid){
										if('jindu' in data.record && data.record.jindu == 100){
											this.getTaskStatus()
											return
										}
										let c = this.$children[1]
										let ind = null
										c.dataList.data.forEach((item,index)=>{
											if(item.id==data.data.id){
												ind = index
											}
										})
										if(ind!==null){
											if('jindu' in data.record){
												c.dataList.data[ind]['jindu'] = parseInt(data.record.jindu)
											}
											c.dataList.data[ind]['record'] = data.record

										}
									}
								break;
						}
					break;
				}
			},
			deep:true
		}
	}
}