import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



import TDesign from 'tdesign-vue';
import 'tdesign-vue/es/style/index.css';
Vue.use(TDesign);



import plugins from './plugins'
Vue.use(plugins)



Vue.config.productionTip = false
new Vue({
  	render: h => h(App),
  	router,
  	store,
  	beforeCreate(){
  		Vue.prototype.$bus = this; //全局事件总线 或 公共vc
  	}
}).$mount('#app')
