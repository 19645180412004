<template>
	<div class="system">
		<div class="con">
			<div class="tit">请输入密码后进行下一步</div>
			<div class="caozuo">
				<img src="@/assets/img/dui.png" alt="" @click="confirm">
				<img src="@/assets/img/cuo.png" alt="" @click="cancel">
			</div>
			<input type="text" placeholder="用户名" :value="userInfo.username" readonly>
			<input type="password" placeholder="密码 不输入不更改" v-model="form.userpass" autocomplete="new-password">
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	export default {
  		name: 'SystemConfig',
  		props: {},
  		data: function(){
  			return {
  				form:{
  					userpass:''
  				}
  			}
  		},
  		methods:{
  			cancel(){
  				this.$emit('cancel');
  			},
  			confirm(){
  				if(!this.form.userpass){
  					this.$message('warning','请输入密码')
  					return;
  				}
  				this.$axios.post('index.php?api-people-verifyUserPass',{userpass:this.form.userpass})
  				.then((r)=>{
  					if(r){
  						this.$emit('adopt',true)
  					}else{
  						this.$message('error','密码错误')
  					}
  				})
  			}
  		},
  		computed: {
  		    ...mapState('globalStore', ['userInfo'])
  		},
  		mounted(){
  			this.form = JSON.parse(JSON.stringify(this.userInfo));
  			this.form.userpass = ''
  		}
  }
</script>
<style scoped>
	.system{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		background-color: rgba(0,0,0,0.4);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.con{
		width: 500px;
		background-color: #EAEEF4;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		padding: 20px 20px 50px;
		position: relative;
	}
	.tit{
		font-size: 24px;
		color: #005baa;
		font-size: 29px;
		line-height: 50px;
	}
	.caozuo{
		position: absolute;
		right: 20px;
		top: 20px;
	}
	.caozuo img{
		width: 45px;
		margin-left: 25px;
	}
	.con>input{
		height: 60px;
		border: none;
		margin-top: 15px;
		font-size: 22px;
		padding-left: 20px;
		outline: none;
	}
	.text-c{
		text-align: center;
	}
	.txbox{
		display: flex;
		margin-top: 15px;
	}
	.txbox>input{
		flex-grow: 1;
		height: 60px;
		border: none;
		font-size: 22px;
		padding-left: 20px;
		outline: none;
		margin-left: 20px;
	}
	.imgbox{
		height: 60px;
		width: 60px;
		line-height: 50px;
		background-color: #fff;
		text-align: center;
		font-size: 46px;
		color: #9096a8;
		border: none;
		outline: none;
		border-radius: 6px;
		cursor: pointer;
	}
	.imgbox img{
		width: 100%;
		height: 100%;
	}
</style>