<template>
	<div class="tanbox">
		<div class="tanboxcon">
			<nav>
				{{info.id>0?'编辑任务群':'添加任务群'}}
				<div class="navR cursor">
				    <img src="@/assets/img/cuo.png" alt="" @click="$router.back()">
				</div>
			</nav>
			<div class="form">
				<div class="form-box">
					<div class="tit">群名称</div>
					<input type="text" v-model="info.title" placeholder="请输入群名称">
				</div>
				<div class="form-box">
					<div class="tit">群介绍</div>
					<textarea placeholder="请输入群介绍" v-model="info.description"></textarea>
				</div>
				<div class="form-box">
					<div class="tit">群成员 <div class="titR cursor" @click="showCheckPeople=true" v-if="!info.id || info.uid==userInfo.uid"><img src="@/assets/img/add.png" alt="">&nbsp;添加</div></div>
					<div class="groupbox">
						<div v-for="(item,index) in getPeopleByUids(info.uids)">
							{{item.name}}
							<em class="close" v-if="index>0 && !(info.id && info.uid!=userInfo.uid)" @click="info.uids.splice(index,1)"><img src="@/assets/img/del.png" alt=""></em>
						</div>
					</div>
				</div>
				<template v-if="info.id">
					<button class="btn btn-block btn-red" @click="delGroup" v-if="info.uid == userInfo.uid">取消任务群</button>
					<button class="btn btn-block btn-red" @click="quitGroup" v-if="info.uid!=userInfo.uid && info.uids.indexOf(userInfo.uid)>-1">退出任务群</button>
				</template>
				<button class="btn btn-block" @click="confirm">确认{{info.id?'修改':'添加'}}</button>
			</div>
		</div>
		<SelectPeople v-if="showCheckPeople" checkType="7" @cancel="showCheckPeople=false" :alreadyCheckIds="info.uids"></SelectPeople>
	</div>
</template>
<script>
	import {mapGetters,mapState} from 'vuex'
	import SelectPeople from '@/components/SelectPeople'
	export default {
  		name: 'addGroup',
  		props: {},
  		data: function(){
  			return {
  				showCheckPeople:false,
  				info:{
  					id:0,
  					title:'',
  					description:'',
  					uids:[]
  				}
  			}
  		},
  		methods:{
  			confirm(){
				if(this.info.title==''){
					this.$message('warning','请输入任务群名称')
	        	    return;
				}
				if(this.info.uids.length<=1){
					this.$message('warning','请选择2人以上的群人员')
	        	    return;
				}
				var info = this.info
				this.global_submit('index.php?api-group-addEdit', info, (r) => {
					if(r.ok){
						//新建群聊
						if(!info.id){
							info.id=r.insertId
							this.ws.send(JSON.stringify({type:'addGroup',info,cz:{picurl:this.userInfo.picurl,name:this.userInfo.uname,uid:this.userInfo.uid}}))
						}else{
							this.ws.send(JSON.stringify({type:'editGroup',info,cz:{picurl:this.userInfo.picurl,name:this.userInfo.uname,uid:this.userInfo.uid}}))
						}
						this.$router.back()
					}
				})
			},
			delGroup(){
				var info = this.info
				this.global_del('index.php?api-group-del',{id:info.id},(r)=>{
					this.ws.send(JSON.stringify({type:'delGroup',info,cz:{picurl:this.userInfo.picurl,name:this.userInfo.uname,uid:this.userInfo.uid}}))
					// this.$route.params.refreshGroupList = 1
					this.$router.back()
				},"确认解散此任务群吗？","所有与之关联的数据也将一并删除","取消成功！")
			},
			quitGroup(){
				var info = this.info
				this.global_del('index.php?api-group-quitGroup',{id:info.id},(r)=>{
					this.ws.send(JSON.stringify({type:'exitGroup',info,cz:{picurl:this.userInfo.picurl,name:this.userInfo.uname,uid:this.userInfo.uid}}))
					this.$route.params.refreshGroupList = 1
					this.$router.back()
				},'确认退出此任务群吗？','','退出成功！')
			}

  		},
  		beforeRouteLeave(to,from,next){
  			if(to.name != 'Xietong'){
  				if(window.confirm('确认离开吗？数据将不会保存')){
  					next()
  				}
  			}else{
  				next()
  			}
  		},
  		components:{
  			SelectPeople
  		},
  		computed:{
  			...mapState('globalStore', ['userInfo','ws']),
  			...mapGetters('globalStore',['getPeopleByUids'])
  		},
  		mounted(){
  			if('id' in this.$route.query){
  				var info = this.$route.query
  				info.uids = info.uids.split(',')
  				this.info = info
  			}else{
  				this.info.uids = [this.userInfo.uid]
  			}
  		}
  }
</script>
<style scoped>
	.tanboxcon{
		background-color: #fff;
	}
	.topmenu {
		display: flex;
		justify-content: space-between;
		font-size: 20px;
		margin-bottom: 10px;
		flex-shrink: 0;
	}
	.titR img{
		width: 15px;
	}
	.groupbox{
		min-height: 100px;
		display: flex;
		margin-top: 10px;
		flex-wrap: wrap;
	}
	.groupbox div{
		padding: 0 10px;
		height: 40px;
		color: #d3d8e3;
		text-align: center;
		line-height: 40px;
		background-color: #d3d8e3;
		color: #325b91;
		margin-bottom: 10px;
		margin-right: 15px;
		cursor: pointer;
		position: relative;
	}
	.groupbox div .close{
		position: absolute;
		right: -10px;
		top: -10px;
		width: 18px;
		height: 18px;
	}
	.groupbox div .close img{
		float: right;
		width: 100%;
	}
</style>