<template>
    <div class="conbox">
        <div class="rwlb-tit">
            <div class="font-25 col-051349">公示分类</div>
            <div class="box-right">
                <div class="zjl-box">
                    <input type="text" class="zjl-1" placeholder="请输入分类名称" v-model="title">
                    <div class="zjl-2" @click="add" v-if="activeIndex===false">添加</div>
                    <div class="zjl-2" @click="add" v-else>修改</div>
                    <div class="zjl-3" @click="del" v-if="activeIndex!==false">删除</div>
                </div>
                <div class="fieldbox">
                    <label v-for="item in fieldData" >
                        <input type="checkbox" :value="item.id" v-model="field"> {{item.title}}
                    </label>
                </div>
            </div>
        </div>
        <div class="cont-box2" :style="{zoom:zoom}">
            <vuedraggable class="cont-names" v-model="data" animation="300" @sort="dataUpdate" :options="{handle:'.title'}">
                <div v-for="(item,index) in data" @click="select(index)" :class="activeIndex===index?'active':''" :key="item.id">
                    <div class="title">{{item.title}}</div>
                    <img src="@/assets/img/pencil.png" alt="">
                </div>
            </vuedraggable>
        </div>
    </div>
</template>
<script>
import vuedraggable from 'vuedraggable';
import { mapState,mapActions,mapMutations  } from 'vuex'
export default {
    name: 'GongShiKind',
    props: {},
    data: function() {
        return {
            title: '',
            data: [],
            activeIndex: false,
            zoom: 1,
            field:[],
            fieldData:[
                {id:1,title:'序号'},
                {id:2,title:'优先'},
                {id:3,title:'工作内容'},
                {id:4,title:'进展情况'},
                {id:5,title:'部署领导'},
                {id:6,title:'责任领导'},
                {id:7,title:'协同领导'},
                {id:8,title:'责任部门'},
                {id:9,title:'完成时限'},
            ]
        }
    },
    watch:{
        field(v){
            
        }
    },
    methods: {
        add() {
            if(this.title==''){
                this.$message('warning','请输入分类名称')
                return;
            }
            if(this.field.length==0){
                this.$message('warning','至少选中1项展示字段')
                return;
            }
            var data = {
                title: this.title,
                field: this.field.join(',')
            }
            if (this.activeIndex !== false) {
                data.id = this.data[this.activeIndex]['id']
            }
            this.global_submit('index.php?api-other-addEditGsTaskKind', data, (r) => {
                if (this.activeIndex === false) {
                    this.data.push({ id: r.insertId, title: data.title, field: data.field })
                } else {
                    this.data[this.activeIndex]['title'] = data.title
                    this.data[this.activeIndex]['field'] = data.field
                    this.activeIndex = false;
                }
                this.title = ''
                this.field = []
                this.$store.commit('globalStore/changeGsTaskKind',this.data)
            })
        },
        select(ind) {
            if (this.activeIndex === ind) {
                this.activeIndex = false;
                this.title = ''
                this.field = []
            } else {
                this.title = this.data[ind]['title']
                this.field = this.data[ind]['field'].split(',')
                this.activeIndex = ind;
            }
        },
        del() {
            this.global_del('index.php?api-other-delGsTaskKind', {
                id: this.data[this.activeIndex]['id']
            }, (r) => {
                this.data.splice(this.activeIndex, 1)
                this.title = ''
                this.activeIndex = false;
                this.$store.commit('globalStore/changeGsTaskKind',this.data)
            }, '该分类下所有的任务也会被一同删除<br/>确认删除吗')
        },
        dataUpdate(e) {
            this.global_sort({ data: this.data, tableName: 'task_gs_kind' })
            this.$store.commit('globalStore/changeGsTaskKind',this.data)
        },
        ...mapActions('globalStore',['getGsTaskKind']),
        ...mapMutations('globalStore',['changeGsTaskKind'])
    },
    components: {
        vuedraggable
    },
    mounted() {
        this.$store.dispatch('globalStore/getGsTaskKind')
        .then((r)=>{
            this.data = r;
        })

        var appzoom = document.querySelector('#app').style.zoom;
        if (appzoom && appzoom < 0.9) {
            this.zoom = 2 - appzoom;
        }
    }
}
</script>
<style scoped src="./index.css"></style>
<style scoped>
    .box-right{
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
    .fieldbox{
        
    }
    .box-right label{
        font-size: 22px;
    }
    .rwlb-tit{
        height: auto;
    }
    .cont-box2{
        top: 110px;
    }
</style>